import React from 'react';
import PropTypes from 'prop-types';
import style from './ToolBarDeleteButton.module.css';

export const ToolBarDeleteButton = ({ width, disabled, ...props }) => {

	let background = disabled ? '#666666': '#4e95c7';
	return (
		<>
			<button {...props} style={{ width: width, background: background }} className={style.ToolBarDeleteButton}>
				<svg id="delete" xmlns="http://www.w3.org/2000/svg" width="19.259" height="24.045" viewBox="0 0 19.259 24.045">
					<path
						id="Path_24"
						data-name="Path 24"
						d="M19.2,5.374l-.521-1.591A1.455,1.455,0,0,0,17.3,2.769H12.924V1.317A1.306,1.306,0,0,0,11.633,0H7.626A1.306,1.306,0,0,0,6.335,1.317V2.769H1.959A1.455,1.455,0,0,0,.58,3.783L.059,5.374A1.214,1.214,0,0,0,.22,6.448a1.164,1.164,0,0,0,.948.495h.544l1.2,15.1a2.16,2.16,0,0,0,2.124,2h9.435a2.16,2.16,0,0,0,2.123-2l1.2-15.1h.3a1.164,1.164,0,0,0,.948-.495,1.214,1.214,0,0,0,.16-1.074ZM7.716,1.409h3.825V2.769H7.716Zm7.5,20.524a.76.76,0,0,1-.746.7H5.033a.76.76,0,0,1-.746-.7L3.1,6.942H16.4ZM1.464,5.534l.427-1.305a.072.072,0,0,1,.068-.05H17.3a.072.072,0,0,1,.068.05l.427,1.305Zm0,0"
						transform="translate(0 0)"
						fill="#ffffff"
					/>
					<path
						id="Path_25"
						data-name="Path 25"
						d="M268.936,179.1h.036a.675.675,0,0,0,.674-.64l.634-12.171a.675.675,0,1,0-1.349-.07l-.634,12.171A.675.675,0,0,0,268.936,179.1Zm0,0"
						transform="translate(-255.907 -157.124)"
						fill="#ffffff"
					/>
					<path
						id="Path_26"
						data-name="Path 26"
						d="M106.458,178.462a.675.675,0,0,0,.674.638h.037a.675.675,0,0,0,.637-.711l-.664-12.171a.675.675,0,0,0-1.348.073Zm0,0"
						transform="translate(-100.908 -157.126)"
						fill="#ffffff"
					/>
					<path
						id="Path_27"
						data-name="Path 27"
						d="M194.929,179.1a.675.675,0,0,0,.675-.675V166.254a.675.675,0,1,0-1.35,0v12.171A.675.675,0,0,0,194.929,179.1Zm0,0"
						transform="translate(-185.292 -157.126)"
						fill="#ffffff"
					/>
				</svg>
			</button>
		</>
	);
};

ToolBarDeleteButton.propTypes = {
	width: PropTypes.string.isRequired,
};

ToolBarDeleteButton.defaultProps = {
	width: '2rem',
};

export default ToolBarDeleteButton;
