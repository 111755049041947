import React from 'react';
import { useTable, usePagination, useFilters, useSortBy, useRowSelect } from 'react-table';

import IndeterminateCheckbox from './IndeterminateCheckbox'
import {usePageState} from 'components/ui-core/page/usePageState';

export const useMsdTable = ({tableName, columns, data, sortBy, addSort, addSelection, addPagination, dndSpec, options, idAttrName}) => {
    const { getFilterValuesAsInitialState, getPaginationState, getSeletedRowIds } = usePageState()

    const tableOptions = { 
        columns, 
        data,
        initialState: {}, 
        ...options,        
    };

    // Note: Hooks must be added in correct order
    // Filtering
    const tableHooks = [useFilters];
    tableOptions.initialState.filters = getFilterValuesAsInitialState(tableName)

    // Sorting
    if (addSort) {
        tableHooks.push(useSortBy);
        if (sortBy) {
            tableOptions.initialState.sortBy = sortBy;
        }
    }
  
    // Pagination
    if (addPagination) {
        const paginationState = getPaginationState(tableName)
        tableOptions.initialState.pageIndex = paginationState.pageIndex;
        tableOptions.initialState.pageSize = paginationState.pageSize;

        tableHooks.push(usePagination);
    }

    // Selection
    if (addSelection) 
    {
        const addCheckboxColumnHook = (hooks) => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: (tableInstance) => {
                        const toggleProps = addPagination 
                            ? tableInstance.getToggleAllPageRowsSelectedProps
                            : tableInstance.getToggleAllRowsSelectedProps;

                        return (
                            <div>
                            <IndeterminateCheckbox {...toggleProps()} />
                            </div>
                    )},
                    Cell: ({ row }) => {
                        console.log("Selection")
                        const toggleProps = row.getToggleRowSelectedProps();

                        const style = {
                            textAlign: "center",
                        };

                        return (
                            <div style={style}>
                            <IndeterminateCheckbox {...toggleProps} />
                            </div>
                        )
                    },
                },
                ...columns,
            ])            
        }

        if (idAttrName) {
            tableOptions.getRowId = (row) => {
                return row[idAttrName];
            }
        }

        const selectedIdsObj = {}
        getSeletedRowIds(tableName).forEach(id => {
            selectedIdsObj[id] = true;
        });	
        tableOptions.initialState.selectedRowIds = selectedIdsObj

        tableHooks.push(useRowSelect);
        tableHooks.push(addCheckboxColumnHook);
    }

    const useTableObj = useTable(tableOptions, ...tableHooks)
    useTableObj["tableName"] = tableName 

    return {
        tableName,
        ...useTableObj,
        includedHooks: {
            sort: addSort,
            selection: addSelection, 
            pagination: addPagination,
            dnd: !!dndSpec
        },
        dndSpec,
        idAttrName
    };
}

export default useMsdTable;