import React from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getUnitSchema} from 'model/form-schema/ObjectSchemas'
import UnitFormContent from './UnitFormContent'
import {useSaveMetadata} from 'data/queryHooks';

export const EditUnitModal = ({ setEditUnit, editUnit, metadata }) => {
	const metadataClone = cloneDeep(metadata)

	const schema = getUnitSchema(metadata.units, editUnit.units_short, editUnit.units_long);
	const {useFormObj, controlProps} = useWizardForm(editUnit, schema, 'onChange');
	const  {mutateAsync: updateMetadata} = useSaveMetadata();

	const createSubmitPromise = async (formData) => {
		
		const unitInClone = metadataClone.units.find(unit => unit.units_short === editUnit.units_short);
		unitInClone.units_short = formData.units_short;
		unitInClone.units_long = formData.units_long;

		return await updateMetadata(metadataClone).then((response) => {
			toast.success(`Unit '${unitInClone.units_long}' updated`);

			return response;
		})			
	};

	const closeWizard = (wizardVisible) => {
		if (!wizardVisible) {
			setEditUnit(null);
		}
	}

	return (
		<Wizard width='40rem'>
			<WizardForm title="Edit unit" 
				setWizardVisible={closeWizard} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
				submitButtonTitle="Edit"
			>
				<UnitFormContent controlProps={controlProps} />
			</WizardForm>
		</Wizard>		
	);
};


export default EditUnitModal;
