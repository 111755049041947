
export const getButtonColor = (isSelected, editModeActive, mouseEventType) => {
    if(isSelected || mouseEventType === "mouseOver") {
        return 'rgba(245, 161, 69, 1)';
    } else if (editModeActive){
        return '#AAAAAA';
    } else {
        return '#282828';
    }
}

