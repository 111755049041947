import { getPendingBaseColumns } from 'components/cmd-packet/table-page/CommandPacketTableHelper';
import EntityType from 'model/EntityType';

import useReviewPacketsTable from 'components/review/useReviewPacketsTable';
import { getManagedTags } from 'model/ManagedTag';

export const ReviewCommandsTab = ({pendingPackets, viewHandler, submitUpdateTagRequest}) => {

	const allManagedTags = getManagedTags();

	const cmdTableJsx = useReviewPacketsTable({
		tableName: "ReviewCommandsTab",
		baseColumns: getPendingBaseColumns(), 
		packets: pendingPackets,  
		viewHandler: viewHandler,  
		entityType: EntityType.CmdPacket,  
		submitUpdateTagRequest,
		pageAllowedAdditions: allManagedTags,
		pageAllowedRemovals: allManagedTags
	});

	return cmdTableJsx;
};

export default ReviewCommandsTab;