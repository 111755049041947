import React, {useState} from 'react';

import style from 'App.module.css';
import { toast } from 'react-toastify';

import SaveableTab from 'components/ui-core/page/SaveableTab';
import useMsdTable from 'components/ui-core/table/msdTable/useMsdTable'
import MsdTable from 'components/ui-core/table/msdTable/MsdTable'

import { sortTagNames, isTagRemoveable, isTagAddable } from '../TagHelper';
import { areArrayEquals } from 'components/ui-core/utils/ArrayUtils';

import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';
import TableAddButton from 'components/ui-core/buttons/TableAddButton/TableAddButton';
import Tag from 'components/tags/Tag';

import { useTagObjects } from 'data/queryHooks';

export const TagsTabEditMode = (
	{ editModeTransitionHandler, underlyingEntity, entityType, allTags }) => {

	const getSortedRowsFromNameArr = (nameArr) => {
		return nameArr
			.sort(sortTagNames)
			.map(tagName => ({
				name: tagName,
				description: allTags.find(tag => tag.name === tagName)?.description
			}));
	}

	const getOrigTagNames = () => {
		return underlyingEntity.environment_tags
				.filter(entry => entry.value)
				.map(entry => entry.name);
	}

	// Current Data
	const initialCurrentData = React.useMemo(
		() => {
			const currentTagNames = getOrigTagNames();
			return getSortedRowsFromNameArr(currentTagNames);
		},
		[]
	)
		
	const [currentTableData, setCurrentTableData] = useState(initialCurrentData);		
	const getCurrentTagNames = () => currentTableData.map(tagRow => tagRow.name)


	const removeTag = (tagRowToDelete) => {
		setCurrentTableData((existingData) => {
			const newTagNames = [...existingData]
				.filter(tagRow => tagRow.name !== tagRowToDelete.name)
				.map(tagRow => tagRow.name);

			return getSortedRowsFromNameArr(newTagNames);
		});
	}

	const addTag = (tagRow) => {
		setCurrentTableData((existingData) => {
			const newTagNames = [...existingData]
				.map(tagRow => tagRow.name);

			newTagNames.push(tagRow.name);

			return getSortedRowsFromNameArr(newTagNames);
		});

	}

	const namdAndDescColumns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Cell: (tableInstance) => {
					const tagRow = tableInstance.row.original;
					return (
						<Tag key={tagRow.name} title={tagRow.name} />
					);
				},
			},
			{
				Header: 'Description',
				accessor: 'description',
			}			
		],
		[]
	);

	
	const currentColumns = React.useMemo(
		() => [
			...namdAndDescColumns,
			{
				Header: ' ',
				accessor: (tagRow) => {
					return (
						<div className={style.tableCellContainer}>
							{isTagRemoveable(getCurrentTagNames(), tagRow.name) &&
								<TableDeleteButton deletionHandler={() => removeTag(tagRow)}/>
							}
						</div>
					);
				}
			},			
		],
		[currentTableData]
	);

	const tableOptions = {
		getRowId: (row) => {
			return row.name;
		}
	}

	const currentTableObject = useMsdTable(
		{
			tableName: "TagsTabEditMode-Current",
			columns: currentColumns, 
			data: currentTableData, 
			options: tableOptions
		}
	);

	const getAddableTags = () => {

		return allTags
			.map(entry => entry.name)
			.filter(tagName => !currentTableData.find(tagRow => tagRow.name === tagName))
			.sort(sortTagNames)
			.filter(tagName => isTagAddable(underlyingEntity, entityType, getCurrentTagNames(), tagName))
			.map(tagName => ({
				name: tagName,
				description: allTags.find(tag => tag.name === tagName)?.description
			}));
	}
	
	// Addable Table
	const addableTableData = React.useMemo(
		() => {
			return getAddableTags();
		},
		[currentTableData]
	)

	const addableColumns = React.useMemo(
		() => [
			...namdAndDescColumns,
			{
				Header: ' ',
				accessor: (tagRow) => {
					return (
						<div className={style.tableCellContainer}>
							<TableAddButton addHandler={() => addTag(tagRow)}/>
						</div>
					);
				}
			},			
		],
		[currentTableData]
	);

	const addableTableObject = useMsdTable(
		{
			tableName: "TagsTabEditMode-Addable",
			columns: addableColumns, 
			data: addableTableData, 
			options: tableOptions
		}
	);

	// Has the user made any changes?
    const hasUserMadeChanges = () => {
		return !areArrayEquals(getOrigTagNames(), getCurrentTagNames());
	};
	

	const  {mutateAsync} = useTagObjects();
	const updateTagsPromise = async (tagRequest) => {
		return await mutateAsync(tagRequest)
			.then((tagResponse) => {
				toast.success(`'${underlyingEntity[entityType.nameAttr]}' updated`);
				return tagResponse;
			})
			.catch(error => {
				toast.error(`${entityType.description} update failed`);
				return Promise.reject(error)
			});				
	}

	const createSubmitPromise = () => {
		let newTagsArr = allTags
			.map(tag => ({
				name:  tag.name,
				value: getCurrentTagNames().includes(tag.name)
			}))

		let tagRequest = {
			entity_tag_requests: [
				{
					id: underlyingEntity._id,
					entity_type_code: entityType.code,
					tag_model_list: newTagsArr
				}
			]
		}

		return updateTagsPromise(tagRequest);
	};
		
	return (
		<>
			<SaveableTab editModeActive={true}
					editModeTransitionHandler={editModeTransitionHandler}
					createSubmitPromise={createSubmitPromise}
					saveEnabled={hasUserMadeChanges()}>

				<div>
					{/* Current Tags */}
					<h2>Current Tags</h2>
					<MsdTable useMsdTableObj={currentTableObject} />

					{/* Applicable Tags to Add */}
					<h2 style={{marginTop: "1rem"}}>Addable Tags</h2>
					<MsdTable useMsdTableObj={addableTableObject} />
				</div>

			</SaveableTab>
		</>
	);
};

export default TagsTabEditMode;
