import React, {Fragment, useState} from 'react';
import { toast } from 'react-toastify';


import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';

import { useASTData } from 'context/ASTContext';
import { useAlerts, useCreateDevVersion, useSaveAlert} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import ConfirmationRequest from 'model/ConfirmationRequest';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'
import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable';
import Wizard from 'components/ui-core/modal/Wizard';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import Modal from 'components/ui-core/modal/Modal/Modal';
import CreateButton from 'components/ui-core/buttons/CreateButton/CreateButton';
import CloseButton from 'components/ui-core/buttons/CloseButton/CloseButton';
import { DiscreteModal } from './DiscreteModal';


const DiscreteWizardTable = ({setCreateVisible, selectedRow , type, underlyingEntity}) => {
	const [addDiscrete, setAddDiscrete] = useState(false);
	const [editDiscrete, setEditDiscrete] = useState(null);	
	const {setConfirmationRequest} = useASTData();
    const [discrete, setDiscrete] = useState(type === "Red"? selectedRow["red_discrete"]: selectedRow["yellow_discrete"]);
    const [loading, setLoading] = useState(false);    

	const metadata_query = useAlerts();
	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setHeaderTitle(type + " Discrete");
	}, [setHeaderTitle, type]);
	


	const extractMetadataFromQuery = () => {
        return discrete;
	}

	const requestDelConfirmationHandler = (DiscreteRow) => {
		let deleteRequest =  new ConfirmationRequest(
			DiscreteRow,
			async () => performAlertDeletionHandler(DiscreteRow),
			`Delete '${DiscreteRow}'`, 
			"Delete",
			"Are you sure you would like continue?",
			null,
			`Alert '${DiscreteRow}' deleted`,
		);
	
		setConfirmationRequest(deleteRequest);
	}
    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(requestDelConfirmationHandler)

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Value',
				accessor: 'value',
				Filter: TextInputColumnFilter
			},
			{
				Header: ' ',
				accessor: (Row) => {
					return (
						<div className={style.tableButtonContainer}>
							<TableEditButton editHandler={() => setEditDiscrete(Row)}/>
							<TableDeleteButton deletionHandler={() => submitWithAppropriateWarnings(Row)}/>
						</div>
					);
				},
				columnType: "buttons"
			},
		],
		[submitWithAppropriateWarnings]
	);

	const  {mutateAsync: deleteAlert} = useSaveAlert();
	const performAlertDeletionHandler = async (discreteToDelete) => {
		const discrete_attr = type === "Red" ? "red_discrete" : "yellow_discrete";
		const threshold = underlyingEntity["thresholds"].find(ele => ele["default"][discrete_attr] !== undefined);
		const discreteAfterDelete = threshold["default"][discrete_attr].filter(ele => ele.name !== discreteToDelete.name);
		threshold["default"][discrete_attr] = discreteAfterDelete;

		return await deleteAlert(underlyingEntity).then((response) => {
			toast.success(`Alert '${discreteToDelete.name}' deleted`);
			setDiscrete(discreteAfterDelete);
			return response;
		})
	};

    const {mutateAsync: updateDevVersion} = useCreateDevVersion();
    const performAddNewDevVersionHandler = async () => {
    try{
        setLoading(true);
		
		return await updateDevVersion().then((response) => {
			setLoading(false);
			discrete = response["databases"].filter(ele => ele.version.includes("FLIGHT_DEV_MSD"))
			setDiscrete(discrete);
			toast.success(`Updated New Dev Version `);

			return response;
		})
    }
    catch (error) {
        console.error("Error during update:", error);
        // Handle error if needed
      } finally {
        // Set loading to false, whether the operation succeeded or not
        setLoading(false);
      }

	};

	const onEditSave = (Value) => {
		discrete = Value.filter(ele => ele.version.includes("FLIGHT_DEV_MSD"));
		setDiscrete(discrete);
	}

	return (

		
        <Fragment>
			
            {loading && (
                <Wizard width='40rem'>
                            <div role="status">
          <span>Creating Dev Snapshot...</span>
        </div>
        </Wizard>
            )}
			{addDiscrete && 
				<LoadingWrapper 
					queries={[metadata_query]}
					onLoad={() => (
						<Modal>
							<DiscreteModal 
								setAddDiscrete={setAddDiscrete}
								discrete = {null}
								metadata={metadata_query.data}
								underlyingEntity = {underlyingEntity}
								setDiscrete={setDiscrete}
								type ={type}
							/>
						</Modal> 
					)}				
				/>
			}
			

			{editDiscrete && 
				<LoadingWrapper 
					queries={[metadata_query]}
					onLoad={() => (
						<Modal>
							<DiscreteModal 
								setAddDiscrete={setEditDiscrete}
								discrete = {editDiscrete}
								metadata={metadata_query.data}
								underlyingEntity = {underlyingEntity}
								setDiscrete={setDiscrete}
								type ={type}
							/>
						</Modal> 
					)}				
				/>
			}
			
			{!loading && 
			<><div className={style.snapshotHeader}>
			<div>
				<h1>{type} Discrete</h1>
			</div>
			<div onClick={() => {setCreateVisible(false)}}>
				<CloseButton />
			</div>
			</div>
			<MsdQueryTable tableName="SnapShotsTablePage"
					columns={columns} query={metadata_query} dataExtractFn={extractMetadataFromQuery}
			>
	
				<CreateButton
					width="10rem"
					title={type + " Discrete"}
					onClick={() => {
						setAddDiscrete(true);
					}}
				/>
					
			</MsdQueryTable></>}
			
            </Fragment>
	);
}; 

export default DiscreteWizardTable;
