import React, { useState } from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getDiscreteSchema} from 'model/form-schema/ObjectSchemas'
import {useAlerts, useSaveAlert} from 'data/queryHooks';
import DiscreteFormContent from './DiscreteFormContent';
import { transformOption } from 'components/options/OptionHelper';

export const DiscreteModal = ({ setAddDiscrete, discrete, metadata , underlyingEntity , setDiscrete , type}) => {
	const metadata_query = useAlerts();
	metadata =metadata_query.data;
	const discrete_sttr = type == "Red" ? "red_discrete" : "yellow_discrete";
	let metadataClone = cloneDeep(metadata);
	const [dis, setDis] = useState(discrete);
	console.log(underlyingEntity["alert_name"]);
    let rowData = metadataClone.find(ele => ele.alert_name == underlyingEntity["alert_name"]);
	let discreteList = [];
	rowData["thresholds"].map(ele => {

			discreteList = (ele["default"][discrete_sttr]);
		return ele });
	const schema = getDiscreteSchema(discreteList, null , true);
	const {useFormObj, controlProps} = useWizardForm(discrete? discrete: null, schema, 'onChange');
	const {mutateAsync: UseUpdateAlert}  = useSaveAlert();
	


	const createSubmitPromise = async (formData) => {
		const adjustedFormData = transformOption(formData);
		if(discrete){
			let editRow = discreteList.find(ele => ele.name = adjustedFormData.name);
			editRow.value = formData.value;
		}
		else {
			discreteList.push(adjustedFormData);
		}	
		
		setDis(discreteList);
		const threshold = underlyingEntity["thresholds"].find(ele => ele["default"]["red_discrete"] !== undefined);
		threshold["default"][discrete_sttr] = discreteList;
		console.log(underlyingEntity);
		updateAlerts(underlyingEntity);
	};

	const closeWizard = (wizardVisible) => {
		if (!wizardVisible) {
			setAddDiscrete(false);
		}
	}


	const updateAlerts = async (updatedAlert)  => {
		return await UseUpdateAlert(updatedAlert)
		.then(refreshedAlert => {
			let updatedAlert = metadata.filter(ele => ele.alert_name == underlyingEntity["alert_name"]);
			updatedAlert[0] = refreshedAlert;
			setDiscrete(discreteList);
			toast.success(`'${refreshedAlert["alert_name"]}' updated`);
			return refreshedAlert;
		})
		.catch(error => {
			toast.error("Alerts update failed");
			return Promise.reject(error)
		});	
	}

	return (
		<Wizard width='40rem'>
			<WizardForm title={"Add " + type + " Discrete"} 
				setWizardVisible={closeWizard} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
				submitButtonTitle="Save"
			>
				<DiscreteFormContent controlProps={controlProps} />
			</WizardForm>
		</Wizard>		
	);
};


export default DiscreteModal;
