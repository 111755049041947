import React from 'react';
import { useHistory } from 'react-router-dom';

import style from 'App.module.css';

import { getTagColumn } from 'components/ui-core/table/TableHelper';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'
import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';

import { getPacketSummaryItems } from 'components/ui-core/common/PacketSummaryHelper';
import { useSummmaryContext } from 'components/ui-core/common/SummaryContext';

import { doesEntityContainTag } from 'components/tags/TagHelper';
import ManagedTag from 'model/ManagedTag';
import EntityType from 'model/EntityType';
import { getOptionsColumn } from 'components/options/OptionHelper';

export const CmdPacketAssocTabViewMode = ({ cmdPacket, allParams}) => {

	let sequenceNo = 0;
	const tableData = React.useMemo(
		() => {
			return cmdPacket.cmd_fields
				.map(paramName => {
					const cmdParam = allParams.find(param => param.cmd_param_name === paramName);
					if (cmdParam) {
						const isLogicallyDeleted = doesEntityContainTag(cmdParam, ManagedTag.DELETED.name);
						return isLogicallyDeleted
							? {
								...cmdParam,
								sequence: null
							}
							: {
								...cmdParam,
								sequence: sequenceNo++
							};
					} else {
						return {
							cmd_param_name: paramName + "(deleted)",
							sequence: null
						}	
					}
				}
			)			
		},
		[allParams, sequenceNo, cmdPacket]
	)

	const history = useHistory();
	const viewCommandParamHandler = (command_param) => {
		history.push(`/command-parameter/${command_param._id}`);
	};
	
	const columns = React.useMemo(
		() => [
			{
				Header: 'Sequence No.',
				accessor: 'sequence',
			},
			{
				Header: 'Name',
				accessor: 'cmd_param_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Description',
				accessor: 'description'
			},
			{
				Header: 'Type',
				accessor: 'type',
				Filter: SelectInputColumnFilter,
				filter: 'includes',
			},
			{
				Header: 'Byte Size',
				accessor: 'size_bytes',
			},
			{
				Header: 'Max Value',
				accessor: 'max_value'
			},
			getOptionsColumn(EntityType.CmdParam),
			getTagColumn(),
			{
				Header: ' ',
				accessor: (data) => {
					return (
						<div>
							<button className={style.tableTextButton} 
								onClick={() => viewCommandParamHandler(data)}
							>
								View
							</button>
						</div>
					);
				}
			},			
		],
		[]
	);
	
    const {setSummaryItems} =  useSummmaryContext();
	React.useEffect(() => {
			setSummaryItems(getPacketSummaryItems(tableData, "Command Parameter"));
		}, 
		[setSummaryItems, tableData]
	);

	return (
		<>
			<ManagedMsdTable tableName="CmdPacketAssocTabViewMode" columns={columns} data={tableData} />		
		</>
	);
};

export default CmdPacketAssocTabViewMode;
