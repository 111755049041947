import React from 'react';

import ManagedMsdTable from './ManagedMsdTable';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

export const MsdQueryTable = ({ tableName, columns, query, dataExtractFn, sortBy, ...props }) => {
 
    const extractData = () => {
        if (dataExtractFn) {
            return dataExtractFn(query)
        } else {
            return query.data;
        }
    }
    
	return (
        <LoadingWrapper 
            queries={[query]}
            onLoad={() => (
                <ManagedMsdTable tableName={tableName} columns={columns} data={extractData()} sortBy={sortBy} {...props}>
                    {props.children}                    
                </ManagedMsdTable>
            )}
        />
	);
};

export default MsdQueryTable;
