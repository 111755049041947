// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TableFilters_filterContainer__wDVqk {
	display: flex;
	flex-flow: row;
}


.TableFilters_filterContainer__wDVqk input,
.TableFilters_filterContainer__wDVqk select,
.TableFilters_filterContainer__wDVqk textarea {
	margin: 0.3rem 0 0.3rem 0;
}

.TableFilters_filterContainer__wDVqk input {
	margin-right: 1rem;
	border-radius: 0.4rem;
	width: 11rem;
	height: 2rem;
	border: 0.063rem solid #c9c8c8;
	color: #656565;
	font-size: 0.875rem;
	padding-left: 0.5rem;
}


.TableFilters_filterContainer__wDVqk label {
	margin-right: 0.5rem;
}

.TableFilters_filterContainer__wDVqk input:focus {
	outline: none !important;
	border: 0.1rem solid var(--ast-primary-orange-color);
}

.TableFilters_filterContainer__wDVqk select {
	margin-right: 9rem;
	border-radius: 0rem;
	width: 11rem;
	height: 2rem;
	border: 0.1rem solid #656565;
	color: #656565;
	font-size: 0.875rem;
}

.TableFilters_filterImage__Uk7Z3 {
	width: 25px;
	height: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/TableFilters/TableFilters.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,cAAc;AACf;;;AAGA;;;CAGC,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,qBAAqB;CACrB,YAAY;CACZ,YAAY;CACZ,8BAA8B;CAC9B,cAAc;CACd,mBAAmB;CACnB,oBAAoB;AACrB;;;AAGA;CACC,oBAAoB;AACrB;;AAEA;CACC,wBAAwB;CACxB,oDAAoD;AACrD;;AAEA;CACC,kBAAkB;CAClB,mBAAmB;CACnB,YAAY;CACZ,YAAY;CACZ,4BAA4B;CAC5B,cAAc;CACd,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,YAAY;AACb","sourcesContent":["\r\n.filterContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row;\r\n}\r\n\r\n\r\n.filterContainer input,\r\n.filterContainer select,\r\n.filterContainer textarea {\r\n\tmargin: 0.3rem 0 0.3rem 0;\r\n}\r\n\r\n.filterContainer input {\r\n\tmargin-right: 1rem;\r\n\tborder-radius: 0.4rem;\r\n\twidth: 11rem;\r\n\theight: 2rem;\r\n\tborder: 0.063rem solid #c9c8c8;\r\n\tcolor: #656565;\r\n\tfont-size: 0.875rem;\r\n\tpadding-left: 0.5rem;\r\n}\r\n\r\n\r\n.filterContainer label {\r\n\tmargin-right: 0.5rem;\r\n}\r\n\r\n.filterContainer input:focus {\r\n\toutline: none !important;\r\n\tborder: 0.1rem solid var(--ast-primary-orange-color);\r\n}\r\n\r\n.filterContainer select {\r\n\tmargin-right: 9rem;\r\n\tborder-radius: 0rem;\r\n\twidth: 11rem;\r\n\theight: 2rem;\r\n\tborder: 0.1rem solid #656565;\r\n\tcolor: #656565;\r\n\tfont-size: 0.875rem;\r\n}\r\n\r\n.filterImage {\r\n\twidth: 25px;\r\n\theight: 25px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": `TableFilters_filterContainer__wDVqk`,
	"filterImage": `TableFilters_filterImage__Uk7Z3`
};
export default ___CSS_LOADER_EXPORT___;
