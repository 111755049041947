// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableHeaderBar_tableHeaderBarContainer__Y5Pqi {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	height: auto;
	align-items: center;
}

.TableHeaderBar_tableHeaderBarContainer__Y5Pqi form {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}


.TableHeaderBar_tableHeaderBarContainer__Y5Pqi form label {
	font-weight: 500;
	font-size: 0.875rem;
}

@media only screen and (min-width: 1281px) {
	.TableHeaderBar_tableHeaderBarContainer__Y5Pqi form label {
		margin-right: 1rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/TableHeaderBar/TableHeaderBar.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,YAAY;CACZ,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,mBAAmB;AACpB;;;AAGA;CACC,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC;EACC,kBAAkB;CACnB;AACD","sourcesContent":[".tableHeaderBarContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row wrap;\r\n\tjustify-content: space-between;\r\n\theight: auto;\r\n\talign-items: center;\r\n}\r\n\r\n.tableHeaderBarContainer form {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\talign-items: center;\r\n}\r\n\r\n\r\n.tableHeaderBarContainer form label {\r\n\tfont-weight: 500;\r\n\tfont-size: 0.875rem;\r\n}\r\n\r\n@media only screen and (min-width: 1281px) {\r\n\t.tableHeaderBarContainer form label {\r\n\t\tmargin-right: 1rem;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderBarContainer": `TableHeaderBar_tableHeaderBarContainer__Y5Pqi`
};
export default ___CSS_LOADER_EXPORT___;
