import {getManagedTagNames, getTagByName} from "model/ManagedTag";

export const DEFAULT_TAG_COLOR = "#f1d79c";

export const isManagedTag = (tagName) => {
    return getManagedTagNames().includes(tagName);
}

export const isInFswArr = (tagArr) => {
    return !!tagArr.find(entry => entry.name === "FSW" && entry.value);
}

export const sortTagNames = (a, b) => {
    const managed_a = getTagByName(a)
    const managed_b = getTagByName(b)

    // Managed tags first
    if (managed_a && !managed_b) {
        return -1;
    } else if (!managed_a && managed_b) {
        return 1;
    } else if (managed_a && managed_b) {
        // Both managed - look at order value of tag
        return managed_a.workflowOrder - managed_b.workflowOrder;
    } else {
        // Both user tags - use alphabetical order
        return a -b;
    }
    
}

export const getTagIconColor = (disabled) => disabled ? "#a299a6": "#282828";

export const getColorStyleObject = (tagName, cssAttribute) => {
	const styleObject = {};

    let colorToUse = DEFAULT_TAG_COLOR;
    if (!tagName) {
        colorToUse = "white";
    } else {
        const managedTag = getTagByName(tagName);
        if (managedTag) {
            colorToUse = managedTag.color;
        } 
    }
    
    styleObject[cssAttribute]= colorToUse;    

    return styleObject;
}

export const isTagAddable = (underlyingEntity, entityType, existingTagNameArr, tagName) => {
	const managedTag = getTagByName(tagName);
    if (!managedTag) {
        return true;
    }

    const reqTagsMissing = managedTag.tagsReqToAdd.filter(reqTag => !existingTagNameArr.includes(reqTag))
    const entityStateInhibitors =  managedTag.entityCheckOnAddFns.filter(checkFn => !checkFn(underlyingEntity, entityType))

    return managedTag.canUserAdd 
        && managedTag.addableTo.includes(entityType)
        && !existingTagNameArr.find(existingTagName => existingTagName === tagName) 
        && reqTagsMissing.length === 0 
        && entityStateInhibitors.length === 0; 
}

export const isTagAddableToAll = (entities, entityType, tagName) => {
    if (entities.length === 0) {
        return false;
    }

    const qualifyingEntities = entities
        .filter(entity => isTagAddable(entity, entityType, getEntityTags(entity), tagName));

    return qualifyingEntities.length === entities.length;
}

export const getEntityTags = (entity) => {
    return entity.environment_tags.filter(tagEntry => tagEntry.value).map(tagEntry => tagEntry.name)
}

export const isTagRemoveable = (existingTagNameArr, tagName) => {
	const managedTag = getTagByName(tagName);
    if (!managedTag) {
        return true;
    }

    const problemInhibitingTags = managedTag.tagsInbitingRemove.filter(inhibitingTag => existingTagNameArr.includes(inhibitingTag))

    return managedTag.canUserRemove 
        && existingTagNameArr.find(existingTagName => existingTagName === tagName) 
        && problemInhibitingTags.length === 0; 

}

export const isTagRemoveableFromAll = (entities, tagName) => {
    if (entities.length === 0) {
        return false;
    }
    
    const qualifyingEntities = entities
        .filter(entity => isTagRemoveable(getEntityTags(entity), tagName));

    return qualifyingEntities.length === entities.length;
}

export const doesEntityContainTag = (underlyingEntity, tagName) => {
    return !!underlyingEntity["environment_tags"]?.filter(entry => entry.value).find(entry => entry.name === tagName)
}

export const getEntityTagsAsStrArr = (entity) => {
    return entity["environment_tags"]?.filter(tag => tag.value).map(tag => tag.name)
}

export const shouldPhysicallyDelete = (entity) => {
    const currentDatabase = localStorage.getItem("database");

    return currentDatabase === "FLIGHT_STAGING" || !doesEntityContainTag(entity, "FSW_PROD") 
}

