
import { getTagColumn, nodeCmdColumnFilter } from 'components/ui-core/table/TableHelper';

import {
    subsytemColumn, 
    nameColumn, 
    descriptionColumn, 
    cspPortColumn, 
    viewButtonColumn,
    createdByUserNameColumn, 
    creationDateColumn, 
    lastModifiedUserNameColumn, 
    lastModifiedDateColumn
} from 'components/ui-core/table/TableHelper';



export const getCommandPacketColumns = (viewHandler, header_name="Name") => [
    subsytemColumn(),
    nameColumn("cmd_name", header_name),
    descriptionColumn(),
    nodeCmdColumnFilter(),
    cspPortColumn(),
    getTagColumn(),
    viewButtonColumn(viewHandler)
]


export const getPendingBaseColumns = () => [
    subsytemColumn(),
    nameColumn("cmd_name"),
    createdByUserNameColumn(),
    creationDateColumn(),
    lastModifiedUserNameColumn(),
    lastModifiedDateColumn()
]

export const getStagedBaseColumns = () => [
    subsytemColumn(),
    nameColumn("cmd_name")
]
