import React, { useState } from 'react';
import style from './AlertFormContent.module.css'
import {pick} from 'lodash';
import {FormRow, Input, TextArea, Select, Checkbox, Spacer, ToggleSwitchWrapper, MultiSelect, FormCheckmarkRow} from 'components/ui-core/form/FormControls';
import {getUnitEntryOptions} from 'components/ui-core/utils/MetadataHelper'
import {getCmdParamApplicableTypes} from 'components/ui-core/utils/MetadataHelper'

import { getBlockArrayRowIfRequired } from 'components/ui-core/utils/BlockArrayHelper';
import { useBranchData } from 'components/ui-core/layout/Header/BranchContext';

export const AlertFormContent = ({ controlProps, useFormObj, metadata, isWizard, isByteReadOnly, allAlerts , tlmNames, packetNames, viewPoint, editModeActive}) => { 
	const {watch, getValues, setValue, control} = useFormObj;
	watch('packet');
	const {metadataDb} = useBranchData();
	let escOptions = metadataDb.length != 0 ? metadataDb['escalation_group']: [];
	escOptions = escOptions? escOptions : [];
	const nodeOptions = metadata.nodes.map(nodeEntry => nodeEntry.node);
	const limitValueNames = ["red_low_limit", "red_high_limit", "yellow_low_limit", "yellow_high_limit"];
    const [stashedLimitValues, setStashedLimitValues]  = useState(pick(getValues(), limitValueNames));

	return (
		<>

			<FormRow rowNum="1">
				<Input style={{ width: "25rem" }} title="Alert Name" attr="alert_name" {...controlProps} />
				<Input style={{ width: "10rem" }} title="Persistence" attr="persistence" {...controlProps} readonly={true} />
				<div></div>
			</FormRow>

			<FormRow rowNum="2">
				<Select style={{ width: "60rem" }} title="Tlm Point Name" attr="tlm_name" {...controlProps} valueOptions={tlmNames} />
				<div>
				<table style={{ border: "none"}}>
					<tr>
						<td style={{ border: "none", padding: "0" }}><Input style={{ width: "17rem" }} id="Tlm Id" title="Tlm Id" attr="tlm_id" {...controlProps} disabled={true} /></td>
						{!editModeActive && <td style={{ border: "none", margin: "0"}}><button className={style.tableTextButton} onClick={() => viewPoint()}> View </button></td>}
					</tr>
				</table>
				</div>
				<Select style={{ width: "30rem" }} title="Target Board" attr="board" {...controlProps} valueOptions={nodeOptions} />
			</FormRow>

			<FormRow rowNum="3">
				<MultiSelect title="Packet Names" attr="packet" {...controlProps}
					optionValues={packetNames} control={control} editModeActive={false} />
			</FormRow>

			<FormRow rowNum="4">
				{escOptions.length > 0 ? 
				(<Select title="Escalation Group" attr="escalation_group" {...controlProps} valueOptions={escOptions} /> ) : 
					(<Input	style={{ width: "30rem" }} title="Escalation Group" attr="escalation_group" {...controlProps} /> )
				}
			</FormRow>
			<FormRow rowNum="5">
				<FormCheckmarkRow rowNum="4">
					<ToggleSwitchWrapper title="Status" attr="active"
						{...controlProps}
					/>
				</FormCheckmarkRow>
			</FormRow>      
		</>
	);
};


export default AlertFormContent;
