import React from 'react';
import style from './SubmittablePage.module.css'

import { useASTData } from "context/ASTContext";

function SubmittablePage(props) {
    const {submitInProgress} = useASTData();

    return (
        <div id="SubmitableContainer" className={style.submitableContainer}>
            <div id="RootChildren" className={style.childLayer}>
                {props.children}
            </div>

            {submitInProgress ? ( 
                <div id='submitDivWrapper' className={submitInProgress ? style.submittingWrapper : ''}>
                    <div id='submitDiv' className={submitInProgress ? style.submitting : ''}>
                    </div>
                </div>
                ) : <></>
            }
        </div>
    );
}
export default SubmittablePage;
