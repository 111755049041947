export function areArrayEqualsAndInOrder(a, b, equalityFn) {
    return Array.isArray(a) 
        && Array.isArray(b) 
        && a.length === b.length 
        && a.every((val, index) => {
            return equalityFn
                ? equalityFn(val, b[index])
                : val === b[index]
        });
}

export function areArrayEquals(a, b, equalityFn) {
    if (Array.isArray(a) && Array.isArray(b)) {
        const copyA = [...a].sort();
        const copyB = [...b].sort();

        return areArrayEqualsAndInOrder(copyA, copyB, equalityFn)
    } 
    
    return false;

}

  