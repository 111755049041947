import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';

import { nameColumn,  descriptionColumn, viewButtonColumn, getTagColumn} from 'components/ui-core/table/TableHelper';
import { getSubsystemArrColumn} from 'components/ui-core/utils/SubsystemUtils';
import { getOptionsColumn } from 'components/options/OptionHelper';
import EntityType from 'model/EntityType';


const typeColumn = () => {
    return {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectInputColumnFilter,
        filter: 'includes',
    }    
}

const byteSizeColumn = () => {
    return {
        Header: 'Byte Size',
        accessor: 'size_bytes',
        Filter: TextInputColumnFilter
    }    
}

const sequenceNoColumn = () => {
    return {
        Header: 'Sequence No.',
        accessor: 'sequence'
    }    
}

const derivedInputsColumn = () => {
    return {
        Header: 'Inputs Points',
        accessor: 'derived_inputs'
    }    
}

const derivedExpression = () => {
    return {
        Header: 'Derived Expression',
        accessor: 'derived_expression'
    }    
}


export const getTelemetryPointColumns = (viewHandler, header_name="Name") => [
    getSubsystemArrColumn(),
    nameColumn("tlm_point_name", header_name),
    descriptionColumn(),
    typeColumn(),
    byteSizeColumn(),
    getTagColumn(),
    viewButtonColumn(viewHandler),
]


export const getTelemetryPointAssocsColumns = (viewHandler, header_name="Name") => [
    sequenceNoColumn(),
    nameColumn("tlm_point_name", header_name),
    descriptionColumn(),
    typeColumn(),
    byteSizeColumn(),
    getOptionsColumn(EntityType.TlmPoint),
    getTagColumn(),
    viewButtonColumn(viewHandler),
]

export const getDerivedInputUsageColumns = (viewHandler, header_name="Name") => [
    nameColumn("tlm_point_name", header_name),
    descriptionColumn(),
    derivedInputsColumn(),
    derivedExpression(),
    getTagColumn(),
    viewButtonColumn(viewHandler),
]
