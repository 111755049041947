import { useReducer } from "react";
import SelectMultiple from "react-select";
import { Controller } from "react-hook-form";

import style from './FormControls.module.css';
import ToggleSwitch from 'components/ui-core/buttons/ToggleSwitch/ToggleSwitch';
import LoadingWrapper from "../page/LoadingWrapper";


const ConditionalErrorMessage = ({errors, attr}) => {
	return (
        <>
            {errors[attr]?.message &&
                <p className={style.formErrorMessage}>
                    {errors[attr]?.message}
                </p>
            }	        
        </>        
	);
};


export const FormRow = ({rowNum, ...props}) => {
	return (
		<div id={"Row-"+rowNum} {...props} className={style.formRow}>
			{props.children}
		</div>
	);
};

export const FormCheckmarkRow = ({rowNum, ...props}) => {
	return (
		<div id={"Row-"+rowNum} className={style.formRowCheckmark}>
			{props.children}
		</div>
	);
};

const evalIsRequired = (isRequired) => {
    if (typeof isRequired === "function") {
        return isRequired();
    } else if (isRequired){
        return isRequired;
    }

    return false;
}

const ControlTitle = ({title, attr, schema, isRequired, dontShowRequired}) => {
    // required if explicitly states or conditinally required, in which case
    // the fact we display the field makes it required
    const showRequired = 
        !dontShowRequired &&
        schema.fields[attr]?.exclusiveTests?.required || evalIsRequired(isRequired);
    
    // Create the title JSX unless it is passed in (e.g. from a component wanting to 
    // display parts of the label in color) 
    let labelJsx = typeof title === "function"  
        ? title()
        : <label>{title}</label>

    return (
        <div className={style.formTitle}>
            {labelJsx}

            <>
            {showRequired ?  
                <span className={style.requiredIndicator}>*</span>       
                : <></>
            }
            </>
        </div>
    );

}


const revalidateIfRequred = (revalidateOnChange, useFormObj, useFormMode) => {
    if (revalidateOnChange) {
        const {formState: { isSubmitted }} = useFormObj;

        let shouldRevalidate = useFormMode === "onSubmit" ? isSubmitted : true;
        if (shouldRevalidate) {
            useFormObj.trigger();
        }
    }
}

export const Input = ({type, title, attr, schema, useFormObj, dontShowRequired, 
    revalidateOnChange, editModeActive, isRequired, useFormMode, ...props}) => {
	
    const {register, formState: { errors }} = useFormObj;
    const typeToUse = type ? type : "text";

    return (
        <div className={style.rowField}>
            {title &&
                <ControlTitle title={title} attr={attr} schema={schema} 
                    isRequired={isRequired} dontShowRequired={dontShowRequired}/>
            }
			<input type={typeToUse} {...props} 
                {...register(attr ,{
                        onChange: () => revalidateIfRequred(revalidateOnChange, useFormObj, useFormMode)
                    })
                }
            />

            <ConditionalErrorMessage errors={errors} attr={attr} />
        </div>
    );
}

export const InputSimple = ({type, ...props}) => {
    const typeToUse = type ? type : "text";

    return (
			<input className={style.rowFieldSimple} type={typeToUse} {...props} />
    );
}


export const TextArea = ({title, attr, schema, useFormObj, editModeActive, isRequired, useFormMode, ...props}) => {
	const {register, formState: { errors }} = useFormObj;

    return (
        <div className={style.textareaContainer}>
            <ControlTitle title={title} attr={attr} schema={schema} isRequired={isRequired}/>

			<textarea {...props} {...register(attr)}/>

            <ConditionalErrorMessage errors={errors} attr={attr} />
         </div>
    );
} 


export const Select = ({title, attr, schema, useFormObj, className,
     editModeActive, valueOptions, entryOptions, valueStyles, includeBlank, disabled, 
     onChange, revalidateOnChange, isRequired, useFormMode}) => {

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    
    const {register, getValues, formState: { errors }} = useFormObj;
    const options = entryOptions
        ? entryOptions
        : valueOptions.map(val => ({key: val, value: val}));

    const handleChange = (event) => {
        if (onChange) {
            onChange(event)
        }
        if (valueStyles) {
            forceUpdate()
        }
        revalidateIfRequred(revalidateOnChange, useFormObj, useFormMode);
    }

    const handleSetValueAs = (value) => {
        // Convert the blank "" selection to null 
        if (includeBlank && value === "") {
            return null;
        }

        return value
    }

    const styleForOptionValue = (optionValue) => {
        if (!optionValue || !valueStyles || !valueStyles[optionValue]) {
            return {};
        } 

        return valueStyles[optionValue];
    }

    const styleForSelect = () => {
        const currentValue = getValues(attr)

        if (editModeActive) {
            return {
                "backgroundImage": 'url(" + "../../img/select-arrow-down.svg" + ")',
                ...styleForOptionValue(currentValue)
            };
        } 
        
        if (valueStyles) {
            if (currentValue && valueStyles[currentValue]) {
                return {"background": styleForOptionValue(currentValue)};
            }
        }

        return {"background": 'rgba(241, 235, 235, 0.5)'};
    }
    
    return (
        <div>
            <ControlTitle title={title} attr={attr} schema={schema} isRequired={isRequired}/>

            <select 
                disabled={disabled}
                className={className}
                style={styleForSelect()}
                {...register(attr, {
                    onChange: (event) => handleChange(event),
                    setValueAs: (value) => handleSetValueAs(value)
                })}
            >

                {includeBlank &&
                    <option key="blank" value=""></option>
                }

                {options.map((option) => (
                    <option key={option.key} value={option.key} style={styleForOptionValue(option.value)}>
                        {option.value}
                    </option>
                ))}

            </select>
            
            <ConditionalErrorMessage errors={errors} attr={attr} />
        </div>
    );
}

export const Checkbox = ({title, attr, editModeActive, useFormObj, className, textLabels, useFormMode, revalidateOnChange, ...props}) => {

	const {register, getValues, formState: { errors }} = useFormObj;
    const textLabel = textLabels 
        ? getValues(attr) ? textLabels.enabled : textLabels.disabled
        : null;

    return (
        <div className={style.checkmarkGroup}>
            <div className={style.rowField}>
                <p>{title}:</p>
                <label className={style.checkmarkContainer}>
                    <input type="checkbox" {...props}
                        {...register(attr ,{
                            onChange: () => revalidateIfRequred(revalidateOnChange, useFormObj, useFormMode)
                        })}
                    />

                    <span className={style.checkmark}
                        style={
                            editModeActive
                                ? { background: 'rgba(255, 255, 255, 1)'}
                                : { background: 'rgba(241, 235, 235, 0.5)'}
                        }  
                    />
                    {textLabel && <span className={style.checkboxIndicatorText}>{textLabel}</span>}
                </label>
            </div>
            <ConditionalErrorMessage errors={errors} attr={attr} />
        </div>
    );
}

export const Spacer = (props) => {
    return (
        <div className={style.rowField} />
    );
}

export const ToggleSwitchWrapper = ({title, attr, editModeActive, useFormObj, schema, 
        onChangeHandler, revalidateOnChange, useFormMode}) => {
	const {formState: { errors }} = useFormObj;



    return (
        <div>
            <ControlTitle title={title} attr={attr} schema={schema} dontShowRequired={true}/>

            <Controller
                control={useFormObj.control}
                name={attr}
                render={({ field: { value, onChange }}) => { 
                    const doOnChange = (newValue) => {
                        onChange(newValue);

                        // Call any parent method passed in 
                        onChangeHandler && onChangeHandler(newValue)

                        revalidateIfRequred(revalidateOnChange, useFormObj, useFormMode);
                    };
                    
                    return (
                        <ToggleSwitch 
                            editModeActive={editModeActive}  
                            value={value} 
                            onChange={doOnChange}
                        />
                    );}
                }
            />  

            <ConditionalErrorMessage errors={errors} attr={attr} />
        </div>

    );
}

export const QueryControlWrapper = ({title, attr, schema, wrapperStyle = {}, queries, onLoad}) => {


    return (
        <div style={wrapperStyle}>
            <ControlTitle title={title} attr={attr} schema={schema} dontShowRequired={true}/>
            <LoadingWrapper queries={queries} onLoad={onLoad}/>		
        </div>

    );
}

export const MultiSelect = ({title, attr, editModeActive, useFormObj, schema, optionValues, control, minWidth}) => {
	const {formState: { errors }} = useFormObj;
    const options = optionValues.sort().map((value) => ({
        label: value,
        value: value
    }));
    
    const customStyles = {
        container: provided => ({
          ...provided,
          minWidth: minWidth ? minWidth : "15rem"
        })
      };
          
    return (
        <div>
            {title &&
                <ControlTitle title={title} attr={attr} schema={schema}/>
            }

            <Controller
                name={attr}
                control={control}
                render={({ field: { value, onChange, onBlur } }) => {
                    return (
                        <SelectMultiple
                            styles={customStyles}
                            options={options}
                            placeholder={"Choose..."}
                            isDisabled={!editModeActive}
                            isSearchable={true}
                            isClearable={false}
                            isMulti={true}
                            onChange={(options) =>
                                onChange(options?.map((option) => option.value))
                            }
                            onBlur={onBlur}
                            value={options.filter((option) => value?.includes(option.value))}
                            defaultValue={options.filter((option) => 
                                value?.includes(option.value)
                            )}
                        />
                    );
                }}
            />

            <ConditionalErrorMessage errors={errors} attr={attr} />
        </div>

    );
}


export const MenuBarInput = ({type, title, attr, schema, useFormObj, dontShowRequired, editModeActive, ...props}) => {
	const {register, formState: { errors }} = useFormObj;
    const typeToUse = type ? type : "text";

    const style = getTooltipErrorStyle(errors, attr);

    return (
        <>
            {title &&
                <label>{title}</label>
            }
            <input title={errors[attr]?.message} type={typeToUse} {...props} {...register(attr)} style={style}/>
        </>
    );
}


export const NestedFormRowControlWrapper = ({title, attr, schema, isRequired, dontShowRequired, children}) => {

    return (
        <div id="NESTED" className={style.nestedContainer}>
            {title &&
                <ControlTitle title={title} attr={attr} schema={schema} 
                    isRequired={isRequired} dontShowRequired={dontShowRequired}/>
            }
            <div className={style.nestedControl}>
                {children}
            </div>
        </div>
    );
}

const getTooltipErrorStyle = (errors, attr) => {
    const style = {};

    if (errors[attr]?.message) {
        style["borderLeft"] =  "6px solid var(--ast-secondary-red-color)";
    }

    return style; 
}