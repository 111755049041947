import React from 'react';

import {TextArea} from 'components/ui-core/form/FormControls';

export const EditableDerivedSection = ({ controlProps}) => {

    return (
        <>
            <TextArea style={{marginRight: '8px'}} title="Input Points" attr="derived_inputs" 
                rows={2} {...controlProps} isRequired={true}/>                    
            <TextArea style={{marginRight: '8px'}} title="Derived Expression" attr="derived_expression" 
                rows={2} {...controlProps} isRequired={true}/>
            <TextArea title="Derived Python Expression" attr="derived_expression_python" 
                rows={2} {...controlProps} />
        </>
    )
}
