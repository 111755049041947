import Option from "./Option"
import Severity from "./Severity"
import EntityType from "model/EntityType"
import TabCellBoxContainer from "components/ui-core/table/TabCellBoxContainer"

export const option_severities = ["yellow", "red"]

const NOT_SET_STYLE = {
    backgroundColor: "white",
    color: "black" 
}

export const option_styles = {
    yellow: {
        backgroundColor: "yellow",
        color: "black" 
    },
    red: {
        backgroundColor: "var(--ast-primary-red-color)",
        color: "white" 
    },
    null: NOT_SET_STYLE,
    undefined: NOT_SET_STYLE,
    "": NOT_SET_STYLE
}

export const transformOption = (formData) => {
    const result = {}
    Object.keys(formData).forEach(key => {
        if (key === "severity") {
            result[key] = formData[key] === "" ? null : formData[key] 
        } else {
            result[key] = formData[key]
        }
      });

    return result;
}

export const getSeverityColumn = () => {
    return {
        id: 'severity',
        Header: 'Severity',
        Cell: (tableInstance) => {
            const {row} = tableInstance;

            return (
                <>
                    {row.original.severity && 
                        <Severity severity={row.original.severity} />
                    }
                </>
            );
        },				
    }
} 

export const optionComparator = (a, b) => {
    if ( a.value < b.value ){
        return -1;
    }
    if ( a.value > b.value ){
        return 1;
    }
        return 0;  
}

export const getOptionsColumn = (entityType) => {
    return {
        id: 'options',
        Header: 'Options',
        Cell: (tableInstance) => {
            const {row} = tableInstance;
            const optionsAttrName = entityType.optionsAttrName

            return (
                <TabCellBoxContainer>
                    {
                        row.original[optionsAttrName] && row.original[optionsAttrName]
                            .map((option) => 
                                <Option key={option.name} option={option} />
                            )
                            .sort(optionComparator)
                    }	
                     
                </TabCellBoxContainer>
            );
        },				
    }
}
