
export const areAnyLoadingOrIdle = (queries) => {

    let result =  queries.some(query => {
        return query.isLoading || query.isIdle;
    });

    return result;
}

export const wereAnyErrors = (queries) => {
    let result =  queries.some(query => query.isError);
    return result;
}

export const getAllErrors = (queries) => {
    let result =  queries.filter(query => query.isError).map(query => query.error.message);
    return result;
}
