// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabMenuButton_tabMenuButton__8kS65 button {
	display: flex;
	flex-flow: row nowrap;
	height: 2.813rem;
	background: none;
	border: none;
	align-items: center;
	cursor: pointer;
	margin-right: 0.7rem;
}

.TabMenuButton_tabMenuButton__8kS65 button p {
	color: var(--ast-primary-orange-color);
	font-weight: 500;
	font-size: 1.125rem;
	margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/page/TabMenu/TabMenuButton.module.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,gBAAgB;CAChB,gBAAgB;CAChB,YAAY;CACZ,mBAAmB;CACnB,eAAe;CACf,oBAAoB;AACrB;;AAEA;CACC,sCAAsC;CACtC,gBAAgB;CAChB,mBAAmB;CACnB,mBAAmB;AACpB","sourcesContent":["@import 'App.module.css';\r\n\r\n.tabMenuButton button {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\theight: 2.813rem;\r\n\tbackground: none;\r\n\tborder: none;\r\n\talign-items: center;\r\n\tcursor: pointer;\r\n\tmargin-right: 0.7rem;\r\n}\r\n\r\n.tabMenuButton button p {\r\n\tcolor: var(--ast-primary-orange-color);\r\n\tfont-weight: 500;\r\n\tfont-size: 1.125rem;\r\n\tmargin-left: 0.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabMenuButton": `TabMenuButton_tabMenuButton__8kS65`
};
export default ___CSS_LOADER_EXPORT___;
