// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MsdTable_msdTableContainer__65XZt {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow: hidden;
    
}

.MsdTable_msdTableHeader__cPrcR {
    flex-shrink: 0;
    flex-grow: 0;
}

.MsdTable_msdTable__2Po7W {    
    min-height: 0;
    flex-shrink: 1;
    flex-grow: 1;

    flex-flow: column nowrap;
    height: 100%;
    display: flex;
    background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/msdTable/MsdTable.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,YAAY;IACZ,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,YAAY;;IAEZ,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".msdTableContainer {\r\n    display: flex;\r\n    flex-flow: column nowrap;\r\n    height: 100%;\r\n    overflow: hidden;\r\n    \r\n}\r\n\r\n.msdTableHeader {\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n}\r\n\r\n.msdTable {    \r\n    min-height: 0;\r\n    flex-shrink: 1;\r\n    flex-grow: 1;\r\n\r\n    flex-flow: column nowrap;\r\n    height: 100%;\r\n    display: flex;\r\n    background-color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msdTableContainer": `MsdTable_msdTableContainer__65XZt`,
	"msdTableHeader": `MsdTable_msdTableHeader__cPrcR`,
	"msdTable": `MsdTable_msdTable__2Po7W`
};
export default ___CSS_LOADER_EXPORT___;
