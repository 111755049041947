import React, {useState} from 'react';

import formStyle from 'components/ui-core/form/Form.module.css';

import {moveToSeqenceNoSchema} from 'model/form-schema/ObjectSchemas'
import useMenuForm from 'components/ui-core/form/useMenuForm';

import {MenuBarInput} from 'components/ui-core/form/FormControls';
import TableBarButton from 'components/ui-core/buttons/TableBarButton/TableBarButton';

export const MoveToPos = ({tableData, msdTableObject, nameAttr, 
        arrayFnOnAdjustedArr, setTableDataFn, setSelectedIdsObjFn}) => {

    // Keep a track on the number of selections, and if changed revalidate the move to seq form 
    const [noSelected, setNoSelected] = useState(0);
    const {selectedFlatRows} = msdTableObject;

    const maxSeqNo = tableData.length - selectedFlatRows.length + 1;
    const schema = moveToSeqenceNoSchema(maxSeqNo)

    const useMenuFormObj = useMenuForm({}, schema);   
    const {
        useFormObj: {
            handleSubmit, 
            formState:{isValid},
            watch,
            getValues,
            setValue,
            trigger
        },
        controlProps
    } = useMenuFormObj;    

    watch("move_to_seq");    
    if (selectedFlatRows.length !== noSelected) {
        setNoSelected(selectedFlatRows.length);
        if (noSelected) {
            trigger();
        }
    }
	const isMoveEnabled = isValid && noSelected > 0 && getValues("move_to_seq") > 0;    
	
    const onMovePosition = (moveFormData) => {
        console.log("onMovePosition", moveFormData);

        const selectedTableData = selectedFlatRows.map(row => row.original);

        // Remove all selected items from the source table
        const newTableData = tableData
            .filter(tableRow => 
                !selectedTableData.find(row => row[nameAttr] === tableRow[nameAttr]));
            
        addSelectionsAtIndex(newTableData, moveFormData.move_to_seq -1, selectedTableData);
        
        //blank out the value
        setValue("move_to_seq", null);
    }     

    const addSelectionsAtIndex = (newTableData, insertAtIndex, selectedTableData) => {
        newTableData.splice(insertAtIndex, 0, ...selectedTableData);
        arrayFnOnAdjustedArr(newTableData, selectedTableData);

        console.log("New Reordered Data", newTableData);

        // Lose the selection
        setSelectedIdsObjFn && setSelectedIdsObjFn({});

        // set the new data 
        setTableDataFn(newTableData);        
    }
    
    return (
        <form onSubmit={handleSubmit(onMovePosition)} className={formStyle.filterBarControls}>
            <MenuBarInput title="Move to Seq. No:" attr="move_to_seq" {...controlProps}/>

            <TableBarButton 
                title="Move" 
                type="submit"
                width="4rem"
                disabled= {!isMoveEnabled}
                onSubmit={() => {handleSubmit(onMovePosition)}}
            />
        </form>  
    );
   
}

export default MoveToPos;