// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TabMenu_menuContainer__mKrnv {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	border-bottom: 0.2rem solid #aaafb3;
	margin: 0.2rem 0;
}

.TabMenu_tabContainer__4eqmr {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
}


.TabMenu_tabControlsContainer__PiMOU {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

/* Responsive website scaling for 1920 x 1080 screens */
@media screen and (max-width: 1920px) {
	.TabMenu_tabMenuContainer__1KUMp svg {
		width: 1.5rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/page/TabMenu/TabMenu.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,qBAAqB;CACrB,8BAA8B;CAC9B,mCAAmC;CACnC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,2BAA2B;AAC5B;;;AAGA;CACC,aAAa;CACb,qBAAqB;CACrB,2BAA2B;CAC3B,mBAAmB;AACpB;;AAEA,uDAAuD;AACvD;CACC;EACC,aAAa;CACd;AACD","sourcesContent":["\r\n.menuContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\tjustify-content: space-between;\r\n\tborder-bottom: 0.2rem solid #aaafb3;\r\n\tmargin: 0.2rem 0;\r\n}\r\n\r\n.tabContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\tjustify-content: flex-start;\r\n}\r\n\r\n\r\n.tabControlsContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\tjustify-content: flex-start;\r\n\talign-items: center;\r\n}\r\n\r\n/* Responsive website scaling for 1920 x 1080 screens */\r\n@media screen and (max-width: 1920px) {\r\n\t.tabMenuContainer svg {\r\n\t\twidth: 1.5rem;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuContainer": `TabMenu_menuContainer__mKrnv`,
	"tabContainer": `TabMenu_tabContainer__4eqmr`,
	"tabControlsContainer": `TabMenu_tabControlsContainer__PiMOU`,
	"tabMenuContainer": `TabMenu_tabMenuContainer__1KUMp`
};
export default ___CSS_LOADER_EXPORT___;
