import React from 'react';
import style from './Tag.module.css';

import { getColorStyleObject, getTagIconColor} from 'components/tags/TagHelper';

export const AddTagButton = ({ title, onClick, disabled=false, ...props }) => {
	const tagStyle = getColorStyleObject(title, "background");
	const tagIconColor = getTagIconColor(disabled);
	
	const onClickWrapper = (...args) => {
		if (!disabled) {
			onClick(...args)
		}

	}
	return (
		<button onClick={onClickWrapper} className={style.addTagButton} disabled={disabled}>
	 			<div style={tagStyle}  {...props} className={style.tag}>

				<svg id="add_4_" data-name="add (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
					<g id="Group_575" data-name="Group 575">
						<g id="Group_574" data-name="Group 574">
							<path id="Path_1330" 
								data-name="Path 1330" 
								d="M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0ZM9,16.606A7.606,7.606,0,1,1,16.606,9,7.615,7.615,0,0,1,9,16.606Z" 
								fill={tagIconColor}/>
						</g>
					</g>
					<g id="Group_577" data-name="Group 577" transform="translate(3.664 3.616)">
						<g id="Group_576" data-name="Group 576">
							<path id="Path_1331" 
								data-name="Path 1331" 
								d="M146.805,139.685h-3.558v-3.558a.889.889,0,0,0-1.779,0v3.558h-3.558a.889.889,0,0,0,0,1.779h3.558v3.558a.889.889,0,0,0,1.779,0v-3.558h3.558a.889.889,0,0,0,0-1.779Z" 
								transform="translate(-137.022 -135.238)" 
								fill={tagIconColor}/>
						</g>
					</g>
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
					<path
						id="MdPricetag"
						d="M91.485,64H79.111L64.963,78.66a3.318,3.318,0,0,0,0,4.7l9.664,9.659a3.586,3.586,0,0,0,2.25.977,3.917,3.917,0,0,0,2.453-.977L93.985,79V66.5Zm-2.4,8.026a2.5,2.5,0,1,1,1.672-1.672A2.433,2.433,0,0,1,89.087,72.026Z"
						transform="translate(-63.985 -64)"
						fill={tagIconColor}/>
				</svg>
				<p>{title}</p>
	 			</div>
		</button>
	);
};

export default AddTagButton;
