import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CommandPacketFormTab from './CommandPacketFormTab';
import TagsTab from 'components/tags/editors/TagsTab'
import CmdPacketAssocTab from './assoc-tab/CmdPacketAssocTab';
import EntityType from 'model/EntityType';

import { useASTData } from 'context/ASTContext';

import {useEditorPage} from 'components/ui-core/page/useEditorPage'
import EditorPage from 'components/ui-core/layout/EditorPage/EditorPage';
import ConfirmationRequest from 'model/ConfirmationRequest';
import SideBarOption from 'model/SideBarOption';

import {useCommandPacket, useCommandParams, 
		useMetadata, useTags, useSaveCommandPacket, 
		usePhysicallyDeleteCommandPacket, useLogicallyDeleteCommandPacket,
		useGetCommandPacketBoardFilter,
		useGetTelemetryPacketBoardFilter} from 'data/queryHooks';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import SummaryBar from 'components/ui-core/common/SummaryBar';
import SummaryContextProvider from 'components/ui-core/common/SummaryContext';


const CommandPacketPage = () => {
	// Ensure each Page has it's instance (fresh component tree) otherwise history.push()
	// to the same route will reuse components for other object navigate away from
	let {cmdPacketId} = useParams();
	return <CommandPacketPageBody key={cmdPacketId}/>
}

const CommandPacketPageBody = () => {

	const {setSideBarOption, setHeaderTitle, filterToggle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.CmdPacket);
		setHeaderTitle(null);
	}, []);

	let {cmdPacketId} = useParams();

	// Data queries
	const packetByIdQuery = useCommandPacket(cmdPacketId);
	const boardFilter= JSON.parse(localStorage.getItem('board_filter_object'));
	const allCmdPacketsQuery = useGetCommandPacketBoardFilter(boardFilter, filterToggle);
	const allTlmPacketsQuery = useGetTelemetryPacketBoardFilter(boardFilter, filterToggle);

	const allParamsQuery = useCommandParams();
	const metadataQuery = useMetadata();
	const tagsQuery = useTags();

	

	React.useEffect(() => {
		if (packetByIdQuery.data) {
			setHeaderTitle(packetByIdQuery.data?.cmd_name);	
		}
	}, [packetByIdQuery.data]);

	
	const  {mutateAsync: physicallyDelete} = usePhysicallyDeleteCommandPacket();
	const  {mutateAsync: logicallyDelete} = useLogicallyDeleteCommandPacket();

	const createDeleteRequest = () => {
		//const physically_delete = shouldPhysicallyDelete(packetByIdQuery.data)
		const physically_delete =true;
		return new ConfirmationRequest(
			cmdPacketId,
			physically_delete ? physicallyDelete : logicallyDelete,
			`Delete '${packetByIdQuery.data?.cmd_name}'`, 
			"Delete",
			"Are you sure you want to delete this command packet?",
			physically_delete ? '/' : null,
			`Command Packet '${packetByIdQuery.data?.cmd_name}' ${physically_delete ? "deleted" : " flagged for deletion"}`
		)
	}

	const tabDefs = [
		{
			id: "form_tab",
			buttonText: "Command Packet",
			buttonType: "CommandPacket",
			editMode: "formEditMode"
		},
		{
			id: "cmd_params_tab",
			buttonText: "Command Parameters",
			buttonType: "CommandParam",
			editMode: "formEditMode"
		},
		{
			id: "tags_tab",
			buttonText: "Tags",
			buttonType: "Tag",
			editMode: "formEditMode"
		}
	] 

	const {
        currentEditOption,
        selectedTabId,
        editModeActive,
        tabSelectedHandler,
        editModeTransitionHandler,
		formKey
    } = useEditorPage(tabDefs);

	const toolBarDef = 	{
		navButtons: [
			{
				title: "< View All Command Packets",
				width: "15.5rem",
				route: "/"
			}
		],
		manipulationButtons: {
			mutationButtons: {
				includeEdit: true,
				includeBuildMode: true,
				editModeTransitionHandler: editModeTransitionHandler
			},
			deleteButton: {
				createDeleteRequest: createDeleteRequest
			},
			copyButton: {
			}			
		}
	}

	const  {mutateAsync: saveCommandPacket} = useSaveCommandPacket();
	const updateEntityPromise = async (updatedPacket) => {

		return await saveCommandPacket(updatedPacket)
			.then(refreshedPacket => {
				toast.success(`'${updatedPacket.cmd_name}' updated`);
				return refreshedPacket;
			})
			.catch(error => {
				toast.error(`Command Packet update failed`);
				return Promise.reject(error)
			});				
	}

	const getAssocSummaryJsx = () => {
		return selectedTabId === "cmd_params_tab" 
			? <SummaryBar/>
			: <></>		
	} 

	return (
		<SummaryContextProvider>
			<EditorPage 
				toolBarDef={toolBarDef} currentEditOption={currentEditOption} 
				editModeActive={editModeActive} tabDefs={tabDefs} 
				selectedTabId={selectedTabId} tabSelectedHandler={tabSelectedHandler}
				underlyingEntityQuery = {packetByIdQuery}
				tabControls = {getAssocSummaryJsx}
			>		

				{selectedTabId === "form_tab" && 
					<LoadingWrapper 
						queries={[packetByIdQuery, allCmdPacketsQuery, metadataQuery]}
						onLoad={() => (
							<CommandPacketFormTab 
								key={formKey}
								updateEntityPromise={updateEntityPromise}
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								cmdPacket={packetByIdQuery.data}
								allCmdPackets={allCmdPacketsQuery.data}
								allTlmPacketsQuery={allTlmPacketsQuery}
								metadata={metadataQuery.data[0]}/>
						)}
					/>
				}
				{selectedTabId === "tags_tab" && 
					<LoadingWrapper 
						queries={[packetByIdQuery, tagsQuery]}
						onLoad={() => (
							<TagsTab 
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								underlyingEntity={packetByIdQuery.data}
								allTags={tagsQuery.data}
								entityType={EntityType.CmdPacket}/>
						)}
					/>
				}
				{selectedTabId === "cmd_params_tab" && 
					<LoadingWrapper 
						queries={[packetByIdQuery, allParamsQuery]}
						onLoad={() => (
							<CmdPacketAssocTab 
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								updateEntityPromise={updateEntityPromise}
								cmdPacket={packetByIdQuery.data}
								allParams={allParamsQuery.data}/>							
						)}
					/>
				}
			</EditorPage>
		</SummaryContextProvider>			
	);
};

export default CommandPacketPage;
