import style from 'App.module.css';

export const TabCellBoxContainer = (props) => {

    return ( 
        <div className={style.tableCellContainer}>
            {props.children}
        </div>
    )
}

export default TabCellBoxContainer