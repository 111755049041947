import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/ui-core/modal/Modal/Modal';
import CreateTelemetryPointWizard from './CreateTelemetryPointWizard';


import { useASTData } from 'context/ASTContext';
import {useMetadata, useTelemetryPointsOptimized, useGetTelemetryPacketBoardFilter} from 'data/queryHooks';

import SideBarOption from 'model/SideBarOption';

import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable';
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import { getTelemetryPointColumns } from 'components/tlm-point/TelemetryPointTableHelper';
import { getSubsystemDecoratedChildRows} from 'components/ui-core/utils/SubsystemUtils';
import EntityType from 'model/EntityType';
import { queries } from '@testing-library/react';

const TelemetryPointsTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);

	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.TlmPoint);
		setHeaderTitle("Telemetry Points");
		localStorage.setItem('lastPage',"/telemetry-points");
	}, [setSideBarOption, setHeaderTitle]);	

	const history = useHistory();
	// const viewTlmPointHandler = (tlmPointRow) => {
	// 	history.push(`/telemetry-point/${tlmPointRow._id}`);
	// };

	//const cachedFn = useCallback(fn, dependencies)

	
	const viewTlmPointHandler =React.useCallback( (tlmPointRow) => {
		history.push(`/telemetry-point/${tlmPointRow._id}`)
	},[history]);
	

	const columns = React.useMemo(() => getTelemetryPointColumns(viewTlmPointHandler), [viewTlmPointHandler]);


	const allPointsQuery = useTelemetryPointsOptimized();
	const boardFilter= JSON.parse(localStorage.getItem('board_filter_object'));
	const allPacketsQuery = useGetTelemetryPacketBoardFilter(boardFilter);
	const metadataQuery = useMetadata();
	const queries = React.useMemo(
		() => [allPointsQuery, allPacketsQuery]
		,
		[allPointsQuery, allPacketsQuery]
	);

	const mainData =  React.useMemo(
		() => {
		if(allPacketsQuery.data && allPointsQuery.data){
		
				return getSubsystemDecoratedChildRows(
				allPointsQuery.data, allPacketsQuery.data, EntityType.TlmPacket);
		}
		return [];
	}, [allPointsQuery.data, allPacketsQuery.data]);
	
	const handleOnload = React.useCallback(
		() => (
			<ManagedMsdTable 
					tableName="TelemetryPointsTablePage"
					columns={columns} 
					data={mainData}>
				
				<CreateButton
					width="10.5rem"
					title="Telemetry Point"
					onClick={() => {
						setCreateVisible(true);
					}}
				/>		
			</ManagedMsdTable>
		),
		[columns, mainData]
	)
	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[allPointsQuery]}
					onLoad={() => (
						<Modal>
							<CreateTelemetryPointWizard 
								setCreateVisible={setCreateVisible} 
								allPoints={allPointsQuery.data}
								metadata={metadataQuery.data[0]}							/>
						</Modal> 
					)}				
				/>
			}		

			
			<LoadingWrapper 
				queries={queries}
				onLoad={handleOnload}
			/>
		
		</MsdMainPage>
	);
};

export default TelemetryPointsTablePage;
