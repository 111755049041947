import React, {useState} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useASTData } from 'context/ASTContext';

import {useEditorPage} from 'components/ui-core/page/useEditorPage'
import EditorPage from 'components/ui-core/layout/EditorPage/EditorPage';
import SideBarOption from 'model/SideBarOption';

import {useTagObjects, usePushToProd, useGetCommandPacketBoardFilter, useGetTelemetryPacketBoardFilter } from 'data/queryHooks';

import ReviewCommandsTab from './ReviewCommandsTab';
import ReviewTelemetryTab from './ReviewTelemetryTab';
import ReviewStagedTab from './ReviewStagedTab';
import { ReviewPushLogTab } from './ReviewPushLogTab';

import MainButton from 'components/ui-core/buttons/MainButton/MainButton';

import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import { doesEntityContainTag } from 'components/tags/TagHelper';
import ManagedTag from 'model/ManagedTag';

import ConfirmationRequest from 'model/ConfirmationRequest';


const ReviewPage = () => {
	const envType = process.env.REACT_APP_ENV_TYPE;
	const pushEnvName = process.env.REACT_APP_PUSH_ENV_NAME;
	
	const {setSideBarOption, setHeaderTitle, setConfirmationRequest, filterToggle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.Review);
		setHeaderTitle("Review Pending Packets");
		localStorage.setItem('lastPage',"/review");
	}, []);



	// Data queries
	const boardFilter= JSON.parse(localStorage.getItem('board_filter_object'));
	const allCmdPacketsQuery = useGetCommandPacketBoardFilter(boardFilter, filterToggle);
	const allTlmPacketsQuery = useGetTelemetryPacketBoardFilter(boardFilter, filterToggle);

	const tabDefs = [
		{
			id: "review_cmd",
			buttonText: "Commands",
			buttonType: "CommandPacket",
			editMode: "none",
			pageTitle: "Review Pending Commands"
		},
		{
			id: "review_tlm",
			buttonText: "Telemetry",
			buttonType: "TelemetryPacket",
			editMode: "none",
			pageTitle: "Review Pending Telemetry"
		},
		{
			id: "review_staged",
			buttonText: `Staged`,
			buttonType: "Upload",
			editMode: "none",
			pageTitle: "Review Staged Packets",
			enabledWhen: () => envType === "FSW_DEV"
		},
		{
			id: "results",
			buttonText: `Results Log`,
			buttonType: "PushLog",
			editMode: "none",
			pageTitle: "Review Push Results Log",
			enabledWhen: () => envType === "FSW_DEV"
		}
	] 

	const {
        currentEditOption,
        selectedTabId,
        editModeActive,
        tabSelectedHandler,
        editModeTransitionHandler
    } = useEditorPage(tabDefs);

	const toolBarDef = 	{
		navButtons: [],
		manipulationButtons: {
			mutationButtons: {
				includeEdit: false,
				includeBuildMode: false,
				editModeTransitionHandler: editModeTransitionHandler
			}
		}
	}

	React.useEffect(() => {
		const tab = tabDefs.filter(tab => tab.id === selectedTabId)[0]
		setHeaderTitle(tab["pageTitle"]);	
	}, [selectedTabId, tabDefs]);

	const filterPacketToReview = (packetQuery) => {
		// Still to review are pending but NOT staged
		return filterPackets(packetQuery, ManagedTag.PENDING)
			.filter(packet => !doesEntityContainTag(packet, ManagedTag.STAGED_FOR_FSW_PROD.name))
	};

	const filterPackets = (packetQuery, managedTag) => {
		return packetQuery.data.filter(packet => doesEntityContainTag(packet, managedTag.name))
	};


	const  {mutateAsync} = useTagObjects();
	const updateTagsPromise = async (tagRequest, entityType) => {
		const update_desc = tagRequest.entity_tag_requests.length > 1 
			? entityType.description + "s"
			: tagRequest.entity_tag_requests[0].entity_name

		return await mutateAsync(tagRequest)
			.then((tagResponse) => {
				toast.success(`'${update_desc}' updated`);
				return tagResponse;
			})
			.catch(error => {
				toast.error(`${update_desc} update failed`);
				return Promise.reject(error)
			});				
	}
	    
	const {setSubmitInProgress} = useASTData();
    const submitUpdateTagRequest = (tagRequest, entityType) => {
        setSubmitInProgress(true);
        const submitPromise = updateTagsPromise(tagRequest, entityType);
        submitPromise
            .then(() => {
                setSubmitInProgress(false);
            })
            .catch(() => {
                setSubmitInProgress(false);
            })
    }

	const history = useHistory();
	const cmdPacketViewHandler = (cmdPacket) => {
		history.push(`/command-packet/${cmdPacket._id}`);
	};
	const tlmPacketViewHandler = (tlmPacket) => {
		history.push(`/telemetry-packet/${tlmPacket._id}`);
	};



	const {mutateAsync: pushToProd} = usePushToProd();
	const pushToProdPromise = async () => {
		await pushToProd().then(pushJob => {
			toast.success(`Push to Production initiated`);
			tabSelectedHandler("results")
		})
	}

	const pushTitle = `Push to ${pushEnvName}`
	const handlePushToProdRequested = () => {

		const message = 
			<div>
				Are you sure you want to push all staged packets to {pushEnvName}?
				<br/>
				<br/>
				This may take some time.
				<br/>
				<br/>
				In the target {pushEnvName} MSD instance this will overwrite all staged packets and 
				all their associated command parameters and telemetry points.
				<br/>
				<br/>
				In this MSD instance all system managed tags on staged objects will be removed and all objects
				flagged for deletion will be permanently deleted.
			</div>


		const confirmationRequest =  new ConfirmationRequest(
			null,
			pushToProdPromise,
			pushTitle, 
			"Push",
			message,
			null,
			null
		)

		setConfirmationRequest(confirmationRequest);
		console.log("Push Requested");
	}

	const getPushButtonJsx = () => {
		if (selectedTabId === "review_staged") {
			return ( 
				<LoadingWrapper 
					queries={[allCmdPacketsQuery, allTlmPacketsQuery]}
					displayIndicator={false}
					onLoad={() => {
						const disabled = filterPackets(allCmdPacketsQuery, ManagedTag.STAGED_FOR_FSW_PROD).length === 0
							&& filterPackets(allTlmPacketsQuery, ManagedTag.STAGED_FOR_FSW_PROD).length === 0

						return (
							<MainButton 
								width={`${pushTitle.length * 0.7}rem`}
								title={pushTitle} 
								disabled= {disabled}
								onClick={handlePushToProdRequested}
							/>
						)
					}}
				/>
			);
		}
	}

	return (
		<EditorPage 
			toolBarDef={toolBarDef} currentEditOption={currentEditOption} 
			editModeActive={editModeActive} tabDefs={tabDefs} 
			selectedTabId={selectedTabId} tabSelectedHandler={tabSelectedHandler} tabControls={getPushButtonJsx}
		>		

			{selectedTabId === "review_cmd" && 
				<LoadingWrapper 
					queries={[allCmdPacketsQuery]}
					onLoad={() => (
						<ReviewCommandsTab 
							pendingPackets={filterPacketToReview(allCmdPacketsQuery)}
							viewHandler={cmdPacketViewHandler}
							submitUpdateTagRequest={submitUpdateTagRequest}/>
					)}
				/>
			}

			{selectedTabId === "review_tlm" && 
				<LoadingWrapper 
					queries={[allTlmPacketsQuery]}
					onLoad={() => (
						<ReviewTelemetryTab 
							pendingPackets={filterPacketToReview(allTlmPacketsQuery)}
							viewHandler={tlmPacketViewHandler}
							submitUpdateTagRequest={submitUpdateTagRequest}/>
				)}
				/>
			}

			{selectedTabId === "review_staged" && 
				<LoadingWrapper 
					queries={[allCmdPacketsQuery, allTlmPacketsQuery]}
					onLoad={() => (
						<ReviewStagedTab 
							stagedCmdPackets={filterPackets(allCmdPacketsQuery, ManagedTag.STAGED_FOR_FSW_PROD)}
							stagedTlmPackets={filterPackets(allTlmPacketsQuery, ManagedTag.STAGED_FOR_FSW_PROD)}
							cmdPacketViewHandler={cmdPacketViewHandler}
							tlmPacketViewHandler={tlmPacketViewHandler}
							submitUpdateTagRequest={submitUpdateTagRequest}/>
					)}
				/>
			}

			{selectedTabId === "results" && 
				<ReviewPushLogTab /> 
			}

		</EditorPage>
	);
};

export default ReviewPage;
