import CreateOptionWizard from "components/options/CreateOptionWizard";
import EditOptionValueModal from "components/options/EditOptionValueModal";
import OptionsTable from "components/options/OptionsTable";
import { getBitwiseOptionsSchema, getEnnumerationLimitOptionsSchema, getOptionsSchema } from "model/form-schema/ObjectSchemas";
import React from "react";
import cloneDeep from 'lodash/cloneDeep';
import { useState } from "react";
import useFormTab from "components/ui-core/form/useFormTab";
import Modal from "components/ui-core/modal/Modal/Modal";
import EntityType from "model/EntityType";
import FormTab from "components/ui-core/form/FormTab";
import { FormRow, Select } from "components/ui-core/form/FormControls";
import { areArrayEqualsAndInOrder } from "components/ui-core/utils/ArrayUtils";

export const EnumsEditForm = ({createEditEnumsOptionPromise, editEnumValue, setEditEnumValue, schema, totalOptionsData }) => {

    const initialOptionsData = React.useMemo(
		() => {
			let optionsData = 
				editEnumValue['discrete_values']
				? editEnumValue['discrete_values'].map(opt => cloneDeep(opt))
				: [];
	
			return optionsData;
		},
		[]
	);

	const [createVisible, setCreateVisible] = useState(false);
	const [editEnumOptionValue, setEnumEditOptionValue] = useState(null);	
	const [optionsData, setOptionsData] = useState(initialOptionsData);	
    



	const {useFormObj, controlProps} = useFormTab(editEnumValue, schema, true);

	const  createSubmitPromise = async (formData) => {
		let updatedEnumRow = {
			...formData
		};

		updatedEnumRow["discrete_values"] = optionsData;
        createEditEnumsOptionPromise(updatedEnumRow);
        console.log(updatedEnumRow);
        return Promise.resolve();
	};

    const editModeTransitionHandler = () => {
        setEditEnumValue(null);
    }

	const handleOptionDelete = (optionToDelete) => {
		setOptionsData(oldOptions => {
			const newOptions = [...oldOptions].filter(option => option.name !== optionToDelete.name);
			return newOptions; 
		})

		return Promise.resolve();

	}

	const createAddOptionPromise = async (optionFormToAdd) => {
		setOptionsData(oldOptions => {
			const newOptions = [...oldOptions];
			newOptions.push({...optionFormToAdd});
			return newOptions; 
		})
		return Promise.resolve();
	};
		
	const createEditOptionPromise = async (updatedBitwise) => {
		console.log({updatedBitwise});

		setOptionsData(oldOptions => {

			const newOptions =  [...oldOptions];
            const index = newOptions.findIndex(opt => opt.name === editEnumOptionValue.name);
			newOptions.splice(index, 1, {...updatedBitwise});
			return newOptions;
		})

		return Promise.resolve();
	};

	const {watch, getValues, formState: { isDirty, isValid}} = useFormObj;
	// watch("size_bits");

	const hasUserMadeChanges = () => {
		console.log("hasUserMadeChanges", isEnumsOptionsValid(), isEnumsOptionsDirty())
		return isEnumsOptionsValid() && isEnumsOptionsDirty();
	}

	const isEnumsOptionsValid = () => {
		return isValid && getTableErrors().length === 0;
	}

	const isEnumsOptionsDirty = () => {
        console.log(initialOptionsData, optionsData);		
		const arrEqual = areArrayEqualsAndInOrder(
			initialOptionsData, optionsData, 
			(optA, optB) => optA.name === optB.name && optA.value === optB.value);


		// true if either form or table is dirty 
		return isDirty || !arrEqual;
	}

	// const sizeBits = getValues("size_bits");
	// const maxOptionValue = Math.pow(2, sizeBits) - 1;
	const getTableErrors = () => {
		const result = [];

		// check duplicate values
		optionsData.forEach(thisOpt => {
			const otherWithSameValue = optionsData.find(otherOpt => 
				otherOpt.value === thisOpt.value && otherOpt.name !== thisOpt.name)
		
			if (otherWithSameValue) {
				result.push(`Options ${thisOpt.name} and ${otherWithSameValue.name} have the same value`)
			}
		});

		// // check with in range of size_bits
		// optionsData.forEach(opt => {

		// 	if (opt.value > maxOptionValue) {
		// 		result.push(`Options ${opt.name} must be less than or equal to ${maxOptionValue}`)		
		// 	}
		// 	if (opt.value < 0) {
		// 		result.push(`Options ${opt.name} must be not be negative`)		
		// 	}
		// });

		return result;
	}

	const createOptionSchema = () => getOptionsSchema(optionsData, editEnumOptionValue ? editEnumOptionValue.name : null);  


	return (
		<>	
			{/* Note: The wizard needs to be outside the tab to avoid nested forms */}
			{createVisible && 
				<Modal>
					<CreateOptionWizard
						createAddOptionPromise={createAddOptionPromise}
						schema={createOptionSchema()}
						setCreateVisible={setCreateVisible}
						entityType={EntityType.TlmPoint}
						optionsData={optionsData}
						maxOptionValue={null}
					/>
				</Modal> 
			}

			{editEnumOptionValue && 
				<Modal>
					<EditOptionValueModal
						createEditOptionPromise={createEditOptionPromise}
						schema={createOptionSchema()}
						editOptionValue={editEnumOptionValue}
						setEditOptionValue={setEnumEditOptionValue}
						entityType={EntityType.BitwisePoint}
					/>
				</Modal> 
			}		

			<FormTab editModeActive={true}
					editModeTransitionHandler={editModeTransitionHandler}
					createSubmitPromise={createSubmitPromise}
					useFormObj={useFormObj}
                    saveButtonTitle="Save"
                    saveButtonWidth = '8.5rem'
                    customSubmitEnabledFn={hasUserMadeChanges}
			>

                <div >
                    <FormRow rowNum="3">
                        <label>Enumerations Menu for {editEnumValue['sc_mode']}</label>
                    </FormRow>

                    <OptionsTable
                        optionsData={optionsData}
                        setCreateVisible={setCreateVisible}
                        setEditOptionValue={setEnumEditOptionValue}
                        handleDelete={handleOptionDelete}
                        entityType={EntityType.BitwisePoint}
                    />
{/* 
                    {tableError.length > 0 &&
                        <p className={formControlsStyle.formErrorMessage}>
                            {tableError[0]}
                        </p>
                    }	         */}

				</div>
			</FormTab>

			
		</>
	);
}

export default EnumsEditForm;