import Tag from 'components/tags/Tag';
import { sortTagNames } from './TagHelper';
import TabCellBoxContainer from 'components/ui-core/table/TabCellBoxContainer';

export const TagPanel = ({tagArr}) => {

    let includedTags = tagArr?.filter(tag => tag.value)
        .map(entry => entry.name)
        .sort(sortTagNames)

    return ( 
        <TabCellBoxContainer>
            {
                includedTags?.map((tagName) => 
                    <Tag key={tagName} title={tagName} />
                )
            }	
        </TabCellBoxContainer>
    )
}

export default TagPanel