import React, {useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import Modal from 'components/ui-core/modal/Modal/Modal';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';

import { useASTData } from 'context/ASTContext';
import { useMetadata, useSaveMetadata} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import SideBarOption from 'model/SideBarOption';
import ConfirmationRequest from 'model/ConfirmationRequest';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'
import CreateBoardWizard from './CreateBoardWizard';
import { EditBoardModal } from './EditBoardModal';
import { useAuthContext } from 'components/ui-core/auth/AuthContext';
import RefreshButton from 'components/ui-core/buttons/RefreshButton/RefreshButton';

const BoardVersionsPage = () => {
	const {user, logoutUser} = useAuthContext();
	const [createVisible, setCreateVisible] = useState(false);
	const [editBoard, setEditBoard] = useState(null);	
	const {setConfirmationRequest} = useASTData();

	const {setSideBarOption, setHeaderTitle} = useASTData();
	const [filter, setFilter] = useState([]);
	React.useEffect(() => {
		setSideBarOption(SideBarOption.BoardVersions);
		setHeaderTitle("Board Filter");
		localStorage.setItem('lastPage',"/board-filter");
	}, [setSideBarOption,setHeaderTitle]);

	const metadataQuery = useMetadata();
	
	useEffect(() => {
		if(metadataQuery.data){
			const boards = metadataQuery.data[0]["nodes"]
			const semver_boards = boards.map(board => ({board: board.node, 	semver: "latest" }));
			const validSemverBoards = semver_boards && semver_boards.length > 0 ? semver_boards : [];
			//to assign the default filter in board_filter_object
			if(localStorage.getItem('board_filter_object') == null || localStorage.getItem('board_filter_object') == "[]") {
				localStorage.setItem('board_filter_object', JSON.stringify(validSemverBoards));
				setFilter(validSemverBoards);
			}
		}
	}, [metadataQuery.data])

	const extractMetadataFromQuery = () => {
		// the below if condtion is for a rare corner case ,  only executes when last page is /board-versions and board_filter_object is empty / key pair not availalbe
		if(localStorage.getItem('board_filter_object') == null){
			localStorage.setItem('board_filter_object', JSON.stringify([]));
			setFilter([]);
		}
		const semver_data = JSON.parse(localStorage.getItem('board_filter_object'));
		const formatted_semver_data = semver_data.map(({ board, semver }) => ({
			name: board.toUpperCase(),
			version: semver,
			modified_by: null,
			modified_date: null
		  }));
		return formatted_semver_data;
	}

	const requestDelConfirmationHandler = (boardRow) => {
		let deleteRequest =  new ConfirmationRequest(
			boardRow,
			async () => performBoardDeletionHandler(boardRow),
			`Delete '${boardRow.name}'`, 
			"Delete",
			"Are you sure you would like continue?",
			null,
			`Board '${boardRow.name}' deleted`,
		);
	
		setConfirmationRequest(deleteRequest);
	}
    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(requestDelConfirmationHandler)

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Version',
				accessor: 'version',
				Filter: TextInputColumnFilter,
				Cell: ({ value, row, column, updateData }) => {
					const [inputValue, setInputValue] = useState(value);
					const [debouncedValue, setDebouncedValue] = useState(value);
					const correctDebounceValue = (version) => {
						const correctRegex = /^(?:[0-9]{1,4}\.){2}[0-9]{1,4}$/
						if(!correctRegex.test(version) || version == "latest" || version == "9999.9999.9999"){
							return "latest";
						}
						return version;
					}

					useEffect(() => {
						const timer = setTimeout(() => {
							setDebouncedValue(inputValue);
						}, 1500); 

						return () => clearTimeout(timer);
					}, [inputValue]);
			
					useEffect(() => {
						if (debouncedValue !== value) {
							const updatedBoards = JSON.parse(localStorage.getItem('board_filter_object'));
							updatedBoards[row.index]["semver"] = correctDebounceValue(debouncedValue);
							setFilter(updatedBoards);
							localStorage.setItem('board_filter_object', JSON.stringify(updatedBoards));
						}
					}, [debouncedValue]);
			
					return (
						<input
							type="text"
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
							style={{
								padding: '8px',
								border: '1px solid #ccc',
								borderRadius: '4px',
								width: '15rem',
								outline: 'none',
								transition: 'border-color 0.2s',
							 }}
							 onFocus={(e) => e.target.style.borderColor = '#007BFF'}
							 onBlur={(e) => e.target.style.borderColor = '#ccc'}
						/>
					);
				}
			
			},
			// {
			// 	Header: ' ',
			// 	accessor: (boardRow) => {
			// 		return (
			// 			<div className={style.tableButtonContainer}>
			// 				<TableEditButton editHandler={() => setEditBoard(boardRow)}/>
			// 				<TableDeleteButton deletionHandler={() => submitWithAppropriateWarnings(boardRow)}/>
			// 			</div>
			// 		);
			// 	},
			// 	columnType: "buttons"
			// },
		],
		[submitWithAppropriateWarnings]
	);

	const  {mutateAsync: updateMetadata} = useSaveMetadata();
	const performBoardDeletionHandler = async (boardToDelete) => {
		const old_semver_boards = JSON.parse(localStorage.getItem("board_filter_object"));
		const new_semver_boards = old_semver_boards.filter(board => board["board"] !== boardToDelete.name);
		localStorage.setItem("board_filter_object", JSON.stringify(new_semver_boards));
		setFilter(new_semver_boards);
		toast.success(`Board '${boardToDelete.name}' deleted`);
		return null;
	};

	const resetBoardValues = () => {
		const semver_data = JSON.parse(localStorage.getItem('board_filter_object'));
		const semver_data_reset = semver_data.map(({ board }) => ({
			board: board.toUpperCase(),
			semver: "latest"
		  }));
		localStorage.setItem('board_filter_object', JSON.stringify(semver_data_reset));
		setFilter(semver_data_reset);
	}

	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateBoardWizard 
								setCreateVisible={setCreateVisible}
								metadata={metadataQuery.data[0]}
								userName={user.getUsername()}
								dataExtraction={extractMetadataFromQuery}
							/>
						</Modal> 
					)}				
				/>
			}

			{editBoard && 
				<LoadingWrapper 
					queries={[metadataQuery]}
					onLoad={() => (
						<Modal>
							<EditBoardModal
								setEditBoard={setEditBoard}
								editBoard={editBoard}
								metadata={metadataQuery.data[0]}
								userName={user.getUsername()}
								dataExtraction={extractMetadataFromQuery}
							/>
						</Modal> 
					)}				
				/>
			}

			<MsdQueryTable tableName="BoardVersionPage"
					columns={columns} query={metadataQuery} dataExtractFn={extractMetadataFromQuery}
			>
				<RefreshButton
					width="15rem"
					title="Refresh Board Versions"
					onClick={() => {
						resetBoardValues();
					}}
				/>
				{/* <CreateButton
					width="12rem"
					title="Add New Board"
					onClick={() => {
						setCreateVisible(true);
					}}
				/>		 */}
			</MsdQueryTable>
		</MsdMainPage>
	);
}; 

export default BoardVersionsPage;
