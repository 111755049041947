import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';

import {getCmdParamSchema} from 'model/form-schema/ObjectSchemas'
import CommandParameterFormContent from 'components/cmd-param/param-page/CommandParameterFormContent'
import {useCreateCommandParam} from 'data/queryHooks';
import {lookupLongUnitValue} from 'components/ui-core/utils/MetadataHelper'

export const CreateCommandParameterWizard = ({setCreateVisible, allParams, metadata}) => {

	const schema = getCmdParamSchema(allParams, null);
	const {useFormObj, controlProps} = useWizardForm({}, schema);
	const  {mutateAsync: addCommandParam} = useCreateCommandParam();

	const createSubmitPromise = async (formData) => {

		let newCommandParam = {
			...formData,
			units_long: lookupLongUnitValue(metadata, formData.units_short),
			param_options_list: [],
			satellite: ["BW3"],
			environment_tags: [],
			time_created: dayjs(new Date()).toISOString(),
			depreciated: {
				depreciated_status: false,
				notes: null
			}
		};


		return await addCommandParam(newCommandParam)
			.then(response => {
				toast.success(`Command Parameter '${newCommandParam.cmd_param_name}' created`);
				return response;
			})
			.catch(error => {
				toast.error(`Command Parameter creation failed: ${error.message}`);
				return Promise.reject(error)
			});

	};


	return (
		<Wizard width='80rem'>
			<WizardForm title="Create Command Parameter" getObjectbyIdRoutePrefix='/command-parameter'
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
			>
				<CommandParameterFormContent 
					controlProps={controlProps} 
					useFormObj={useFormObj}
					metadata={metadata}
					isWizard={true}
					isByteReadOnly={false}
				/>
			</WizardForm>
		</Wizard>
	);
};

export default CreateCommandParameterWizard;
