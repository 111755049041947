import { useState, createContext, useContext } from 'react';

const SummaryContext = createContext();
export const useSummmaryContext = () => useContext(SummaryContext);

const SummaryContextProvider = ({ children }) => {

	const [summaryItems, setSummaryItems] = useState([]);	
	
	const value = {
		summaryItems, 
		setSummaryItems
	};

	return <SummaryContext.Provider value={value}>{children}</SummaryContext.Provider>;
};

export default SummaryContextProvider;
