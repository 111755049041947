import React from 'react';
import PropTypes from 'prop-types';
import RefreshIcon from 'img/refresh.svg';
import style from './RefreshButton.module.css';

export const RefreshButton = ({ title, width, additionalStyles = {}, ...props }) => {
	const styles = { width: width, ...additionalStyles}

	return (
		<>
			<button {...props} style={styles} className={style.RefreshButton}>
				<img className={style.RefreshIcon} src={RefreshIcon} alt="Refresh icon" />
				{title}
			</button>
		</>
	);
};

RefreshButton.propTypes = {
	title: PropTypes.string.isRequired,
	width: PropTypes.string.isRequired,
};

RefreshButton.defaultProps = {
	title: 'Tag',
	width: '11.5rem',
};

export default RefreshButton;
