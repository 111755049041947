import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CreateTelemetryPacketWizard from './CreateTelemetryPacketWizard';

import Modal from 'components/ui-core/modal/Modal/Modal';

import { useASTData } from 'context/ASTContext';
import {useMetadata, useGetTelemetryPacketBoardFilter, useGetCommandPacketBoardFilter} from 'data/queryHooks';


import SideBarOption from 'model/SideBarOption';

import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import { getTelemetryPacketColumns } from './TelemetryPacketTableHelper';
import ToggleSwitch from 'components/ui-core/buttons/ToggleSwitch/ToggleSwitch';

const TelemetryPacketsTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);

	const {setSideBarOption, setHeaderTitle, filterToggle, setFilterToggle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.TlmPacket);
		setHeaderTitle("Telemetry Packets");
		localStorage.setItem('lastPage',"/telemetry-packets");
	}, []);	

	const history = useHistory();
	const viewTelemetryPacketHandler = (tmlPacket) => {
		history.push(`/telemetry-packet/${tmlPacket._id}`);
	};
	if(localStorage.getItem('board_filter_object') == null){
		localStorage.setItem('board_filter_object', "[]");
	}

	const columns = React.useMemo(() => getTelemetryPacketColumns(viewTelemetryPacketHandler), []);
	const boardFilter= JSON.parse(localStorage.getItem('board_filter_object'));
	const allTlmPacketsQuery = useGetTelemetryPacketBoardFilter(boardFilter, filterToggle);
	const allCmdPacketsQuery = useGetCommandPacketBoardFilter(boardFilter, filterToggle);

	const metadataQuery = useMetadata();

	const onToggleChange = () => {
        setFilterToggle(prevState => !prevState);
    };

	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 

					queries={[allTlmPacketsQuery, metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateTelemetryPacketWizard 
								setCreateVisible={setCreateVisible} 
								allTlmPackets={allTlmPacketsQuery.data}
								allCmdPacketsQuery={allCmdPacketsQuery}
								metadata={metadataQuery.data[0]}
								copyPacket={null}
							/>
						</Modal> 
					)}				
				/>
			}			
			
			<MsdQueryTable tableName="TelemetryPacketsTablePage" columns={columns} query={allTlmPacketsQuery}>
				<div>
					<ToggleSwitch
						editModeActive={true}
						value={filterToggle}
						title= "Board Filter Flag"
						buttonText="Board Filter "
						onChange={onToggleChange}
					/>
				</div>
				<CreateButton
					width="13rem"
					title="Telemetry Packet"
					onClick={() => {
						setCreateVisible(true);
					}}
				/>		
			</MsdQueryTable>			
		</MsdMainPage>
	);
};

export default TelemetryPacketsTablePage;
