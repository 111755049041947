import React from 'react';
import style from './Form.module.css';
import { useHistory } from 'react-router-dom';

import MainButton from 'components/ui-core/buttons/MainButton/MainButton';
import CloseButton from "components/ui-core/buttons/CloseButton/CloseButton";

import { useASTData } from "context/ASTContext";
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'

export const WizardForm = ({title, setWizardVisible, useFormObj, 
        createSubmitPromise, getObjectbyIdRoutePrefix, 
        inhibitProdWarning=false, submitButtonTitle="Create", copyPacketType, ...props} ) => {
    
    const {setSubmitInProgress} = useASTData();
    const history = useHistory();            
    title = copyPacketType? copyPacketType === 'cmd_type' ? 'Copy Command Packet' : 'Copy Telemetry Packet' : title ;
    const {
		handleSubmit,
		formState: { isDirty, isValid }
	} = useFormObj;

    // let isEnabled = isDirty && isValid;
    console.log(`isDirty: ${isDirty}, isValid: ${isValid}`)

    const submitWrapper = (event) => {
        setSubmitInProgress(true);
        const submitPromise = createSubmitPromise(event);
        submitPromise
            .then((createdObject) => {
                console.log("setSubmitInProgress = false");
                setSubmitInProgress(false);
                if (getObjectbyIdRoutePrefix) {
                    history.push(`${getObjectbyIdRoutePrefix}/${createdObject._id}`)
                } else {
                    setWizardVisible(false)
                }
            })
            .catch((error) => {
                console.log({error});
                setSubmitInProgress(false);
            })
    }

    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(submitWrapper, inhibitProdWarning)

	return ( 
        <form onSubmit={handleSubmit(submitWithAppropriateWarnings)} className={style.msdForm}>

            <div className={style.wizardHeader}>
                <div>
                    <h1>{title}</h1>
                </div>
                <div>
                    <div onClick={() => {setWizardVisible(false)}}>
                        <CloseButton />
                    </div>
                </div>
            </div>

            {props.children}

            <div className={style.wizardFooter}>
                <MainButton 
                    title={submitButtonTitle} 
                    type="submit" 
                    onSubmit={handleSubmit(submitWithAppropriateWarnings)}
                />
            </div>
        </form>  
	);
};

export default WizardForm;
