import React, {Children} from 'react';
import style from './TableBarControls.module.css';

export const TableBarControls = (props) => {
	const childArr = Children.toArray(props.children);

	return (
		<div className={style.tableBarControlsContainer}>
			{childArr.map((child) => {
				return (
					<div key={child.key} className={style.tableBarControl}>
						{child}
					</div>
				);
			})}                    

		</div>
	);
};

export default TableBarControls;
