// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloseButton_closeBtnContainer__U6ao9 div {
	display: flex;
	flex-flow: row;
	border: none;
	background: none;
	align-items: center;
	color: var(--ast-primary-orange-color);
	font-size: 1.125rem;
	width: 5.5rem;
	height: 2rem;
	justify-content: space-between;
	cursor: pointer;
}

/* Responsive website scaling for 1920 x 1080 screens */

@media screen and (max-width: 1920px) {
	.CloseButton_closeBtnContainer__U6ao9 svg {
		width: 1.5rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/buttons/CloseButton/CloseButton.module.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,cAAc;CACd,YAAY;CACZ,gBAAgB;CAChB,mBAAmB;CACnB,sCAAsC;CACtC,mBAAmB;CACnB,aAAa;CACb,YAAY;CACZ,8BAA8B;CAC9B,eAAe;AAChB;;AAEA,uDAAuD;;AAEvD;CACC;EACC,aAAa;CACd;AACD","sourcesContent":["@import 'App.module.css';\r\n\r\n.closeBtnContainer div {\r\n\tdisplay: flex;\r\n\tflex-flow: row;\r\n\tborder: none;\r\n\tbackground: none;\r\n\talign-items: center;\r\n\tcolor: var(--ast-primary-orange-color);\r\n\tfont-size: 1.125rem;\r\n\twidth: 5.5rem;\r\n\theight: 2rem;\r\n\tjustify-content: space-between;\r\n\tcursor: pointer;\r\n}\r\n\r\n/* Responsive website scaling for 1920 x 1080 screens */\r\n\r\n@media screen and (max-width: 1920px) {\r\n\t.closeBtnContainer svg {\r\n\t\twidth: 1.5rem;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeBtnContainer": `CloseButton_closeBtnContainer__U6ao9`
};
export default ___CSS_LOADER_EXPORT___;
