import React, {useState} from 'react';

import AddTagButton from 'components/tags/AddTagButton';
import RemoveTagButton from 'components/tags/RemoveTagButton';
import Tag from 'components/tags/Tag';

import style from 'App.module.css';

import MsdTable from 'components/ui-core/table/msdTable/MsdTable';
import useMsdTable from 'components/ui-core/table/msdTable/useMsdTable';

import { getUserAddableManagedTags, getUserRemovableManagedTags} from 'model/ManagedTag';
import { isTagAddable, isTagAddableToAll, isTagRemoveable, isTagRemoveableFromAll} from 'components/tags/TagHelper';
import { getEntityTagsAsStrArr, sortTagNames} from 'components/tags/TagHelper';
import {TagColumnFilter, filterTag} from 'components/ui-core/table/filter/TagColumnFilter';


export const useReviewPacketsTable = ({tableName, baseColumns, packets, viewHandler, 
		entityType, submitUpdateTagRequest, pageAllowedAdditions, pageAllowedRemovals}) => {

    const createEmptyUpdateRequest = () => ({entity_tag_requests: []});
	const addEntityToRequest = (request, entityRow, tagName, isAdd) => {

		const newTagsList = [...entityRow.environment_tags]

		const existingTagEntry = newTagsList.find(tagEntry => tagEntry.name === tagName);
		if (!existingTagEntry) {
			newTagsList.push({name: tagName, value: isAdd});
		} else {
			existingTagEntry.value = isAdd;
		}

		request.entity_tag_requests.push({
			id: entityRow["_id"],
			entity_name: entityRow[entityType.nameAttr],
			entity_type_code: entityType.code,
			tag_model_list: newTagsList
		})
	}

	const pageRemovalTags = getUserRemovableManagedTags().filter(tag => pageAllowedRemovals.includes(tag))
	const pageAddableTags = getUserAddableManagedTags().filter(tag => pageAllowedAdditions.includes(tag))

	const [multiSelection, setMultipleSelection] = useState(false);

	const columns = React.useMemo(
		() => [
			...baseColumns,
			{
				id: "CurrentTags",
				Header: 'Current Tags',
				Cell: (tableInstance) => {
					const entityRow = tableInstance.row.original;
					const currentTagNames = getEntityTagsAsStrArr(entityRow)
						.sort(sortTagNames);
					const possibleRemovals = pageRemovalTags
						.filter(tag => isTagRemoveable(currentTagNames, tag.name))
						.map(tag => tag.name)

					return (
						<div className={style.tableCellContainer}>
							{
								currentTagNames.map(tagName => {
									return !possibleRemovals.includes(tagName)
										? <Tag key={tagName} title={tagName}/>
										: <RemoveTagButton key={tagName} title={tagName} disabled={multiSelection}
											onClick={() => handleTagChangeToRow(entityRow, tagName, false)}/>
								})
							}

						</div>
					);
				},
				Filter: TagColumnFilter,
				filter: filterTag
			},

			{
				id: "AddAndViewButtons",
				Header: ' ',
				Cell: (tableInstance) => {
					const entityRow = tableInstance.row.original;
					const currentTagNames = getEntityTagsAsStrArr(entityRow);
					const possibleAdditions = pageAddableTags
						.filter(tag => isTagAddable(entityRow, entityType, currentTagNames, tag.name))
						.map(tag => tag.name)
						.sort(sortTagNames)

					return (
						<div className={style.tableCellContainer}>
							<button className={style.tableTextButton} 
								onClick={() => viewHandler(entityRow)}
							>
								View
							</button>

							{
								possibleAdditions.map((tagName) => 
								<AddTagButton key={tagName} title={tagName} disabled={multiSelection}
									onClick={() => handleTagChangeToRow(entityRow, tagName, true)}/>
								)
							}

						</div>
					);
				}
			}
		], 
		[packets, multiSelection]
	);    

	const useMsdTableObj = useMsdTable(
		{
			tableName: tableName,
			columns: columns, 
			data: packets, 
			sortBy: null, 
			addSort: true, 
			addSelection: true,
			addPagination: true,
			idAttrName: entityType.nameAttr
		}		
	);

    const {selectedFlatRows} = useMsdTableObj;
	const getSelectedEntities = () => selectedFlatRows.map(row => row.original) 
	if (multiSelection && selectedFlatRows.length < 2) {
		setMultipleSelection(false);
	} else if (!multiSelection && selectedFlatRows.length > 1) {
		setMultipleSelection(true);
	}

	const handleTagChangeToRows = (rows, tagName, isAdd) => {
		const updateRequest = createEmptyUpdateRequest();
		rows.forEach(row => addEntityToRequest(updateRequest, row, tagName, isAdd));

		submitUpdateTagRequest(updateRequest, entityType)
	}

	const handleTagChangeToRow = (row, tagName, isAdd) => {
		handleTagChangeToRows([row], tagName, isAdd)
	}

	const handleTagChangeToSelected = (tagName, isAdd) => {
		const updateRequest = createEmptyUpdateRequest();
		getSelectedEntities().forEach(row => addEntityToRequest(updateRequest, row, tagName, isAdd));

		submitUpdateTagRequest(updateRequest, entityType)	
	}

	const possibleSelectionRemovals = pageRemovalTags
		.filter(tag => isTagRemoveableFromAll(getSelectedEntities(), tag.name))
		.map(tag => tag.name)
		.sort(sortTagNames)

	const possibleSelectionAdditions = pageAddableTags
		.filter(tag => isTagAddableToAll(getSelectedEntities(), entityType, tag.name))
		.map(tag => tag.name)
		.sort(sortTagNames)

    const tableJsx = 
        <MsdTable useMsdTableObj={useMsdTableObj}>
			{multiSelection &&
				<div className={style.tableCellContainer}>
					{
						possibleSelectionRemovals.map((tagName) => 
							<RemoveTagButton key={tagName} title={tagName}
								onClick={() => handleTagChangeToSelected(tagName, false)}/>
						)
					}
					{
						possibleSelectionAdditions.map((tagName) => 
							<AddTagButton key={tagName} title={tagName} 
								onClick={() => handleTagChangeToSelected(tagName, true)}/>
						)
					}
				</div>
			}
        </MsdTable>

    return tableJsx;
}

export default useReviewPacketsTable;