import React from 'react';
import {areAnyLoadingOrIdle, wereAnyErrors, getAllErrors} from 'components/ui-core/utils/QueryHelper'

import style from './LoadingWrapper.module.css'

function LoadingWrapper({queries, onLoad, displayIndicator = true}) {

    return (
        <>
            {
                areAnyLoadingOrIdle(queries) 
                    ? displayIndicator
                        ? (
                            <div className={style.loadingSpinner}>
                                <span>Loading...</span>
                            </div>)
                        : <></> 
                    : wereAnyErrors(queries) 
                        ? displayIndicator
                            ? (getAllErrors(queries))
                            :  <></>
                        : (onLoad())		
            }
        </>
    );
}
export default LoadingWrapper;
