import React from 'react';
import { toast } from 'react-toastify';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getTagSchema} from 'model/form-schema/ObjectSchemas'
import TagFormContent from './TagFormContent'
import {useSaveTag} from 'data/queryHooks';
import {isManagedTag} from 'components/tags/TagHelper';

export const EditTagModal = ({ setEditTag, editTag, allTags }) => {

	const schema = getTagSchema(allTags, editTag.name);
	const {useFormObj, controlProps} = useWizardForm(editTag, schema, 'onChange');
	const  {mutateAsync: saveTag} = useSaveTag();

	const createSubmitPromise = async (formData) => {
		
		console.log("---- Saving Env Tag");

		return await saveTag(formData)
			.then(refreshedTag => {
				toast.success(`Tag '${refreshedTag.name}' updated`);
				
				console.log("---- Toasting Env Tag");
				
				return refreshedTag;
			})
			.catch(error => {
				toast.error(`Tag update failed`);
				return Promise.reject(error)
			});				
	};

	const closeWizard = (wizardVisible) => {
		if (!wizardVisible) {
			setEditTag(null);
		}
	}

	return (
		<Wizard width='40rem'>
			<WizardForm title="Edit Tag" 
				setWizardVisible={closeWizard} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
				submitButtonTitle="Edit"
				inhibitProdWarning={true}
			>
				<TagFormContent controlProps={controlProps} protectedTag={isManagedTag(editTag.name)}/>
			</WizardForm>
		</Wizard>		
	);
};


export default EditTagModal;
