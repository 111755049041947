import React from 'react';

import MsdTable from './MsdTable'
import useMsdTable from './useMsdTable'

export const ManagedMsdTable = ({ tableName, columns, data, sortBy, 
		addSort=true, addSelection=false, addPagination=true, dndSpec, ...props }) => {
    
    const useMsdTableObj = useMsdTable({tableName, columns, data, 
		sortBy, addSort, addSelection, addPagination, dndSpec, options: {}} );

	return (
		<MsdTable useMsdTableObj={useMsdTableObj} {...props} />
	);
};

export default ManagedMsdTable;
