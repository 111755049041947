import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CreateCommandParameterWizard from 'components/cmd-param/table-page/CreateCommandParameterWizard';
import Modal from 'components/ui-core/modal/Modal/Modal';

import { useASTData } from 'context/ASTContext';
import {useCommandParams, useMetadata, useGetCommandPacketBoardFilter} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import SideBarOption from 'model/SideBarOption';

import { getTagColumn } from 'components/ui-core/table/TableHelper';
import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable';
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import { getSubsystemDecoratedChildRows, getSubsystemArrColumn} from 'components/ui-core/utils/SubsystemUtils';
import EntityType from 'model/EntityType';

const CommandParametersTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);
	const {setSideBarOption, setHeaderTitle, filterToggle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.CmdParam);
		setHeaderTitle("Command Parameters");
		localStorage.setItem('lastPage',"/command-parameters");
	}, []);


	const history = useHistory();
	const viewCommandParamHandler = (command_param) => {
		history.push(`/command-parameter/${command_param._id}`);
	};
	
	const columns = React.useMemo(
		() => [
			getSubsystemArrColumn(),			
			{
				Header: 'Name',
				accessor: 'cmd_param_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Description',
				accessor: 'description'
			},
			{
				Header: 'Type',
				accessor: 'type',
				Filter: SelectInputColumnFilter,
				filter: 'includes',
			},
			{
				Header: 'Byte Size',
				accessor: 'size_bytes',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Units',
				accessor: 'units_long'
			},
			{
				Header: 'Min Value',
				accessor: 'min_value'
			},
			{
				Header: 'Max Value',
				accessor: 'max_value'
			},
			getTagColumn(),
			{
				Header: ' ',
				accessor: (data) => {
					return (
						<div>
							<button className={style.tableTextButton} 
								onClick={() => viewCommandParamHandler(data)}
							>
								View
							</button>
						</div>
					);
				}
			},
		],
		[]
	);

	const allParamsQuery = useCommandParams();
	const boardFilter= JSON.parse(localStorage.getItem('board_filter_object'));
	const allPacketsQuery = useGetCommandPacketBoardFilter(boardFilter, filterToggle);

	const metadataQuery = useMetadata();
	

	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[allParamsQuery, metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateCommandParameterWizard 
								setCreateVisible={setCreateVisible} 
								allParams={allParamsQuery.data}
								metadata={metadataQuery.data[0]}
							/>
						</Modal> 
					)}				
				/>
			}			

			
			<LoadingWrapper 
				queries={[allParamsQuery, allPacketsQuery]}
				onLoad={() => (
					<ManagedMsdTable 
							tableName="CommandParametersTablePage" 
							columns={columns} 
							data={getSubsystemDecoratedChildRows(
								allParamsQuery.data, allPacketsQuery.data, EntityType.CmdPacket)}>
						
						<CreateButton
							width="14.5rem"
							title="Command Parameter"
							onClick={() => {
								setCreateVisible(true);
							}}
						/>		
					</ManagedMsdTable>
				)}
	        />

		</MsdMainPage>
	);
};

export default CommandParametersTablePage;
