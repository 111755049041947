import React from 'react';
import style from './TabMenuButton.module.css';

import {getButtonColor} from './TabColorHelper';

import CmdPacketUsageIcon from 'img/cmd-packet-usage.svg';
import CmdPacketUsageSelectedIcon from 'img/cmd-packet-usage-orange.svg';
import TlmPacketUsageIcon from 'img/tlm-packet-usage.svg';
import TlmPacketUsageSelectedIcon from 'img/tlm-packet-usage-orange.svg';

export const TabMenuButton = ({ tabDef, isSelected, buttonColor, mouseEventHandler, svgJsx, imgIcon, tabSelectedHandler, ...props }) => { 

	const onMouseOver = () =>  {
		mouseEventHandler("mouseOver");
	} 
	const onMouseOut = () =>  {
		mouseEventHandler("mouseOut");
	} 

	return (
		<>
			<div id={tabDef.id} {...props} className={style.tabMenuButton}>
				<button
					onClick={() => tabSelectedHandler(tabDef.id)}
					onMouseOver={onMouseOver}
					onMouseOut={onMouseOut}>
					{svgJsx && svgJsx}
					{imgIcon && <img src={imgIcon} alt="Img Icon" /> }
					<p style={{ color: buttonColor }}>{tabDef.buttonText}</p>
				</button>
			</div>
		</>
	);
};


 
export const CommandPacketButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<path
							id="top-arrow"
							d="M21.5,30V15.73h5.986a2.588,2.588,0,0,0,2.121-.9c1.113-1.511-.407-3.005-1.461-4.058C25.155,7.8,18.382,2.4,16.906.838a2.6,2.6,0,0,0-3.83,0C11.551,2.452,4.409,8.162,1.566,11.055.58,12.061-.641,13.426.386,14.827a2.6,2.6,0,0,0,2.124.9H8.478V30Z"
							transform="translate(0.003 0.003)"
							fill={buttonColor}
						/>
					</svg>
				}
			/>
		</>
	);
};

 
export const CommandParamButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<path
							id="Path_1347"
							data-name="Path 1347"
							d="M29.974,4.191h0a2.454,2.454,0,0,1-2.394,2.5H22.51a5.758,5.758,0,0,0-.027-4.993h5.1a2.454,2.454,0,0,1,2.394,2.5ZM21.8,25.782a4.048,4.048,0,1,1-4.045-4.218A4.134,4.134,0,0,1,21.8,25.782ZM30,25.754h0a2.454,2.454,0,0,1-2.394,2.5H22.51a5.757,5.757,0,0,0-.027-4.993H27.6A2.454,2.454,0,0,1,30,25.754Zm-16.991,2.5H2.4A2.454,2.454,0,0,1,0,25.754H0a2.454,2.454,0,0,1,2.394-2.5H13.032a5.758,5.758,0,0,0-.027,4.993Zm-6.09-13.3a4.134,4.134,0,0,0,4.045,4.218,4.134,4.134,0,0,0,4.045-4.218,4.134,4.134,0,0,0-4.045-4.218A4.134,4.134,0,0,0,6.916,14.95ZM0,14.923H0a2.454,2.454,0,0,0,2.394,2.5H6.221a5.672,5.672,0,0,1-.556-2.469,5.739,5.739,0,0,1,.583-2.524H2.394A2.454,2.454,0,0,0,0,14.923Zm15.725,2.5H27.606A2.454,2.454,0,0,0,30,14.923h0a2.454,2.454,0,0,0-2.394-2.5H15.7a5.743,5.743,0,0,1,.583,2.524,5.673,5.673,0,0,1-.556,2.469ZM21.8,4.218a4.048,4.048,0,1,1-8.09,0,4.048,4.048,0,1,1,8.09,0Zm-8.8,2.469H2.406a2.454,2.454,0,0,1-2.394-2.5h0a2.454,2.454,0,0,1,2.394-2.5H13.032a5.758,5.758,0,0,0-.027,4.993Z"
							transform="translate(0 0)"
							fill={buttonColor}
							fillRule="evenodd"
						/>
					</svg>
				}
			/>
		</>
	);
};

export const TagButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}


	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<path
							id="MdPricetag"
							d="M91.485,64H79.111L64.963,78.66a3.318,3.318,0,0,0,0,4.7l9.664,9.659a3.586,3.586,0,0,0,2.25.977,3.917,3.917,0,0,0,2.453-.977L93.985,79V66.5Zm-2.4,8.026a2.5,2.5,0,1,1,1.672-1.672A2.433,2.433,0,0,1,89.087,72.026Z"
							transform="translate(-63.985 -64)"
							fill={buttonColor}
						/>
					</svg>
				}
			/>
		</>
	);
};

export const OptionsButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}


	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<g id="ListNumbered" transform="translate(-4 -4)">
							<path
								id="Path_1361"
								data-name="Path 1361"
								d="M16,22H32.154v2.5H16Z"
								transform="translate(1.846 4.5)"
								fill={buttonColor}
							/>
							<path
								id="Path_1362"
								data-name="Path 1362"
								d="M16,8H32.154v2.5H16Z"
								transform="translate(1.846 1)"
								fill={buttonColor}
							/>
							<path
								id="Path_1363"
								data-name="Path 1363"
								d="M8.615,14V4H6.308V5.25H4v2.5H6.308V14H4v2.5h6.923V14Z"
								fill={buttonColor}
							/>
							<path
								id="Path_1364"
								data-name="Path 1364"
								d="M10.923,30.5H4v-5A2.41,2.41,0,0,1,6.308,23H8.615V20.5H4V18H8.615a2.41,2.41,0,0,1,2.308,2.5V23a2.41,2.41,0,0,1-2.308,2.5H6.308V28h4.615Z"
								transform="translate(0 3.5)"
								fill={buttonColor}
							/>
						</g>
					</svg>
				}
			/>
		</>
	);
};

export const TelemetryPacketButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="31.164" viewBox="0 0 30 31.164">
						<path
							id="top-arrow"
							d="M21.5,0V14.819h5.986a2.547,2.547,0,0,1,2.121.938c1.113,1.57-.407,3.122-1.461,4.215-2.991,3.089-9.764,8.694-11.241,10.317a2.536,2.536,0,0,1-3.83,0c-1.525-1.676-8.667-7.608-11.51-10.614C.58,18.63-.641,17.213.386,15.757a2.556,2.556,0,0,1,2.124-.938H8.478V0Z"
							transform="translate(0.003 0.002)"
							fill={buttonColor}
						/>
					</svg>
				}
			/>
		</>
	);
};


export const TelemetryPointButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg id="adjust-control" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<path
							id="Path_1348"
							data-name="Path 1348"
							d="M4.191,0h0a2.433,2.433,0,0,1,2.5,2.349V7.621a5.856,5.856,0,0,0-4.993.026v-5.3A2.433,2.433,0,0,1,4.191,0ZM25.781,8.315A4.1,4.1,0,0,1,30,12.284a4.226,4.226,0,0,1-8.437,0,4.1,4.1,0,0,1,4.218-3.969ZM25.754,0h0a2.433,2.433,0,0,1,2.5,2.349V7.621a5.857,5.857,0,0,0-4.993.026v-5.3A2.434,2.434,0,0,1,25.754,0Zm2.5,16.948v10.7A2.433,2.433,0,0,1,25.754,30h0a2.433,2.433,0,0,1-2.5-2.349V16.922a5.857,5.857,0,0,0,4.993.026Zm-13.3,5.976a4.1,4.1,0,0,0,4.218-3.969,4.1,4.1,0,0,0-4.218-3.969,4.1,4.1,0,0,0-4.218,3.969,4.1,4.1,0,0,0,4.218,3.969ZM14.922,30h0a2.434,2.434,0,0,0,2.5-2.349V23.606a5.76,5.76,0,0,1-2.469.546,5.831,5.831,0,0,1-2.524-.572v4.071A2.434,2.434,0,0,0,14.922,30Zm2.5-15.72V2.349A2.434,2.434,0,0,0,14.922,0h0a2.433,2.433,0,0,0-2.5,2.349V14.306a5.831,5.831,0,0,1,2.524-.572,5.763,5.763,0,0,1,2.469.546ZM4.218,8.315a4.1,4.1,0,0,1,4.218,3.969,4.1,4.1,0,0,1-4.218,3.969A4.1,4.1,0,0,1,0,12.284,4.1,4.1,0,0,1,4.218,8.315Zm2.469,8.632v10.7A2.433,2.433,0,0,1,4.191,30h0a2.433,2.433,0,0,1-2.5-2.349V16.922a5.857,5.857,0,0,0,4.993.026Z"
							fill={buttonColor}
							fillRule="evenodd"
						/>
					</svg>
				}
			/>
		</>
	);
};

export const BitwisePointButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	const style = `.cls-1 {font-size: 62px; fill: ${buttonColor}; font-family: Silom;`;

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 128.06 65.81">
					<defs>
						<style>
							{style}
						</style>
					</defs>
					<text class="cls-1" transform="translate(0 42)">1010</text>
					</svg>
		
				}
			/>
		</>
	);
};



export const UploadButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
						<path 
							d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20s9 20 20 20s20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20s9 20 20 20s20-9 20-20z" 
							fill={buttonColor}>		
						</path>
					</svg>					
				}
			/>
		</>
	);
};

export const TlmPacketUsageButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				imgIcon={isSelected ? TlmPacketUsageSelectedIcon : TlmPacketUsageIcon}
			/>
		</>
	);
};



export const CmdPacketUsageButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props}
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				imgIcon={isSelected ? CmdPacketUsageSelectedIcon : CmdPacketUsageIcon}
			/>
		</>
	);
};

export const PushLogButton = ({isSelected, editModeActive, ...props }) => {

	let buttonColor = getButtonColor(isSelected, editModeActive, null); 
	const mouseEventHandler = (mouseEventType) => {
		buttonColor = getButtonColor(isSelected, editModeActive, mouseEventType);
	}

	return (
		<>
			<TabMenuButton
				disabled={editModeActive}
				isSelected={isSelected}
				{...props} 
				mouseEventHandler={mouseEventHandler}
				buttonColor={buttonColor}
				svgJsx={
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 14 14">
						<g fill="none">
							<path d="M5.085 2A1.5 1.5 0 0 1 6.5 1h3a1.5 1.5 0 0 1 1.415 1h.585A1.5 1.5 0 0 1 13 3.5v10a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 3 13.5v-10A1.5 1.5 0 0 1 4.5 2h.585zM6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM7 7.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5zm0 3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5zM5.5 8a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1zm0 3a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1z" 
								fill={buttonColor}>
							</path>
						</g>
					</svg>				
				}
			/>
		</>
	);
};