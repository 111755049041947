import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'react-notifications/lib/notifications.css';

import LoginPage from 'components/login/LoginPage';

import CommandPacketsTablePage from 'components/cmd-packet/table-page/CommandPacketsTablePage';
import CommandPacketPage from 'components/cmd-packet/packet-page/CommandPacketPage';

import CommandParametersTablePage from 'components/cmd-param/table-page/CommandParametersTablePage';
import CommandParameterPage from 'components/cmd-param/param-page/CommandParameterPage';

import TelemetryPacketsTablePage from 'components/tlm-packet/table-page/TelemetryPacketsTablePage';
import TelemetryPacketPage from 'components/tlm-packet/packet-page/TelemetryPacketPage';

import TelemetryPointsTablePage from 'components/tlm-point/table-page/TelemetryPointsTablePage';
import TelemetryPointPage from 'components/tlm-point/point-page/TelemetryPointPage';

import AllBitwiseTablePage from 'components/tlm-point/all-bitwise-table-page/AllBitwiseTablePage';

import TagsTablePage from 'components/tags/table-page/TagsTablePage';
import UnitsTablePage from 'components/units/UnitsTablePage';

import ReviewPage from 'components/review/ReviewPage';

import NotFound from 'pages/NotFound';
import AuthContextProvider from 'components/ui-core/auth/AuthContext';
import PrivateRoute from 'components/ui-core/routes/PrivateRoute'

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import BoardVersionsPage from 'components/board-versions/BoardVersionsPage';
import AlertsTablePage from 'components/alerts/table-page/AlertsTablePage';
import AlertPage from 'components/alerts/alert-page/AlertPage';
import VersionCompareFormPage from 'components/version-compare/VersionCompareFormPage';
import BranchContextProvider from 'components/ui-core/layout/Header/BranchContext';




const App = () => {
	
	return (
		<div className="App">
			<ToastContainer
				position="bottom-right"
				autoClose={7000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>                   
      		<Router>
        		<AuthContextProvider
					routes = {() => {	
						return (
							<> 
							<BranchContextProvider>
								<Switch id="Switch">


						
									<PrivateRoute component={CommandPacketsTablePage} 
										path="/" exact requiredScopes={["MsdUser"]} />          		
									<PrivateRoute component={CommandPacketPage} 
										path="/command-packet/:cmdPacketId" requiredScopes={["MsdUser"]} exact/>

									<PrivateRoute component={CommandParametersTablePage} 
										path="/command-parameters"  requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={CommandParameterPage} 
										path="/command-parameter/:cmdParamId"  requiredScopes={["MsdUser"]} exact/>

									<PrivateRoute component={TelemetryPacketsTablePage} 
										path="/telemetry-packets"  requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={TelemetryPacketPage} 
										path="/telemetry-packet/:tlmPacketId"  requiredScopes={["MsdUser"]} exact/>

									<PrivateRoute component={TelemetryPointsTablePage} 
										path="/telemetry-points"  requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={TelemetryPointPage} 
										path="/telemetry-point/:tlmPointId"  requiredScopes={["MsdUser"]} exact/>

									<PrivateRoute component={AllBitwiseTablePage} 
										path="/bitwise-points"  requiredScopes={["MsdUser"]} exact/>
									
									<PrivateRoute component={AlertsTablePage} 
										path="/msd-alerts"  requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={AlertPage} 
										path="/alert/:alertId"  requiredScopes={["MsdUser"]} exact/>

									<PrivateRoute component={TagsTablePage} 
										path="/tags" requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={UnitsTablePage} 
										path="/units" requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={BoardVersionsPage} 
										path="/board-filter" requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={ReviewPage} 
										path="/review" requiredScopes={["MsdUser"]} exact/>
									<PrivateRoute component={VersionCompareFormPage} 
										path="/version-compare" requiredScopes={["MsdUser"]} exact/>
									
									<Route component={LoginPage} path="/login"/>

									<PrivateRoute component={NotFound} path="*" exact/>

								</Switch>
								</BranchContextProvider>
							</>
						);
					}} 
				/>
      		</Router>
    	</div>
	);
};

export default App;
