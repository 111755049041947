// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TableBarControls_tableBarControlsContainer__WwnzW {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.TableBarControls_tableBarControlsContainer__WwnzW {
	display: flex;
	flex-flow: row;
}


.TableBarControls_tableBarControlsContainer__WwnzW input,
.TableBarControls_tableBarControlsContainer__WwnzW select,
.TableBarControls_tableBarControlsContainer__WwnzW textarea {
	margin: 0.3rem 0 0.3rem 0;
}

.TableBarControls_tableBarControlsContainer__WwnzW input {
	margin-right: 1rem;
	border-radius: 0.4rem;
	width: 3rem;
	height: 2rem;
	border: 0.063rem solid #c9c8c8;
	color: #656565;
	font-size: 0.875rem;
	padding-left: 0.5rem;
}


.TableBarControls_tableBarControlsContainer__WwnzW label {
	margin-right: 0.5rem;
}

.TableBarControls_tableBarControlsContainer__WwnzW input:focus {
	outline: none !important;
	border: 0.1rem solid var(--ast-primary-orange-color);
}

.TableBarControls_tableBarControlsContainer__WwnzW select {
	margin-right: 9rem;
	border-radius: 0rem;
	width: 11rem;
	height: 2rem;
	border: 0.1rem solid #656565;
	color: #656565;
	font-size: 0.875rem;
}

.TableBarControls_tableBarControl__riYAz {
	margin-left: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/TableBarControls/TableBarControls.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,qBAAqB;CACrB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,cAAc;AACf;;;AAGA;;;CAGC,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,qBAAqB;CACrB,WAAW;CACX,YAAY;CACZ,8BAA8B;CAC9B,cAAc;CACd,mBAAmB;CACnB,oBAAoB;AACrB;;;AAGA;CACC,oBAAoB;AACrB;;AAEA;CACC,wBAAwB;CACxB,oDAAoD;AACrD;;AAEA;CACC,kBAAkB;CAClB,mBAAmB;CACnB,YAAY;CACZ,YAAY;CACZ,4BAA4B;CAC5B,cAAc;CACd,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":["\r\n.tableBarControlsContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\talign-items: center;\r\n}\r\n\r\n.tableBarControlsContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row;\r\n}\r\n\r\n\r\n.tableBarControlsContainer input,\r\n.tableBarControlsContainer select,\r\n.tableBarControlsContainer textarea {\r\n\tmargin: 0.3rem 0 0.3rem 0;\r\n}\r\n\r\n.tableBarControlsContainer input {\r\n\tmargin-right: 1rem;\r\n\tborder-radius: 0.4rem;\r\n\twidth: 3rem;\r\n\theight: 2rem;\r\n\tborder: 0.063rem solid #c9c8c8;\r\n\tcolor: #656565;\r\n\tfont-size: 0.875rem;\r\n\tpadding-left: 0.5rem;\r\n}\r\n\r\n\r\n.tableBarControlsContainer label {\r\n\tmargin-right: 0.5rem;\r\n}\r\n\r\n.tableBarControlsContainer input:focus {\r\n\toutline: none !important;\r\n\tborder: 0.1rem solid var(--ast-primary-orange-color);\r\n}\r\n\r\n.tableBarControlsContainer select {\r\n\tmargin-right: 9rem;\r\n\tborder-radius: 0rem;\r\n\twidth: 11rem;\r\n\theight: 2rem;\r\n\tborder: 0.1rem solid #656565;\r\n\tcolor: #656565;\r\n\tfont-size: 0.875rem;\r\n}\r\n\r\n.tableBarControl {\r\n\tmargin-left: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBarControlsContainer": `TableBarControls_tableBarControlsContainer__WwnzW`,
	"tableBarControl": `TableBarControls_tableBarControl__riYAz`
};
export default ___CSS_LOADER_EXPORT___;
