import React from 'react'
import {useDrop } from 'react-dnd'

const EmptyTableDndRowPlaceholder = ({ getCustomRowProps={}, useMsdTableObj}) => {
  
  const {
    dndSpec: {
      dropSpec: tableDropSpec, 
      tableId, 
    }, 
  } = useMsdTableObj;

    const dndRef = React.useRef(null);

    const dropSpec = {
      ...tableDropSpec,

      drop: (item, monitor) => {
        console.log("Dropping");        
        tableDropSpec.performDrop({
          dragDetails: item, 
          dropAtIndex: -1, 
          mousePositionAtDropIndex: "top-half",
          dropTableId: tableId
        });
      },
      
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }

    const [{isOver}, drop] = useDrop(dropSpec)
  
    const style = {}    
    if (isOver) {
      style["borderTop"] = "6px solid var(--ast-secondary-blue-color)" 
    }

    drop(dndRef)
  
    const getDndCustomRowProps = (row) => (
      {
        ...getCustomRowProps(row),
        ref: dndRef,
      }  
    )
    
    return (
      <tr {...getDndCustomRowProps()} style={style}>
        <td></td>
      </tr>

    )
  }
  
export default EmptyTableDndRowPlaceholder;