import React from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getBoardSchema, getUnitSchema} from 'model/form-schema/ObjectSchemas'
import BoardFormContent from './BoardFormContent';

export const CreateBoardWizard = ({ setCreateVisible,  metadata, userName , dataExtraction}) => {
	const formatted_semver_data = dataExtraction();
	const schema = getBoardSchema(formatted_semver_data? formatted_semver_data : [], null);
	const {useFormObj, controlProps} = useWizardForm({}, schema);

	const createSubmitPromise = async (formData) => {
		
		let newBoard = {
			...formData, 
			modified_by: userName,
			modified_date: new Date().toISOString(),
		}
		const semver_data = JSON.parse(localStorage.getItem('board_filter_object'));
		semver_data.push({board: newBoard["name"], semver: newBoard["version"]});
		localStorage.setItem("board_filter_object", JSON.stringify(semver_data));
		toast.success(`Board '${newBoard.name}' created`);
		return null;	
	};


	return (
		<Wizard width='40rem'>
			<WizardForm title="Add New Board" 
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
			>
				<BoardFormContent controlProps={controlProps} metadata={metadata} />
			</WizardForm>
		</Wizard>		
	);
};


export default CreateBoardWizard;
