// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Wizard_wizard__C26oc {
	/* width: 45.375rem; */
	height: auto;
	background: #ffffff;
	border-radius: 0.5rem;
	padding: 1rem;
}

.Wizard_wizard__C26oc label {
	font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/modal/Wizard.module.css"],"names":[],"mappings":";AACA;CACC,sBAAsB;CACtB,YAAY;CACZ,mBAAmB;CACnB,qBAAqB;CACrB,aAAa;AACd;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":["\r\n.wizard {\r\n\t/* width: 45.375rem; */\r\n\theight: auto;\r\n\tbackground: #ffffff;\r\n\tborder-radius: 0.5rem;\r\n\tpadding: 1rem;\r\n}\r\n\r\n.wizard label {\r\n\tfont-weight: 500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wizard": `Wizard_wizard__C26oc`
};
export default ___CSS_LOADER_EXPORT___;
