import React, {useState} from 'react';
import {pick} from 'lodash'; 

import {FormRow, FormCheckmarkRow, Input, TextArea, 
    Select, ToggleSwitchWrapper, Checkbox} from 'components/ui-core/form/FormControls';
import style from './TelemetryPointFormContent.module.css'

import { ReadOnlyDerivedSection } from 'components/tlm-point/point-page/derived/ReadOnlyDerivedSection';
import { EditableDerivedSection } from 'components/tlm-point/point-page/derived/EditableDerivedSection';

import {getUnitEntryOptions} from 'components/ui-core/utils/MetadataHelper'
import {getTlmPointApplicableTypes, doesTypeSupportTlmLimits, 
    getDerivedTypeOptions, getAlgorithimTypeOptions} from 'components/ui-core/utils/MetadataHelper'

import { getBlockArrayRowIfRequired } from 'components/ui-core/utils/BlockArrayHelper';

export const TelemetryPointFormContent = ({ controlProps, metadata, 
        useFormObj, isWizard, allPoints, isByteReadOnly}) => {
    const {watch, getValues, setValue} = useFormObj;
    const {editModeActive} = controlProps

    const unitOptions = getUnitEntryOptions(metadata); 
    const isTypeDerived = () => getValues("type") === "derived";

    // Hold the limit values in a stash when the user toggles them off so that they take no part in 
    // validation (in case the non-visble/applicable values are invalid) and so that on reticking they
    // are restored
    const limitValueNames = ["red_low_limit", "red_high_limit", "yellow_low_limit", "yellow_high_limit"];
    const [stashedLimitValues, setStashedLimitValues]  = useState(pick(getValues(), limitValueNames));
    
    const handleLimitTogglerChange = (newLimits) => {
        if (newLimits) {
            // Apply the stash limit values
            Object.entries(stashedLimitValues).forEach(
                    ([limitKey, limitValue]) => setValue(limitKey, limitValue))
        } else {
            // Stash values and reset
            setStashedLimitValues(pick(getValues(), limitValueNames));
            limitValueNames.forEach(limitKey => setValue(limitKey, null));
        }
    }

    // re-render fields that other fields validation status depends on changes 
    watch(["type", "limit", "array_element_type", "derived_type", "size_bytes", ...limitValueNames]);


    return (
        <>
            <FormRow rowNum="1">
                <Input style={{width: "30rem"}} title="Telemetry Point Name" attr="tlm_point_name" {...controlProps}/>
                <Select title="Type" attr="type" 
                    {...controlProps} valueOptions={getTlmPointApplicableTypes()} 
                    includeBlank={isWizard} revalidateOnChange={true}/>
            </FormRow> 

            <FormRow rowNum="2">
                <TextArea title="Description" attr="description" {...controlProps}/>
            </FormRow>

            {/* Byte Size and units (Note: For derived, they are merged into a row with the derived type later) */}
            {getValues("type") !== "derived" && 

                <FormRow rowNum="3">
                <div>
                    <Input 
                    title="Byte Size"
                    attr="size_bytes" 
                    {...controlProps} />
                    {isByteReadOnly && controlProps.editModeActive && <span style={{border: "6px solid var(--ast-secondary-red-color)", backgroundColor: "var(--ast-secondary-red-color)", color: "white"}}>Note: point exist in parent packet</span>}
                    </div>
                    <Checkbox title="Unique Across TLM Packets" attr="unique_across_tlm_packets" {...controlProps}/>
                    <Checkbox title="Unique Across Boards" attr="unique_across_boards" {...controlProps}/>
                    {getValues("type") !== "bitwise" &&
                        <Select title="Units" attr="units_short" {...controlProps} 
                            entryOptions={unitOptions} includeBlank={true} />
                    }
                </FormRow>          
            }
            
            {/* Block Array */}
            {getBlockArrayRowIfRequired("6", controlProps, useFormObj, isWizard)}            

            {/* Derived Telemetry */}
            {getValues("type") === "derived" && 
                <>
                    <FormRow rowNum="7a">
                        <Select title="Derived Type" attr="derived_type" 
                            {...controlProps} valueOptions={getDerivedTypeOptions()} 
                                includeBlank={true} revalidateOnChange={true}/>

                        <Input title="Byte Size" attr="size_bytes" {...controlProps}/>
                        <Select title="Units" attr="units_short" {...controlProps} 
                            entryOptions={unitOptions} includeBlank={true} />

                        <Input title="Calculation Frequency" attr="derived_calc_freq" 
                            {...controlProps} isRequired={isTypeDerived}/>
                    </FormRow>
                    <FormRow rowNum="7b">
                        <Select title="Algorithm" attr="derived_algorithm" 
                                {...controlProps} valueOptions={getAlgorithimTypeOptions()} 
                                    includeBlank={true} revalidateOnChange={false} isRequired={isTypeDerived}/>
                        <Checkbox title="Unique Across TLM Packets" attr="unique_across_tlm_packets" {...controlProps}/>
                        <Checkbox title="Unique Across Boards" attr="unique_across_boards" {...controlProps}/>
                    </FormRow>	


                    {!editModeActive && 
                        <ReadOnlyDerivedSection 
                            controlProps={controlProps}
                            useFormObj={useFormObj}
                            allPoints={allPoints}/>
                    }
                    {editModeActive && 
                        <FormRow rowNum="7c">
                            <EditableDerivedSection controlProps={controlProps}/>
                        </FormRow>
                    }
                </>
            }

        </>
    );
};



export default TelemetryPointFormContent;
