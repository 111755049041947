
import {FormRow, Input, Select} from 'components/ui-core/form/FormControls';
import { getSnapShotTestStatusOptions } from 'components/ui-core/utils/MetadataHelper';

export const DiscreteFormContent = ({controlProps}) => {

	return (
		<>
			<FormRow rowNum="1">
				<Input  title="Name" attr="name" {...controlProps}/>
			</FormRow>
			<FormRow rowNum="2">
				<Input  title="value" attr="value" {...controlProps}/>
			</FormRow>
		</>

	);
};

export default DiscreteFormContent;
