import React from 'react';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';

import LimitOptionFormContent from './LimitOptionFormContent'
import { getEditLimitOptionsSchema } from 'model/form-schema/ObjectSchemas';


export const EditOptionValueModal = ({ createEditOptionPromise, 
		editOptionValue, setEditOptionValue, entityType, optionsData, optionsType}) => {

	console.log("inside Edit ", editOptionValue);
	const schema = getEditLimitOptionsSchema();
	const {useFormObj, controlProps} = useWizardForm(editOptionValue, schema, 'onChange');
	const title = "Edit Threshold For " + editOptionValue['sc_mode']		

	const closeWizard = (wizardVisible) => {
		console.log("Inside Close");
		if (!wizardVisible) {
			setEditOptionValue(null);
		}
	}

	return (
		<Wizard width='20rem'>
			<WizardForm title={title} 
				setWizardVisible={closeWizard} 
				useFormObj={useFormObj} 
				createSubmitPromise={createEditOptionPromise}
				submitButtonTitle="Edit"
			>
				<LimitOptionFormContent controlProps={controlProps} entityType={entityType} optionsType={optionsType} formType={'Edit'} optionsData={optionsData} entryType={"Edit"} />
			</WizardForm>
		</Wizard>		
	);
};

export default EditOptionValueModal;
