import CmdPacketAssocTabViewMode from "./CmdPacketAssocTabViewMode";
import CmdPacketAssocTabEditMode from "./CmdPacketAssocTabEditMode";

export const CmdPacketAssocTab = ({editModeActive, editModeTransitionHandler, updateEntityPromise,
		cmdPacket, allParams }) => {
	return (
		<>
			{editModeActive
				? 	
					<CmdPacketAssocTabEditMode 
						editModeTransitionHandler={editModeTransitionHandler}
						updateEntityPromise={updateEntityPromise}
						cmdPacket={cmdPacket}
						allParams={allParams}/>
				: 
					<CmdPacketAssocTabViewMode cmdPacket={cmdPacket} allParams={allParams}/>
			}
		</>
	);
};

export default CmdPacketAssocTab;

