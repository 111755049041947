import {React, useState, useEffect} from 'react';

import LoginForm from './LoginForm/LoginForm';
import { useASTData } from 'context/ASTContext';







const LoginPage = () => {
	const { setSideBarOption } = useASTData();

	useEffect(() => {
		setSideBarOption(null);
	}, [setSideBarOption]);





	return (
		<LoginForm/>		
	);
};

export default LoginPage;
 