import style from './TabMenu.module.css';

import {CommandPacketButton, CommandParamButton, TagButton, OptionsButton, TelemetryPacketButton, 
    TelemetryPointButton, BitwisePointButton, CmdPacketUsageButton, TlmPacketUsageButton, 
    UploadButton, PushLogButton} from './TabMenuButton';

import LoadingWrapper from 'components/ui-core//page/LoadingWrapper';
import TagPanel from 'components/tags/TagPanel';

export const TabMenu = ({tabDefs, selectedTabId, tabSelectedHandler, editModeActive, underlyingEntityQuery, tabControls}) => {

    const isButtonSelected = (tabDef) => {
        return tabDef.id === selectedTabId;
    }

    const tabSelectHandlerWrapper = (...arg) => {
        if (!editModeActive) {
            tabSelectedHandler(...arg)
        }
    }

    const getJsxForButtonType = (tabDef) => {
        if (tabDef.buttonType === "CommandPacket") {
            return getCommandPacketIconTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "CommandParam") {            
			return getCommandParamIconTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "Tag") {
            return getTagIconTabButtonJsx(tabDef);
		} else if (tabDef.buttonType === "Options") {            
            return getOptionsIconTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "TelemetryPacket") {
            return getTelemetryPacketIconTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "TelemetryPoint") {            
			return getTelemetryPointIconTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "BitwisePoint") {            
			return getBitwisePointIconTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "CommandPacketUsage") {
			return getCmdPacketUsageTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "TelemetryPacketUsage") {
			return getTlmPacketUsageTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "Upload") {
			return getUploadTabButtonJsx(tabDef);
        } else if (tabDef.buttonType === "PushLog") {
            return getPushLogTabButtonJsx(tabDef);
        }

        
        //default
        return (
			<h1>Undefined Icon</h1>
		);
    }

    const getCommandPacketIconTabButtonJsx = (tabDef) => {
		return (
			<CommandPacketButton
                key="CommandPacketButton"
				tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    }

    const getCommandParamIconTabButtonJsx = (tabDef) => {
		return (
			<CommandParamButton
                key="CommandParamButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    }

    const getTagIconTabButtonJsx = (tabDef) => {
		return (
			<TagButton 
                key="TagButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    }

    const getOptionsIconTabButtonJsx = (tabDef) => {
		return (
			<OptionsButton 
                key="OptionsButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    }

    const getTelemetryPacketIconTabButtonJsx = (tabDef) => {
		return (
			<TelemetryPacketButton
                key="TelemetryPacketButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    }

    const getTelemetryPointIconTabButtonJsx = (tabDef) => {
		return (
			<TelemetryPointButton
                key="TelemetryPointButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    }

    const getBitwisePointIconTabButtonJsx = (tabDef) => {
		return (
			<BitwisePointButton
                key="BitwisePointButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    } 

    const getCmdPacketUsageTabButtonJsx = (tabDef) => {
		return (
			<CmdPacketUsageButton
                key="CmdPacketUsageButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    } 


    const getTlmPacketUsageTabButtonJsx = (tabDef) => {
		return (
			<TlmPacketUsageButton
                key="TlmPacketUsageButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    } 

    const getUploadTabButtonJsx = (tabDef) => {
		return (
			<UploadButton
                key="UploadButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    } 

    const getPushLogTabButtonJsx = (tabDef) => {
		return (
			<PushLogButton
                key="PushLogButton"
                tabDef={tabDef} 
				isSelected={isButtonSelected(tabDef)}
				tabSelectedHandler={tabSelectHandlerWrapper}
                editModeActive={editModeActive}/>
        );
    } 

    
    const includeTab = (tabDef) => {
        if (tabDef.enabledWhen) {
            return tabDef.enabledWhen();
        } 

        return true;
    }

	return (
		<div className={style.menuContainer}>
			<div className={style.tabContainer}>	
                {
                    tabDefs.filter(tabDef => includeTab(tabDef)).map((tabDef) => {
                        return getJsxForButtonType(tabDef);
                    })
                }
			</div>

            {tabControls && tabControls()}

            {underlyingEntityQuery && selectedTabId !== "tags_tab" && 
                <div className={style.tabControlsContainer}>
                    <LoadingWrapper 
                        queries={underlyingEntityQuery? [underlyingEntityQuery] : []}
                        displayIndicator={false}
                        onLoad={() => (
                            <TagPanel tagArr={underlyingEntityQuery ? underlyingEntityQuery.data["environment_tags"] : []} />
                        )}
                    />        
                </div>
            }

        </div>
	);
};

export default TabMenu;
