import React from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import TelemetryPointFormTab from './TelemetryPointFormTab';
import OptionsTab from 'components/options/OptionsTab';
import BitwiseTableTab from 'components/tlm-point/point-page/bitwise/tab/BitwiseTableTab';
import TagsTab from 'components/tags/editors/TagsTab'
import EntityType from 'model/EntityType';

import { useASTData } from 'context/ASTContext';

import {useEditorPage} from 'components/ui-core/page/useEditorPage'
import EditorPage from 'components/ui-core/layout/EditorPage/EditorPage';
import ConfirmationRequest from 'model/ConfirmationRequest';
import SideBarOption from 'model/SideBarOption';
import TelemetryPointUsageTab from './TelemetryPointUsageTab';

import {useTlmPointWithBitwise, useTelemetryPointsOptimized, useMetadata, useTags,
	useSaveTelemetryPoint,
	usePhysicallyDeleteTelemetryPoint, useLogicallyDeleteTelemetryPoint,
	useGetTelemetryPacketBoardFilter}  from 'data/queryHooks';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import SummaryBar from 'components/ui-core/common/SummaryBar';
import SummaryContextProvider from 'components/ui-core/common/SummaryContext';
import { shouldPhysicallyDelete } from 'components/tags/TagHelper';
import EditModeTransition from 'model/EditModeTransition';
import { set } from 'react-hook-form';

const TelemetryPointPage = () => {
	// Ensure each Page has it's instance (fresh component tree) otherwise history.push()
	// to the same route will reuse components for other object navigate away from
	let {tlmPointId} = useParams();
	return <TelemetryPointPageBody key={tlmPointId}/>
}

const TelemetryPointPageBody = () => {

	const {setSideBarOption, setHeaderTitle, setConfirmationRequest} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.TlmPoint);
		setHeaderTitle(null);
	}, [setSideBarOption,setHeaderTitle]);

	let {tlmPointId} = useParams();

	// Data queries
	const [pointByIdQuery, bitwiseForTlmPointQuery] = useTlmPointWithBitwise(tlmPointId);
	const allPointsQuery = useTelemetryPointsOptimized();
	const boardFilter= JSON.parse(localStorage.getItem('board_filter_object'));
	const allPacketsQuery = useGetTelemetryPacketBoardFilter(boardFilter);
	const metadataQuery = useMetadata();	
	const tagsQuery = useTags();

	React.useEffect(() => {
		if (pointByIdQuery.data) {
			setHeaderTitle(pointByIdQuery.data?.tlm_point_name);	
		}
	}, [pointByIdQuery.data, bitwiseForTlmPointQuery.data, setHeaderTitle]);

	const  {mutateAsync: physicallyDelete} = usePhysicallyDeleteTelemetryPoint();
	const  {mutateAsync: logicallyDelete} = useLogicallyDeleteTelemetryPoint();

	const createDeleteRequest = () => {
		const physically_delete = shouldPhysicallyDelete(pointByIdQuery.data)

		return new ConfirmationRequest(
			tlmPointId,
			physically_delete ? physicallyDelete : logicallyDelete,
			`Delete '${pointByIdQuery.data?.tlm_point_name}'`, 
			"Delete",
			"Are you sure you want to delete this telemetry point?",
			physically_delete ? '/telemetry-points' : null,
			`Telemetry Point '${pointByIdQuery.data?.tlm_point_name}' ${physically_delete ? "deleted" : " flagged for deletion"}`
		)
	}

	const enumTypes = ["unsigned", "signed"];
	const tabDefs = [
		{
			id: "form_tab",
			buttonText: "Telemetry Point",
			buttonType: "TelemetryPoint",
			editMode: "formEditMode"
		},
		{
			id: "options",
			buttonText: "Enumerations Menu",
			buttonType: "Options",
			editMode: "none",
			enabledWhen: () => {
				return enumTypes.includes(pointByIdQuery.data?.type); 
			}
		},
		{
			id: "bitwise_tlm_points",
			buttonText: "Bitwise Points",
			buttonType: "BitwisePoint",
			editMode: "buildMode",
			enabledWhen: () => {
				return pointByIdQuery.data?.type === "bitwise";
			}
		},
		{
			id: "tags_tab",
			buttonText: "Tags",
			buttonType: "Tag",
			editMode: "formEditMode"
		},
		{
			id: "usage",
			buttonText: "Usage",
			buttonType: "TelemetryPacketUsage",
			editMode: "none"
		}
	] 
	

	const {
        currentEditOption,
        selectedTabId,
        editModeActive,
        tabSelectedHandler,
        editModeTransitionHandler,
		formKey
    } = useEditorPage(tabDefs);

	const toolBarDef = 	{
		navButtons: [
			{
				title: "< View All Telemetry Points",
				width: "18.5rem",
				route: "/telemetry-points"
			}
		],
		manipulationButtons: {
			mutationButtons: {
				includeEdit: true,
				includeBuildMode: true,
				editModeTransitionHandler: editModeTransitionHandler
			},
			deleteButton: {
				createDeleteRequest: createDeleteRequest
			}
		}
	}


	const  {mutateAsync: saveTelemetryPoint} = useSaveTelemetryPoint();
	const updateEntityPromise = async (updatedTlmPoint) => {
		if(pointByIdQuery.data?.size_bytes !== updatedTlmPoint["size_bytes"])
		{
			const message = 
				<div>
					Caution, you are changing the size bytes of a tlm point.
					<br/>
					<br/>
					This could have cascading implications for all telemetry packets that use this telemetry point. 
					<br/>
					<b>Are you sure you want to edit it?</b>
				</div>
            const confirmationRequest =  new ConfirmationRequest(
                null,
                async () => await updateTelemetryPoint(updatedTlmPoint),
                `Warning '${updatedTlmPoint["tlm_point_name"]}'`, 
                "Confirm Changes",
                message,
                null,
                `Telemetry Point '${updatedTlmPoint["tlm_point_name"]}' Updated Successfully`,
				'Cancel',
				async () => editModeTransitionHandler(EditModeTransition.Cancel)
 
            )
        
            setConfirmationRequest(confirmationRequest);
		}
		else{
			updateTelemetryPoint(updatedTlmPoint);
		}				
	}

	const updateTelemetryPoint = async (updatedTlmPoint) => {
		return await saveTelemetryPoint(updatedTlmPoint)
				.then(refreshedPoint => {
					toast.success(`'${updatedTlmPoint.tlm_point_name}' updated`);
					return refreshedPoint;
				})
				.catch(error => {
					toast.error("Telemetry Packet update failed");
					return Promise.reject(error)
				});
	}

	const getAssocSummaryJsx = () => {
		return selectedTabId === "bitwise_tlm_points" 
			? <SummaryBar/>
			: <></>		
	} 

	return (
		<SummaryContextProvider>
			<EditorPage 
				toolBarDef={toolBarDef} currentEditOption={currentEditOption} 
				editModeActive={editModeActive} tabDefs={tabDefs} 
				selectedTabId={selectedTabId} tabSelectedHandler={tabSelectedHandler}
				underlyingEntityQuery={pointByIdQuery}
				tabControls={getAssocSummaryJsx}
			>		

				{selectedTabId === "form_tab" && 
					<LoadingWrapper 
						queries={[pointByIdQuery, allPointsQuery, metadataQuery, allPacketsQuery]}
						onLoad={() => (
							<TelemetryPointFormTab 
								key={formKey}
								updateEntityPromise={updateEntityPromise}
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								tlmPoint={pointByIdQuery.data}
								allPoints={allPointsQuery.data}
								metadata={metadataQuery.data[0]}
								parentPacketExists={allPacketsQuery.data
									.filter(packet => packet.fields.includes(pointByIdQuery.data["tlm_point_name"])).length !== 0}/>
						)}
					/>
				}
				{selectedTabId === "options" && 
					<LoadingWrapper 
						queries={[pointByIdQuery]}
						onLoad={() => (
							<OptionsTab
								key={formKey}
								updateEntityPromise={updateEntityPromise}
								underlyingEntity={pointByIdQuery.data}
								entityType={EntityType.TlmPoint}/>
						)}
					/>
				}	
				{selectedTabId === "tags_tab" && 
					<LoadingWrapper 
						queries={[pointByIdQuery, tagsQuery]}
						onLoad={() => (
							<TagsTab 
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								underlyingEntity={pointByIdQuery.data}
								allTags={tagsQuery.data}
								entityType={EntityType.TlmPoint}/>
						)}
					/>
				}						
				{selectedTabId === "bitwise_tlm_points" && 
					<LoadingWrapper 
						queries={[pointByIdQuery, bitwiseForTlmPointQuery]}
						onLoad={() => (
							<BitwiseTableTab
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								tlmPoint={pointByIdQuery.data}
								allTlmBitwise={bitwiseForTlmPointQuery.data}
							/>
						)}
					/>
				}				

				{selectedTabId === "usage" && 
					<LoadingWrapper 
						queries={[pointByIdQuery, allPacketsQuery, allPointsQuery]}
						onLoad={() => (
							<TelemetryPointUsageTab
								key={formKey}
								tlmPoint={pointByIdQuery.data}
								parentPackets={allPacketsQuery.data
									.filter(packet => packet.fields.includes(pointByIdQuery.data["tlm_point_name"]))}
								allPoints={allPointsQuery.data}/>
						)}
					/>
				}

			</EditorPage>		
		</SummaryContextProvider>
	);
};

export default TelemetryPointPage;
