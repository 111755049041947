import { getStagedBaseColumns as getCmdStagedBaseColumns } from 'components/cmd-packet/table-page/CommandPacketTableHelper';
import { getStagedBaseColumns as getTlmStagedBaseColumns } from 'components/tlm-packet/table-page/TelemetryPacketTableHelper'; 

import EntityType from 'model/EntityType';
import useReviewPacketsTable from 'components/review/useReviewPacketsTable';
import ManagedTag from 'model/ManagedTag';
import style from 'App.module.css';

export const ReviewStagedTab = ({ stagedCmdPackets, stagedTlmPackets, 
	cmdPacketViewHandler, tlmPacketViewHandler, submitUpdateTagRequest }) => {

	const cmdTableJsx = useReviewPacketsTable({
		tableName: "Staged-Commands",
		baseColumns: getCmdStagedBaseColumns(), 
		packets: stagedCmdPackets,  
		viewHandler: cmdPacketViewHandler,  
		entityType: EntityType.CmdPacket,  
		submitUpdateTagRequest,
		pageAllowedAdditions: [],
		pageAllowedRemovals: [ManagedTag.STAGED_FOR_FSW_PROD]
	});

	const tlmTableJsx = useReviewPacketsTable({
		tableName: "Staged-Telemetry",
		baseColumns: getTlmStagedBaseColumns(), 
		packets: stagedTlmPackets,  
		viewHandler: tlmPacketViewHandler,  
		entityType: EntityType.TlmPacket,  
		submitUpdateTagRequest,
		pageAllowedAdditions: [],
		pageAllowedRemovals: [ManagedTag.STAGED_FOR_FSW_PROD]
	});
	
	return (
		<div className={style.splitPageContainer}>
			<div className={style.splitPageTableContainer}>
				<h2>Staged Command Packets</h2>
				{cmdTableJsx}
			</div>
		
			<div className={style.splitPageButtonsContainer}/>

			<div className={style.splitPageTableContainer}>
				<h2>Staged Telemetry Packets</h2>
				{tlmTableJsx}
			</div>
	</div>
	);
};

export default ReviewStagedTab;