
import {FormRow, Input} from 'components/ui-core/form/FormControls';

export const UnitFormContent = ({controlProps}) => {

	return (
		<>
			<FormRow rowNum="1">
				<Input title="Short Name" attr="units_short" {...controlProps}/>
			</FormRow>
			<FormRow rowNum="2">
				<Input title="Long Name" attr="units_long" {...controlProps}/>
			</FormRow>
		</>

	);
};

export default UnitFormContent;
