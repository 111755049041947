
import style from 'App.module.css';

export const NON_TERMINAL_STATES = ["Scheduled", "In Progress"]

export const STATUS_TO_STYLES_MAP = {
    "Scheduled": {
        "tableStyle": null,
        "filterStyle": {}
    },
    "In Progress": {
        "tableStyle": null,
        "filterStyle": {}
    },
    "Success": {
        "tableStyle": style.success,
        "filterStyle": {
            color: "#0ab34d",
            background: "white"
        }
    },
    "Partial Success": {
        "tableStyle": style.partalSuccess,
        "filterStyle": {
            color: "#f5a145",
            background: "white"
        }
    },
    "Fail": {
        "tableStyle": style.fail,
        "filterStyle": {
            color: "#e61739",
            background: "white"
        }
    }
}

