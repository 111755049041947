// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.MsdMainPage_pageRootContainer__C1sTT {
	display: flex;
	flex-flow: row nowrap;
	height: 100vh;
	width: 100vw;
	max-width: none;
	position: relative;
	z-index: 0;
}

main {
	padding: 1rem;
	width: 100vw;
}

.MsdMainPage_mainSection__4aRHA {
	display: flex;
	flex-flow: column nowrap;
	height: 100% !important;

}

.MsdMainPage_mainHeader__6AEwv{
	flex-shrink: 0;
	flex-grow: 0;
}


.MsdMainPage_mainBody__GEaAp {
	min-height: 0;
	flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/layout/MsdMainPage/MsdMainPage.module.css"],"names":[],"mappings":";;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,aAAa;CACb,YAAY;CACZ,eAAe;CACf,kBAAkB;CAClB,UAAU;AACX;;AAEA;CACC,aAAa;CACb,YAAY;AACb;;AAEA;CACC,aAAa;CACb,wBAAwB;CACxB,uBAAuB;;AAExB;;AAEA;CACC,cAAc;CACd,YAAY;AACb;;;AAGA;CACC,aAAa;CACb,YAAY;AACb","sourcesContent":["\r\n\r\n.pageRootContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\theight: 100vh;\r\n\twidth: 100vw;\r\n\tmax-width: none;\r\n\tposition: relative;\r\n\tz-index: 0;\r\n}\r\n\r\nmain {\r\n\tpadding: 1rem;\r\n\twidth: 100vw;\r\n}\r\n\r\n.mainSection {\r\n\tdisplay: flex;\r\n\tflex-flow: column nowrap;\r\n\theight: 100% !important;\r\n\r\n}\r\n\r\n.mainHeader{\r\n\tflex-shrink: 0;\r\n\tflex-grow: 0;\r\n}\r\n\r\n\r\n.mainBody {\r\n\tmin-height: 0;\r\n\tflex-grow: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageRootContainer": `MsdMainPage_pageRootContainer__C1sTT`,
	"mainSection": `MsdMainPage_mainSection__4aRHA`,
	"mainHeader": `MsdMainPage_mainHeader__6AEwv`,
	"mainBody": `MsdMainPage_mainBody__GEaAp`
};
export default ___CSS_LOADER_EXPORT___;
