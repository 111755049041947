import React from 'react';
import style from './TintedPage.module.css'

function TintedPage(props) {

    const getTintedStyle = () => {
        const should_tint = process.env.REACT_APP_TINT
        if (should_tint === "false") {
            return null;
        }

        const tintColor = process.env.REACT_APP_ENV_COLOR

        if (tintColor === "red") {
            return style.red;
        }
        else if (tintColor === "green") {
            return style.green;
        }
        else if (tintColor === "blue") {
            return style.blue;
        }

        return null;
    }

    const tintedSytle = getTintedStyle();

    return (
        <div id="tintedContainer" className={style.container}>
            <div id="tintChildren" className={style.childLayer}>
                {tintedSytle && <div id='tintDivWrapper' className={tintedSytle} />}

                {props.children}
            </div>

        </div>
    );
}
export default TintedPage;
