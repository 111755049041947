
import {useForm} from 'react-hook-form';
import {yupResolver} from "@hookform/resolvers/yup";

import {getEditClass} from 'components/ui-core/form/FormUtils';

export const useMenuForm = (defaultValues, schema, useFormMode='onChange') => {
    
	const useFormObj = useForm({
		mode: useFormMode,
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: defaultValues		
	});

	const controlProps = {
		className: getEditClass(true),
		disabled: false,
		editModeActive: true,
		useFormObj: useFormObj,
		schema: schema
	}
    const result = {useFormObj, controlProps};
	return result;
}

export default useMenuForm;