import React from 'react';
import EditIcon from 'img/edit-icon.svg';
import style from './TableEditButton.module.css';

export const TableEditButton = ({editHandler}) => {
	return (
		<>
			<button className={style.tableEditButton} onClick={editHandler}>
				<img src={EditIcon} alt="Edit Icon" />
			</button>
		</>
	);
};

export default TableEditButton;
