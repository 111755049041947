import React from 'react';
import PropTypes from 'prop-types';
import style from './ToolBarBuildModeButton.module.css';

export const ToolBarBuildModeButton = ({ title, width, disabled, ...props }) => {

	let background = disabled ? '#666666': '#4e95c7';
	return (
		<>
			<button {...props} style={{ width: width, background: background }} className={style.ToolBarBuildModeButton}>
				<svg xmlns="http://www.w3.org/2000/svg" width="16.073" height="16.079" viewBox="0 0 16.073 16.079">
					<g id="Group_584" data-name="Group 584" transform="translate(-11.961 -7.921)">
						<path
							id="Build"
							d="M16.847,4.324a.574.574,0,0,0-.916-.144l-2.2,2.206a.578.578,0,0,1-.812,0l-.953-.954a.574.574,0,0,1,0-.812l2.2-2.2a.574.574,0,0,0-.172-.93,4.615,4.615,0,0,0-5.033.941,4.677,4.677,0,0,0-.906,4.719.57.57,0,0,1-.161.574L1.912,13.185a2.3,2.3,0,1,0,3.251,3.252l5.514-5.987a.57.57,0,0,1,.566-.164,6.433,6.433,0,0,0,1.658.229A4.189,4.189,0,0,0,15.91,9.379a4.687,4.687,0,0,0,.938-5.055ZM3.569,16.044a1.148,1.148,0,1,1,1.017-1.017,1.148,1.148,0,0,1-1.017,1.017Z"
							transform="translate(10.813 6.8)"
							fill="#fff"
						/>
					</g>
				</svg>
				{title}
			</button>
		</>
	);
};

ToolBarBuildModeButton.propTypes = {
	title: PropTypes.string.isRequired,
	width: PropTypes.string.isRequired
};

ToolBarBuildModeButton.defaultProps = {
	title: 'Build Mode',
	width: '8.5rem'
};

export default ToolBarBuildModeButton;
