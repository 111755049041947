import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/ui-core/modal/Modal/Modal';

import { useASTData } from 'context/ASTContext';
import {useMetadata, useAlerts, useSaveAlert, useTelemetryPackets, useTlmPointsLite} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import SideBarOption from 'model/SideBarOption';

import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable';
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';


import CreateAlertWizard from './CreateAlertWizard';
import ToggleSwitch from 'components/ui-core/buttons/ToggleSwitch/ToggleSwitch';
import { toast } from 'react-toastify';
import AlertStatusColumnFilter, { filterStatus } from 'components/ui-core/table/filter/AlertStatusColumnFilter';

const AlertsTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);
	const {setSideBarOption, setHeaderTitle} = useASTData();
	const metadata = useMetadata();	
	const getAllAlerts = useAlerts();
	const allTlmPacketsQuery = useTelemetryPackets();
	const tlmPointLiteQuery = useTlmPointsLite();
	const [tableData, setTableData] = useState([]);
	const [toggleStates, setToggleStates] = useState({});
	
	React.useEffect(() => {
		setSideBarOption(SideBarOption.Alerts);
		setHeaderTitle("Alerts");
		localStorage.setItem('lastPage',"/msd-alerts");
		if(tableData){
			const newToggleStates = {};
			// eslint-disable-next-line array-callback-return
			tableData?.map((row) => {
			newToggleStates[row._id] = row.active || false;
			});
			setToggleStates(newToggleStates);
		}
	}, [tableData, setHeaderTitle, setSideBarOption]);

	React.useEffect(() => {
		if(getAllAlerts.data) {
			setTableData(getAllAlerts.data);
		}
		setTableData(getAllAlerts.data);
	}, [getAllAlerts.data]);
	const history = useHistory();
	const viewAlertHandler = (alert) => {
		history.push(`/alert/${alert._id}`);
	};

	const changeAlertStatus = (newValue) => {
		if(newValue){
		newValue.active = newValue.active !== undefined? !newValue.active: false;
		(getAllAlerts.data? getAllAlerts.data : []).find(ele => newValue._id === ele._id).active = newValue.active;
		console.log(getAllAlerts.data, 'assigned');
		setTableData(getAllAlerts.data);
		setToggleStates(previousState => {
		    const updatedToggleStates = { ...previousState };
   			updatedToggleStates[newValue._id] = newValue.active;
    		return updatedToggleStates;	
		});
		updateAlerts(newValue);	
	}
	}

	const columns = React.useMemo(
		() => [			
			{
				Header: 'Alert Name',
				accessor: 'alert_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Persistence',
				accessor: 'persistence',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'TLM Point Name',
				accessor: 'tlm_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Board',
				accessor: 'board',
				Filter: SelectInputColumnFilter,
				filter: 'includes',
			},
			{
				Header: 'Subsystem',
				accessor: 'escalation_group',
				Filter: SelectInputColumnFilter,
				filter: 'includes',
			},
			{
				Header: 'Packet Name',
				accessor: (row) => {
					if (row && row.packet) {
						return row.packet?.map(packet => packet).join(', ');
					}
		
					return null; 
				},
				Filter: TextInputColumnFilter
			},
			{

				Header: 'Status',
				Cell: (tableInstance) => {
					const { row } = tableInstance;
					return (
						<div>
							<ToggleSwitch 
                            editModeActive={true}  
                            value={toggleStates[row.original._id]} 
                            onChange={() => changeAlertStatus(row.original)}
                        />
						</div>
					);
				},
				Filter: AlertStatusColumnFilter,
				filter: filterStatus
			},
			{
				Header: ' ',
				accessor: (data) => {
					return (
						<div>
							<button className={style.tableTextButton} 
								onClick={() => viewAlertHandler(data)}
							>
								View
							</button>
						</div>
					);
				}
			},
		],
		[toggleStates, changeAlertStatus, viewAlertHandler]
	);




	const {mutateAsync: UseUpdateAlert}  = useSaveAlert();



	const updateAlerts = async (updatedAlert)  => {
		return await UseUpdateAlert(updatedAlert)
		.then(refreshedAlert => {
			toast.success(`'${refreshedAlert["alert_name"]}' updated`);

			console.log(tableData);
			return refreshedAlert;
		})
		.catch(error => {
			toast.error("Alerts update failed");
			return Promise.reject(error)
		});	
	}

	const getAlertsData = () => {
		const resp =  tableData && tableData.length !== 0? tableData: getAllAlerts.data? getAllAlerts.data: [] ; 
		return resp;
	}
	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[getAllAlerts, metadata, tlmPointLiteQuery, allTlmPacketsQuery]}
					onLoad={() => (
						<Modal>
							<CreateAlertWizard 
								setCreateVisible={setCreateVisible} 
								allAlerts={getAllAlerts.data}
								metadata={metadata.data[0]}
								tlmPointLite = {tlmPointLiteQuery.data}
								tlmPackets = {allTlmPacketsQuery.data}
							/>
						</Modal> 
					)}				
				/>
			}			

			
			{<LoadingWrapper 
				queries={[getAllAlerts]}
				onLoad={() => (
					<ManagedMsdTable 
							tableName="AlertsTablePage" 
							columns={columns} 
							data={getAlertsData()}>
						
						<CreateButton
							width="6.5rem"
							title="Alert"
							onClick={() => {
								setCreateVisible(true);
							}}
						/>		
					</ManagedMsdTable>
				)}
	        /> }

		</MsdMainPage>
	);
};

export default AlertsTablePage;
