// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolBarEditButton_ToolBarEditButton__rrWQy {
	border-radius: 0.3rem;
	border: none;
	color: #ffffff;
	padding: 0.5rem;
	font-weight: 500;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	cursor: pointer;
	width: 5rem;
	height: 2rem;
	justify-content: space-around;
}

.ToolBarEditButton_ToolBarEditButton__rrWQy:hover {
	opacity: 0.8;
}

.ToolBarEditButton_ToolBarEditButton__rrWQy svg {
	width: 1rem;
	height: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/toolbar/buttons/ToolBarEditButton/ToolBarEditButton.module.css"],"names":[],"mappings":"AAEA;CACC,qBAAqB;CACrB,YAAY;CACZ,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,aAAa;CACb,qBAAqB;CACrB,mBAAmB;CACnB,eAAe;CACf,WAAW;CACX,YAAY;CACZ,6BAA6B;AAC9B;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,WAAW;CACX,YAAY;AACb","sourcesContent":["@import 'App.module.css';\r\n\r\n.ToolBarEditButton {\r\n\tborder-radius: 0.3rem;\r\n\tborder: none;\r\n\tcolor: #ffffff;\r\n\tpadding: 0.5rem;\r\n\tfont-weight: 500;\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\talign-items: center;\r\n\tcursor: pointer;\r\n\twidth: 5rem;\r\n\theight: 2rem;\r\n\tjustify-content: space-around;\r\n}\r\n\r\n.ToolBarEditButton:hover {\r\n\topacity: 0.8;\r\n}\r\n\r\n.ToolBarEditButton svg {\r\n\twidth: 1rem;\r\n\theight: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToolBarEditButton": `ToolBarEditButton_ToolBarEditButton__rrWQy`
};
export default ___CSS_LOADER_EXPORT___;
