import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import {getTlmPacketSchema, getToastMessage} from 'model/form-schema/ObjectSchemas'
import FormTab from 'components/ui-core/form/FormTab';
import useFormTab from 'components/ui-core/form/useFormTab';

import TelemetryPacketFormContent from './TelemetryPacketFormContent';
import { toast } from 'react-toastify';

export const TelemetryPacketFormTab = ({ updateEntityPromise, editModeActive, editModeTransitionHandler,
		tlmPacket, allTlmPackets, allCmdPacketsQuery, metadata }) => {

	const defaultSchema = getTlmPacketSchema(allTlmPackets, tlmPacket, editModeActive);
	const [schema, setSchema] = useState(defaultSchema);
	const [toastMes, setToastMes] = useState("");	
	const {useFormObj, controlProps} = useFormTab(
		{
			...cloneDeep(tlmPacket),
			src_nodes: tlmPacket.source_group.map(nodeEntry => nodeEntry.node),
			src_semver: tlmPacket.source_group.map(nodeEntry => `${nodeEntry.node}${nodeEntry.semver_range || "*"}`).join("\n")
		}, 
		schema, 
		editModeActive);
    const {watch, getValues} = useFormObj;
	watch("tlm_packet_name")
	useEffect(() => {
		console.log(getValues());
		const schem = getTlmPacketSchema(allTlmPackets, getValues(),  editModeActive);
		setSchema(schem);
		schem.isValid(useFormObj.getValues())
    	.then(valid => {
			if(!valid){
				const toa = getToastMessage(allTlmPackets, getValues(), "tlm_node", true);
				setToastMes(toa);
			}
		});
	}, [getValues()['src_nodes'], getValues()['subsystem_name'], getValues()['csp_port'], getValues()['tlm_id']]);

	useEffect(() => {
		if(toastMes || toastMes != ""){
			toast.warning(toastMes);
			setToastMes("");
		}
	}, [toastMes]);
	const createSubmitPromise = (formData) => {
		const semverLines = formData.src_semver.split("\n")
		const semverMapping = Object.fromEntries(
			formData.src_nodes.map(node => {
				const matchingSemver = semverLines.find(semverLine => semverLine.startsWith(node))?.replace(node, '').trim();
        		const semver = matchingSemver;
        		return [node, semver];
			})
		);
		let updatedPacket = {
			...formData,
			source_group: formData.src_nodes.map( (nodeName) => {
				return {
					node: nodeName,
					node_id: metadata.nodes.find(metaNodeEntry => metaNodeEntry.node === nodeName)?.node_id,
					semver_range: semverMapping.hasOwnProperty(nodeName) ? semverMapping[nodeName] : "*"
				}
			})

		}

		return updateEntityPromise(updatedPacket)
	};
	
	return (
		<>
			<FormTab editModeActive={editModeActive}
					editModeTransitionHandler={editModeTransitionHandler}
					createSubmitPromise={createSubmitPromise}
					useFormObj={useFormObj}>

				<TelemetryPacketFormContent 
					controlProps={controlProps} 
					metadata={metadata}
					allCmdPacketsQuery={allCmdPacketsQuery}
					useFormObj={useFormObj}					
					isWizard={false}
					editModeActive={editModeActive}
				/>				
			</FormTab>

		</>
	);
};

export default TelemetryPacketFormTab;
