import React from 'react';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'

import 'animate.css';
import style from 'App.module.css';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import { getSeverityColumn } from './OptionHelper';
import { TLM_RELATED_TYPES } from 'model/EntityType';

export const OptionsTable = ({ optionsData, setCreateVisible, setEditOptionValue, handleDelete, entityType}) => {

	const columns = React.useMemo(
		() => {			
			const columns = [
				{
					Header: 'Name',
					accessor: 'name',
				},
				{
					Header: 'Value',
					accessor: 'value',
				}
			]

			// Severity for telemetry
			if (TLM_RELATED_TYPES.includes(entityType)) {
				columns.push(getSeverityColumn())
			}

			// Buttons
			columns.push(
				{
					Header: ' ',
					accessor: (optionRow) => {
						return (
							<div className={style.tableCellContainer}>
								<TableEditButton editHandler={(event) => {
									event.preventDefault()
									setEditOptionValue(optionRow)
								}}/>
								<TableDeleteButton deletionHandler={(event) => {
									event.preventDefault()
									handleDelete(optionRow)
								}}/>
							</div>
						);
					},
					columnType: "buttons"
				},
			)

			return columns

		},
		[]
	);
	
	return (
		<>
 
			<ManagedMsdTable tableName="OptionsTable" columns={columns} data={optionsData}>
				<CreateButton
					additionalStyles={{"marginBottom": "3px"}}
					width="9rem"
					title="Enumeration"
					onClick={(event) => {
						event.preventDefault()
						setCreateVisible(true);
					}}
				/>		
			</ManagedMsdTable>
		</>
	);
};

export default OptionsTable;
