import axios from 'axios'
import { useAuthContext } from 'components/ui-core/auth/AuthContext';

const useAxios = (baseURL) => {
    const {getBearerToken} = useAuthContext();

    const axiosInstance = axios.create({
        baseURL,
    });

    axiosInstance.interceptors.request.use(
        async (request) => {
            request.headers.Authorization = await getBearerToken();
            return request;
        }
    )

    return axiosInstance
}


export default useAxios;