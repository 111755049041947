import React from 'react';
import style from './Wizard.module.css';

export const Wizard = ({width, ...props}) => {
	console.log(`wizard width= ${width}`);

    return (
		<div style={{ width: width}} className={style.wizard}>
            {props.children}
        </div>
	);
};

Wizard.defaultProps = {
	width: '45.375rem',
};

export default Wizard;
