// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaveableTab_saveableContainer__vH3br {
    display: flex;
    flex-flow: column nowrap;
    height:100%;
}

.SaveableTab_saveableContent__XFid\\+ {
    flex-shrink: 1;
    flex-grow: 0;
    min-height: 0;
}

.SaveableTab_saveableButtonsContainer__gSscb {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0.5rem 0.7rem;
    min-height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui-core/page/SaveableTab.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".saveableContainer {\r\n    display: flex;\r\n    flex-flow: column nowrap;\r\n    height:100%;\r\n}\r\n\r\n.saveableContent {\r\n    flex-shrink: 1;\r\n    flex-grow: 0;\r\n    min-height: 0;\r\n}\r\n\r\n.saveableButtonsContainer {\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n    padding: 0.5rem 0.7rem;\r\n    min-height: 40px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveableContainer": `SaveableTab_saveableContainer__vH3br`,
	"saveableContent": `SaveableTab_saveableContent__XFid+`,
	"saveableButtonsContainer": `SaveableTab_saveableButtonsContainer__gSscb`
};
export default ___CSS_LOADER_EXPORT___;
