// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableDeleteButton_tableDeleteButton__bZpfA {
	background: none;
	border: none;
	cursor: pointer;
	margin: 0px;
	padding: 1px 6px;
}

.TableDeleteButton_tableDeleteButton__bZpfA:hover {
	opacity: 0.8;
}

/* Responsive website scaling for 1920 x 1080 screens */

@media screen and (max-width: 1920px) {
	.TableDeleteButton_tableDeleteButton__bZpfA img {
		width: 1.5rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/buttons/TableDeleteButton/TableDeleteButton.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,YAAY;CACZ,eAAe;CACf,WAAW;CACX,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb;;AAEA,uDAAuD;;AAEvD;CACC;EACC,aAAa;CACd;AACD","sourcesContent":[".tableDeleteButton {\r\n\tbackground: none;\r\n\tborder: none;\r\n\tcursor: pointer;\r\n\tmargin: 0px;\r\n\tpadding: 1px 6px;\r\n}\r\n\r\n.tableDeleteButton:hover {\r\n\topacity: 0.8;\r\n}\r\n\r\n/* Responsive website scaling for 1920 x 1080 screens */\r\n\r\n@media screen and (max-width: 1920px) {\r\n\t.tableDeleteButton img {\r\n\t\twidth: 1.5rem;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableDeleteButton": `TableDeleteButton_tableDeleteButton__bZpfA`
};
export default ___CSS_LOADER_EXPORT___;
