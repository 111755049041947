import React from 'react';

import { getColorStyleObject, sortTagNames } from 'components/tags/TagHelper';
import {usePageState} from 'components/ui-core/page/usePageState';

export function TagColumnFilter({ column: { id, filterValue, setFilter, preFilteredRows }, tableName}) {
	// Calculate the options for filtering using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
			//Keeps the tag filter empty until the metadata api response			
			(row.original.environment_tags? row.original.environment_tags: [])
			.filter(tag => tag.value)
			.forEach(tag => {
					options.add(tag.name)
				}
			)
		});
		return [...options.values()].sort(sortTagNames);
	}, [preFilteredRows]);

	const selectColorStyle = getColorStyleObject(filterValue, "background");
	const {setFilterValue} = usePageState();

	// Render a multi-select box
	return (
		<select
            style={{ marginRight: '2rem', ...selectColorStyle }}
			value={filterValue}
			onChange={(e) => {
				const newFilterValue = e.target.value || undefined
				setFilterValue(tableName, id, newFilterValue)
				setFilter(newFilterValue);
			}}
		>
			<option value="">Select a value</option>
			{options.map((option, i) => {
				const optionColorStyle = getColorStyleObject(option, "background");
				return (
					<option style={optionColorStyle} key={i} value={option}>
						{option}
					</option>			
				)
			})}
		</select>
	);
}

export const filterTag = (rows, columnId, filterValue) => {

	const result = rows.filter(row => {
		return row.original.environment_tags.some(tag => tag.value && tag.name === filterValue);
	})

	return result;
}

export default TagColumnFilter;