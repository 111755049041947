import React from 'react';

import {usePageState} from 'components/ui-core/page/usePageState';
import { STATUS_TO_STYLES_MAP } from 'components/review/PushJobHelper'; 

export function StatusColumnFilter({ column: { id, filterValue, setFilter, preFilteredRows }, tableName}) {
	const getColorStyleObject = (filterValue) => {
		if (filterValue) {
			return STATUS_TO_STYLES_MAP[filterValue]["filterStyle"]
		} 
		return {"background": "white"}
	}

	// Calculate the options for filtering using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
			options.add(row.values[id]);
		});
		return [...options.values()].sort();
	}, [id, preFilteredRows]);

	const selectColorStyle = getColorStyleObject(filterValue, "background");
	const {setFilterValue} = usePageState();

	return (
		<select
            style={{ marginRight: '2rem', ...selectColorStyle }}
			value={filterValue}
			onChange={(e) => {
				const newFilterValue = e.target.value || undefined
				setFilterValue(tableName, id, newFilterValue)
				setFilter(newFilterValue);
			}}
		>
			<option value="">Select a value</option>
			{options.map((option, i) => {
				const optionColorStyle = getColorStyleObject(option, "background");
				return (
					<option style={optionColorStyle} key={i} value={option}>
						{option}
					</option>			
				)
			})}
		</select>
	);
}

export default StatusColumnFilter;