import { FormRow, Select } from 'components/ui-core/form/FormControls';
import { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

export const VersionCompareFormContent = ({controlProps, useFormObj, isWizard, spacecrafts, allVersions}) => {
	const [versionsA, setVersionsA] = useState([]);
	const [versionsB, setVersionsB] = useState([]);
	const [sc, setSc] = useState("");
	const [sc2, setSc2] = useState("");
	const spacecraftsB = [...spacecrafts];


	const changeSelectedSpacecraftA = (event) => {
		const selectedSpacecraftA = event.target.value;
		if(selectedSpacecraftA != sc){
			setSc(event.target.value);
			let db_a = allVersions.filter(ele => ele['spacecraft_name'] == selectedSpacecraftA )[0];
			let versions = db_a['databases'].map(ele => ele['version']);
			versions = ['Choose the version', ...versions];
			setVersionsA([...versions])
		}
	}
	const changeSelectedSpaceCraftB = (event) => {
		const selectedSpacecraftB = event.target.value;
		if(selectedSpacecraftB != sc2){
			setSc2(event.target.value);
			let db_b = allVersions.filter(ele => ele['spacecraft_name'] == selectedSpacecraftB )[0];
			let versions = db_b['databases'].map(ele => ele['version']);
			versions = ['Choose the version', ...versions];
			setVersionsB([...versions])
		}
	}
	return (
		<>
			

			<FormRow rowNum="1">
				<div> 
					<h2>SpaceCraft A</h2>
					<Select style={{ width: "60rem" }} attr="spacecraft_a" {...controlProps} valueOptions={spacecrafts} onChange = {changeSelectedSpacecraftA} />
				 </div>
				 <div> 
					<h2>Version A</h2>
					<Select style={{ width: "60rem" }} attr="version_a" {...controlProps} valueOptions={versionsA} />
				</div>
				<div></div>
				<div></div>
			</FormRow>
			<FormRow rowNum="2">
				<div></div>
			</FormRow>
			<FormRow rowNum="3">
				<div></div>
			</FormRow>
			<FormRow rowNum="4">
				<div>
					<h2 style= {{ color: '#f88306'}}>SpaceCraft B</h2>
					<Select style={{ width: "60rem" }} attr="spacecraft_b" {...controlProps} valueOptions={spacecraftsB} onChange={changeSelectedSpaceCraftB} />
				</div>
				<div>
					<h2 style= {{ color: '#f88306'}}>Version B</h2>
					<Select style={{ width: "60rem" }} attr="version_b" {...controlProps} valueOptions={versionsB} />
				</div>
				<div></div>
				<div></div>
			</FormRow>
			<FormRow rowNum="5">
				<div style={{ display: "inline-block", paddingTop: '3rem' }}>
					<FaInfoCircle style={{ marginRight: "5px" }} /> <span>MSD Version Compare perform the comparition of CMD/TLM between selected Spacecraft & Version of A & B , Result will be in excel format</span>
				</div>
			</FormRow>
			

		</>
	);
};

export default VersionCompareFormContent;
