import * as yup from "yup";

import { isUniqueField, isUniqueValue } from 'components/ui-core/form/FormUtils';
import { isInFswArr } from "components/tags/TagHelper";
import { useState } from "react";
import { toast } from "react-toastify";

const int_size_values = [1,2,4,8];
const float_size_values = [4,8];
const number = () => {
	return yup.number()
		.transform((value, origValue) => {
			return origValue === '' || origValue === null ? undefined : value;
		})
}

export const integer = () => { 
	return number()
		.integer("Value must be an integer")
		.typeError("Value must be an integer");
}

export const requiredInteger = () => {
	return number()
		.integer("Value must be an integer")
		.required()
}

export const requiredFloat = () => {
	return yup.number('Value must be float')
		.required('value is required')
}


export const positiveInteger = () => {
	return integer().positive("Value must be a positive integer");
}

export const requiredPositiveInteger = () => {
	return positiveInteger().required("Value is required");
}

export const nonNegativeInteger = () => {
	return integer().min(0, "Value must be greater or equals to zero");
}

export const requiredNonNegativeInteger = () => {
	return nonNegativeInteger().required("Value is required");
}

export const float = () => { 
	return number().typeError("Value must be an floating point number");
}


export const requiredUniqueField = (allObjects, attrName, existingValue, fieldTitle) => {
	return yup.string().required("Value is required")
		.test("unique", `${fieldTitle} must be unique`,
			function(potentialValue) {
				return isUniqueValue(potentialValue, attrName, allObjects, existingValue);
			});
} 

export const requiredUniqueFieldValue = (allObjects, attrName, existingValue, fieldTitle) => {
	return yup.string().required("Value is required")
		.test("unique", `${fieldTitle} must be unique`,
			function(potentialValue) {
				return isUniqueField(potentialValue, attrName, allObjects, existingValue);
			});
}

export const requiredUniqueEnumerations = (allOptions, name) => {
	return integer().required("Value is required")
		.test("unique", `Value must be unique`,
			function(potentialValue) {
				if(name){
					return allOptions.filter(obj => obj["value"] === potentialValue && obj["name"] != name).length < 1 
				}
				else{
					return allOptions.filter(obj => obj["value"] === potentialValue).length < 1
				}
			});
}

export const requiredUniqueFieldCmdComb = (allObjects, packet, messageTitle, check_type, requiredUniqueCheck) => {
	return yup.array().test("unique nodes and name", `${messageTitle} must be unique`,
			function(potentialValue) {
				if(requiredUniqueCheck){
					return cspIdBoardsCheck(allObjects, packet, check_type, potentialValue) && boardsAndPacketNameCheck(allObjects, packet, check_type, potentialValue);
				}
				else {
					return true;
				}
			});
}

const cspIdBoardsCheck = (allObjects, packet, check_type, potentialValue) => {
	const attr2 = check_type === "cmd_node"? "destination_group" : "source_group";
	const attr3 = "csp_port";
	const attr4 = check_type === "cmd_node"? "cmd_code" : "tlm_id";
	potentialValue = potentialValue? potentialValue : [];
	const filteredRowsBoards = potentialValue.length === 0 ? 
	allObjects.filter(obj => obj[attr2].length === 0) : 
	allObjects.filter(obj => (obj[attr2]?.map(item => item.node)).slice().sort().some(value => potentialValue.slice().sort().includes(value)));
	const filteredRowsCsp = filteredRowsBoards.filter(obj => obj[attr3] == packet[attr3]);
	const filterByPacketID =  filteredRowsCsp.filter(obj => obj[attr4] == packet[attr4]);
	return filterByPacketID.filter(obj => obj["_id"] !== packet["_id"]).length === 0;
}

const boardsAndPacketNameCheck = (allObjects, packet, check_type, potentialValue) => {
	const attr2 = check_type === "cmd_node"? "destination_group" : "source_group";
	const attr4 = check_type === "cmd_node"? "cmd_name" : "tlm_packet_name";
	potentialValue = potentialValue? potentialValue : [];
	const filteredRowsBoards = potentialValue.length === 0 ? 
	allObjects.filter(obj => obj[attr2].length === 0) : 
	allObjects.filter(obj => (obj[attr2]?.map(item => item.node)).slice().sort().some(value => potentialValue.slice().sort().includes(value)));
	const filteredRowsName = filteredRowsBoards.filter(obj => obj[attr4] == packet[attr4]);
	return filteredRowsName.filter(obj => obj["_id"] !== packet["_id"]).length === 0;
}

export const requiredUniqueFieldAlertComb = (allObjects, alert, messageTitle, requiredUniqueCheck) => {
	return yup.string().required("Value is required").test("unique Alerts and name", `${messageTitle} must be unique`,
			function(potentialValue) {
				if(requiredUniqueCheck){
					potentialValue = potentialValue? potentialValue : "";
					const filterTlm = allObjects.filter(row =>  row['tlm_id'] === alert['tlm_id']);
					const filterBoard = filterTlm.filter(row => row['board'] === potentialValue);
					return filterBoard.filter(obj => obj["_id"] !== alert["_id"]).length === 0;
				}
				else {
					return true;
				}
			});
}

//Regex to check double underscores for TLm Points
const nameRegex = /^(?!.*__)[A-Z0-9_]+$/
const nameWithSpecialRegex = /^(?!.*__)[A-Z0-9_]+$/
export const requiredUniqueName = (allObjects, attrName, existingName) => {
	return requiredUniqueField(allObjects, attrName, existingName, "Name")
		.matches(nameRegex, "Name must container A-Z, 0-9 and _ characters only");
}

export const requiredUniqueFieldName = (allObjects, attrName, existingName) => {
	return requiredUniqueFieldValue(allObjects, attrName, existingName, "Name")
		.matches(nameRegex, "Name must container A-Z, 0-9 and _ characters only");
}

export const requiredName = () => {
	return yup.string().required("Value is required")
	.matches(nameRegex, "Name must container A-Z, 0-9 and _ characters only");
}

export const requiredSpecialName = () => {
	return yup.string().required("Value is required")
	.matches(nameWithSpecialRegex, "Name must container A-Z, 0-9 and _&  characters only");
}

const versionRegex = /^\d+\.\d+\.\d+$/
export const requiredVersion = () => {
	return yup.string().required("Value is required")
	.matches(versionRegex, "Version must be in Major.Minor.Patch format only")
}
export const requiredUniqueComb = (allObjects,  packet,  check_type, requireUniqueCheck) => {
	return requiredUniqueFieldCmdComb(allObjects, packet, "Name & Boards", check_type, requireUniqueCheck) 
}

export const requiredUniqueAlertComb = (allObjects, alert, requireUniqueCheck) => {
	return requiredUniqueFieldAlertComb(allObjects, alert , "Tlm_id & Board", requireUniqueCheck) 
}

export const string = () => {
	return yup.string();
}


export const requiredString = () => {
	return yup.string()
		.transform((value, origValue) => {
			return origValue === null ? undefined : value;
		})
		.required("Value is required");
}

export const boolean = () => {
	return yup.boolean().required("Value is required");
}

export const optionalBoolean = () => {
	return yup.boolean().nullable();
}

const getSizeBytesValidationObject = (typeFieldName, derivedFieldName) => {
	return requiredPositiveInteger()
		.test(`sizeBytesIntegerRange-${typeFieldName}`, 
			"Value must in 1, 2, 4 or 8",
			function(potentialValue, testContext) {
				const type = testContext.parent[typeFieldName];
				if (type === "unsigned" || type === "signed") {
					return int_size_values.includes(potentialValue);
				}  else if (type === "derived" && derivedFieldName)  {
					// Take from the derived type instead
					const derivedType = testContext.parent[derivedFieldName];
					if (derivedType === "unsigned" || derivedType === "signed") {
						return int_size_values.includes(potentialValue);
					}					
				}

				return true;
			})
		.test(`sizeBytesFloatRange-${typeFieldName}`, 
			"Value must in 4 or 8",
			function(potentialValue, testContext) {
				const type = testContext.parent[typeFieldName];
				if (type === "float") {
					return float_size_values.includes(potentialValue);
				} else if (type === "derived" && derivedFieldName)  {
					// Take from the derived type instead
					const derivedType = testContext.parent[derivedFieldName];
					if (derivedType === "float") {
						return float_size_values.includes(potentialValue);
					}					
				}

				return true;
			});
}

const mandatoryInFSW = number()
	.test("FswEnvRequired", "Mandatory when in FSW environment",
		(value, testContext) => !(isInFswArr(testContext.parent["environment_tags"]) && value == null));
	
export const getCmdPacketSchema = (allPackets, existingPacket , requireUniqueCheck) => {
	const schema = yup.object().shape({

		cmd_name: requiredName(),
		subsystem_name: requiredString(),
		csp_port: nonNegativeInteger().concat(mandatoryInFSW),
		description: requiredString(),
		cmd_code: nonNegativeInteger().concat(mandatoryInFSW),
		critical_command_warning: string()
			.when(
				"critical_command", {
					is: true, 
					then: yup.string().required("A warning is required for critical commands")}) 
			.when(
				"critical_command", {
					is: false, 
					then: yup.string().nullable()}),
		dest_nodes: yup.array().of(yup.string()).min(1, "Source nodes are required"),
		dest_semver: optionalString(),
	});    
    return schema;
};

const getByteArrayRelatedFields = () => {
	return {
		array_element_type: string()
			.when('type', {
				is: val => val === "block_array" || val === "var_block_array",
				then: requiredString(),
				otherwise: string().nullable()
			}),
		array_element_size: integer()
			.when('type', {
				is: val => val === "block_array" || val === "var_block_array",
				then: getSizeBytesValidationObject("array_element_type"),
				otherwise: integer().nullable()
			})		
	}	
}

export const getCmdParamSchema = (allParams, existingName) => {
	const schema = yup.object().shape({

		cmd_param_name: requiredUniqueName(allParams, "cmd_param_name", existingName),
		type: requiredString(),
		description: requiredString(),
		size_bytes: getSizeBytesValidationObject("type"),
		units_short: string().nullable(),
		default_value: string().nullable(), 
		min_value: yup.number().nullable()
			.when('type', {
				is: val => val === "float",
				then: float().required("Required when type is float"),
				otherwise: integer().nullable()
			}),
		max_value: yup.number().nullable()
			.when('type', {
				is: val => val === "float",
				then: float().required("Required when type is float"),
				otherwise: integer().nullable()
			}),
		...getByteArrayRelatedFields(),
		support_nan: optionalBoolean(),
		mapped_nan_value: float()
			.test("MappedNanRequired", "Value required for floating point parameters that support NaN'",
				(value, testContext) => {
					if (testContext.parent["support_nan"] && testContext.parent["type"] === "float" ) {
						return value != null
					}
					return true;
				})
			.test("MappedNanMinLimit", "Value must be greater or equal to 'Min Value'",
				(value, testContext) => {
					if (testContext.parent["support_nan"] && testContext.parent["type"] === "float") {
						return isGreaterThanLimit(value, testContext, "min_value")
					}
					return true;
				})
			.test("MappedNanMaxLimit", "Value must be less than or equal to 'Max Value'",
				(value, testContext) => {
					if (testContext.parent["support_nan"] && testContext.parent["type"] === "float") {
						return isLessThanLimit(value, testContext, "max_value");
					}
					return true;
				})

	});
	
    return schema;
};


export const getTlmPacketSchema = (allPackets, existingPacket, requiredUniqueCheck) => {
	const schema = yup.object().shape({
		
		tlm_packet_name: requiredName(),
		subsystem_name: requiredString(),
		csp_port: nonNegativeInteger().concat(mandatoryInFSW),
		description: requiredString(),
		tlm_id: nonNegativeInteger().concat(mandatoryInFSW),
		file_id: positiveInteger().nullable(),
		update_frequency: positiveInteger().nullable(),
		src_nodes:yup.array().of(yup.string()).min(1, "Source nodes are required"),
		src_semver: optionalString(),
		non_telem: boolean(),
		allow_short: boolean(),
		response: boolean(),
		response_to_cmd_pkts: yup.array().of(yup.string()).nullable()
	});

    return schema;
};


const getLimitValueValidationObject = () => {
	return number()
		.when('type', {
			is: val => val === "signed" || val ==="unsigned",
			then: integer().nullable(),
			otherwise: float().nullable()
		})
		.test("LimitValueRequired", "Value is required",
			(value, testContext) => !(testContext.parent["limit"] && value == null)
		);
}

const isGreaterThanLimit = (value, testContext, relativeLimitName) => {
	const relativeValue = testContext.parent[relativeLimitName];
	if (relativeValue == null) {
		return true;
	}

	return value >= relativeValue;
}

const isLessThanLimit = (value, testContext, relativeLimitName) => {
	const relativeValue = testContext.parent[relativeLimitName];
	if (relativeValue == null) {
		return true;
	}

	return value <= relativeValue;
}



export const getTlmPointSchema = (allPoints, existingName) => {
	const derivedInputsRegex = /^[A-Z0-9_,]+$/

	const schema = yup.object().shape({		
		tlm_point_name: requiredUniqueName(allPoints, "tlm_point_name", existingName),
		type: requiredString(),
		description: requiredString(),
		size_bytes: getSizeBytesValidationObject("type", "derived_type"),
		units_short: string().nullable(),
		limit: boolean(),
		unique_across_tlm_packets: optionalBoolean(),
		unique_across_boards: optionalBoolean(),

		// required if type=float and limit, integer/float based on type
		red_low_limit: getLimitValueValidationObject(),
		yellow_low_limit: getLimitValueValidationObject()
			.test("YellowLowRelCheck", "Value must be greater or equal to the Red Low limit",
				(value, testContext) => isGreaterThanLimit(value, testContext, "red_low_limit")),
		yellow_high_limit: getLimitValueValidationObject()
			.test("YellowHighRelCheck", "Value must be greater or equal to the Yellow Low limit",
				(value, testContext) => isGreaterThanLimit(value, testContext, "yellow_low_limit")),
		red_high_limit: getLimitValueValidationObject()
			.test("RedHighRelCheck", "Value must be greater or equal to the Yellow High limit",
				(value, testContext) => isGreaterThanLimit(value, testContext, "yellow_high_limit")),

		...getByteArrayRelatedFields(),

		derived_type: string().nullable(),
		derived_inputs: string().nullable()
			.when('type', {
				is: val => val === "derived",
				then: requiredString().matches(derivedInputsRegex, "Inputs Point names must be comma separated without spaces")
				.test("unique-derived-inputs", "Derived inputs must be unique", function(value) {
                    if (!value) return true; 
                    const inputs = value.split(",").map(input => input.trim()); 
                    return inputs.length === new Set(inputs).size; 
                }),
				otherwise: string().nullable()
			}),
		derived_calc_freq: string()
			.when('type', {
				is: val => val === "derived",
				then: requiredString(),
				otherwise: string().nullable()
			}),
		derived_algorithm: string()
			.when('type', {
				is: val => val === "derived",
				then: requiredString(),
				otherwise: string().nullable()
			}),
		derived_expression: string()
			.when('type', {
				is: val => val === "derived",
				then: requiredString(),
				otherwise: string().nullable()
			}),
		derived_expression_python: string().nullable()
	});

    return schema;
};


export const getTagSchema = (allTags, existingName) => {
	const schema = yup.object().shape({
		name: requiredUniqueName(allTags, "name", existingName),
		description: requiredString(),
	});

    return schema;
};

export const getVersionCompareSchema = () => {
	const schema = yup.object().shape({
		spacecraft_a: string().test('notEmpty', 'Spacecraft A is required', value => value !== ''),
		version_a: string().matches('FLIGHT|FSW|MSD', 'Invalid Version A'),
		spacecraft_b: string().test('notEmpty', 'Spacecraft A is required', value => value !== ''),
		version_b: string().matches('FLIGHT|FSW|MSD', 'Invalid Version B')
	});

    return schema;
};

export const getUnitSchema = (allUnits, existingShortName, existingLongName) => {
	const schema = yup.object().shape({
		units_short: requiredUniqueField(allUnits, "units_short", existingShortName, "Short Name"),
		units_long: requiredUniqueField(allUnits, "units_long", existingLongName, "Long Name")
	});

    return schema;
};

export const getSnapShotSchema = () => {
	const schema = yup.object().shape({
		version: requiredName(),
		test_status: requiredString(),
		comments: optionalString(),
		created_date: optionalString(),
	});

    return schema;
};

const optionalString = () => {
	return string().optional();
}

const optionalLiteral = () => {
	return string().nullable().transform((value, origValue) => {
		console.log(`value: ${value}, origValue: ${origValue}`)
		return value = "" ? null : value;
	})
}

export const getBoardSchema = (allBoards, boardName) => {
	const schema = yup.object().shape({
		name: requiredUniqueField(allBoards, "name", boardName , "Board Name"),
		version: requiredVersion()
	});

    return schema;
};

export const getDiscreteSchema = (allDiscrete, existingName, createMode) => {
	const schema = yup.object().shape({
		name: createMode? requiredUniqueFieldName(allDiscrete, "alert_name", existingName): requiredName(),
		value: requiredInteger(),
	});

    return schema;
};

export const getOptionsSchema = (allOptions, existingName) => {
	const schema = yup.object().shape({
		name: requiredUniqueFieldName(allOptions, "name", existingName),
		value: requiredUniqueEnumerations(allOptions, existingName),
		severity: optionalLiteral()
	});

    return schema;
};

export const getCreateLimitOptionsSchema = () => {
	const schema = yup.object().shape({
		sc_mode: requiredString(),
		red_low: requiredFloat(),
		yellow_low: requiredFloat(),
		red_high: requiredFloat(),
		yellow_high: requiredFloat()
	});

    return schema;
};

export const getEditLimitOptionsSchema = () => {
	const schema = yup.object().shape({
		red_low: requiredFloat(),
		yellow_low: requiredFloat(),
		red_high: requiredFloat(),
		yellow_high: requiredFloat()
	});

    return schema;
};

export const getDiscreteOptionsSchema = () => {
	const schema = yup.object().shape({
		sc_mode: requiredString(),
	});

    return schema;
};

export const getBitwiseSchema = (allTlmBitwise, existingName) => {
	const schema = yup.object().shape({
		tlm_bitwise_name: requiredUniqueName(allTlmBitwise, "tlm_bitwise_name", existingName)
			.test("Not Filler", "Bitwise names starting with 'BIT_FILLER_' are reserved for system-mamaged fillers",
				function(potentialName) {
					return !potentialName.startsWith("BIT_FILLER_");
				}),
		size_bits: requiredPositiveInteger(),
		description: requiredString(),
	});

    return schema;
};

export const getBitwiseOptionsSchema = (allOptions, existingName, maxValue) => {
	
	const schema = yup.object().shape({
		name: requiredUniqueName(allOptions, "name", existingName),
		value: requiredNonNegativeInteger()
			.max(maxValue, `Value must be less than or equal to ${maxValue}`)
	});

    return schema;
};


export const getEnnumerationLimitOptionsSchema = () => {
	
	const schema = yup.object().shape({
		name: requiredString(),
		value: requiredNonNegativeInteger()
	});

    return schema;
};

export const moveToSeqenceNoSchema = (maxSeqNo) => {
	const schema = yup.object().shape({
		move_to_seq: positiveInteger()		
			.test("range valid", `Value must be between 1 and ${maxSeqNo}`,
				function(potentialSeqNo) {
					return !potentialSeqNo || (potentialSeqNo > 0 && potentialSeqNo <= maxSeqNo);
				})
	});

    return schema;
};

export const getAlertSchema = (allAlerts, alert, requireUniqueCheck) => {
	const schema = yup.object().shape({

		alert_name: requiredUniqueName(allAlerts, "alert_name", alert? alert['alert_name'] : ""),
		persistence: requiredInteger(),
		board: requiredUniqueAlertComb(allAlerts, alert, requireUniqueCheck),
		packet: yup.array(),
		escalation_group: requiredSpecialName(),
	});
	
    return schema;
};

export const getToastMessage = (allObjects, packet, check_type, requiredUniqueCheck) => {
	let potentialValue = packet['dest_nodes'];
	if(requiredUniqueCheck){
		let toastMsg = '';
		const attr1 = check_type === "cmd_node"? "cmd_name": "tlm_packet_name";
		const toastTitlePackets = check_type === "cmd_node"? "CMD" : "TLM";
		const attr2 = check_type === "cmd_node"? "destination_group" : "source_group";
		const attr3 = "csp_port";
		const attr4 = check_type === "cmd_node"? "cmd_code" : "tlm_id";
		potentialValue = potentialValue? potentialValue : [];
		const filteredRowsBoards = potentialValue.length === 0 ? 
		allObjects.filter(obj => obj[attr2].length === 0) : 
		allObjects.filter(obj => (obj[attr2]?.map(item => item.node)).slice().sort().some(value => potentialValue.slice().sort().includes(value)));
		const filteredRowsCsp = filteredRowsBoards.filter(obj => obj[attr3] == packet[attr3]);
		const filterByPacketID =  filteredRowsCsp.filter(obj => obj[attr4] == packet[attr4]);
		const filterByPacketIdToastItems = filterByPacketID.filter(obj => obj["_id"] !== packet["_id"]).map(ele => ele[attr1]);
		const filterByPacketNames = filteredRowsBoards.filter(obj => obj[attr1] == packet[attr1]).filter(obj => obj["_id"] !== packet["_id"]).map(ele => ele[attr1])
		if(filterByPacketIdToastItems.length > 0){
			toastMsg = 'Found Duplicate ' + toastTitlePackets + ' Packets - ' + filterByPacketIdToastItems.toString()+ ' with same '+ attr3 + ' , ' + attr4+ ' , '+ attr2; 
		}
		else if(filterByPacketNames.length> 0){
			toastMsg = 'Found Duplicate ' + toastTitlePackets + ' Packets - ' + filterByPacketNames.toString()+ ' with same '+ attr2 + ' , ' + attr1; 
		}
		else {
			toastMsg = '';
		}
		return toastMsg;
	}
}



