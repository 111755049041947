
class SideBarOption {
  // Create new instances of the same class as static attributes
  static CmdPacket = new SideBarOption("CmdPacket")
  static CmdParam = new SideBarOption("CmdParam")
  static TlmPacket = new SideBarOption("TlmPacket")
  static TlmPoint = new SideBarOption("TlmPoint")
  static Bitwise = new SideBarOption("Bitwise")
  static Tag = new SideBarOption("Tag")
  static Units = new SideBarOption("Units")
  static BoardVersions = new SideBarOption("BoardVersions")
  static Review = new SideBarOption("Review")
  static MSDDocs = new SideBarOption("MSDDocs")
  static MSDVersionCompare = new SideBarOption("MSDVersionCompare")
  static Alerts = new SideBarOption("Alerts")

  constructor(name) {
    this.name = name
  }
}

export default SideBarOption;