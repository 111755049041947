import React from 'react';
import style from './CloseButton.module.css';

export const CloseButton = () => {
	return (
		<>
			<div className={style.closeBtnContainer}>
				<div>
					<svg id="x-mark" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						<path
							id="Path_133"
							data-name="Path 133"
							d="M12,24A12,12,0,1,1,24,12,12.014,12.014,0,0,1,12,24ZM12,1.714A10.286,10.286,0,1,0,22.286,12,10.3,10.3,0,0,0,12,1.714Zm0,0"
							fill="#f5a145"
						/>
						<path
							id="Path_134"
							data-name="Path 134"
							d="M155.715,156.574a.854.854,0,0,1-.606-.251l-8.571-8.571a.857.857,0,0,1,1.212-1.212l8.571,8.571a.857.857,0,0,1-.606,1.463Zm0,0"
							transform="translate(-139.43 -139.431)"
							fill="#f5a145"
						/>
						<path
							id="Path_135"
							data-name="Path 135"
							d="M147.143,156.572a.857.857,0,0,1-.606-1.463l8.571-8.571a.857.857,0,1,1,1.212,1.212l-8.571,8.571A.854.854,0,0,1,147.143,156.572Zm0,0"
							transform="translate(-139.429 -139.43)"
							fill="#f5a145"
						/>
					</svg>

					<p>Close</p>
				</div>
			</div>
		</>
	);
};


export default CloseButton;
