// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TelemetryPointFormContent_limitsRow__RBYgv {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
}

.TelemetryPointFormContent_lowLimitsGroup__vld59 {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	flex: 1 1;
}

.TelemetryPointFormContent_highLimitsGroup__G4fhK {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	flex: 1 1;
}

.TelemetryPointFormContent_lowLimitsGroup__vld59 > label {
	margin-right: 1rem;
}

.TelemetryPointFormContent_highLimitsGroup__G4fhK > label {
	margin-left: 1rem;
}

.TelemetryPointFormContent_spacer__Vo5fu {
	flex: 3 0;

}

.TelemetryPointFormContent_limitsRow__RBYgv label {
	font-weight: 500;
}

.TelemetryPointFormContent_limitsRow__RBYgv input[type='text'] {
	width: 10rem;
	border-radius: 0rem;
	margin: 1rem 0 2rem 0;
}



.TelemetryPointFormContent_redLabel__BzBD- {
	color: var(--ast-primary-red-color);
}

.TelemetryPointFormContent_yellowLabel__ISkP1 {
	color: var(--ast-primary-orange-1-color);
}

.TelemetryPointFormContent_greenLabel__IgG1e {
	color: #82dd55;
}
`, "",{"version":3,"sources":["webpack://./src/components/tlm-point/point-page/TelemetryPointFormContent.module.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,8BAA8B;CAC9B,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,8BAA8B;CAC9B,uBAAuB;CACvB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,8BAA8B;CAC9B,uBAAuB;CACvB,SAAS;AACV;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,SAAS;;AAEV;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,mBAAmB;CACnB,qBAAqB;AACtB;;;;AAIA;CACC,mCAAmC;AACpC;;AAEA;CACC,wCAAwC;AACzC;;AAEA;CACC,cAAc;AACf","sourcesContent":["@import 'App.module.css';\r\n\r\n.limitsRow {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\tjustify-content: space-between;\r\n\talign-items: flex-start;\r\n}\r\n\r\n.lowLimitsGroup {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\tjustify-content: space-between;\r\n\talign-items: flex-start;\r\n\tflex: 1 1;\r\n}\r\n\r\n.highLimitsGroup {\r\n\tdisplay: flex;\r\n\tflex-flow: row nowrap;\r\n\tjustify-content: space-between;\r\n\talign-items: flex-start;\r\n\tflex: 1 1;\r\n}\r\n\r\n.lowLimitsGroup > label {\r\n\tmargin-right: 1rem;\r\n}\r\n\r\n.highLimitsGroup > label {\r\n\tmargin-left: 1rem;\r\n}\r\n\r\n.spacer {\r\n\tflex: 3 0;\r\n\r\n}\r\n\r\n.limitsRow label {\r\n\tfont-weight: 500;\r\n}\r\n\r\n.limitsRow input[type='text'] {\r\n\twidth: 10rem;\r\n\tborder-radius: 0rem;\r\n\tmargin: 1rem 0 2rem 0;\r\n}\r\n\r\n\r\n\r\n.redLabel {\r\n\tcolor: var(--ast-primary-red-color);\r\n}\r\n\r\n.yellowLabel {\r\n\tcolor: var(--ast-primary-orange-1-color);\r\n}\r\n\r\n.greenLabel {\r\n\tcolor: #82dd55;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limitsRow": `TelemetryPointFormContent_limitsRow__RBYgv`,
	"lowLimitsGroup": `TelemetryPointFormContent_lowLimitsGroup__vld59`,
	"highLimitsGroup": `TelemetryPointFormContent_highLimitsGroup__G4fhK`,
	"spacer": `TelemetryPointFormContent_spacer__Vo5fu`,
	"redLabel": `TelemetryPointFormContent_redLabel__BzBD-`,
	"yellowLabel": `TelemetryPointFormContent_yellowLabel__ISkP1`,
	"greenLabel": `TelemetryPointFormContent_greenLabel__IgG1e`
};
export default ___CSS_LOADER_EXPORT___;
