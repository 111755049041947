import React, { useEffect, useState } from 'react';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'

import 'animate.css';

import style from './LimitOptionsTable.module.css';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import Modal from 'components/ui-core/modal/Modal/Modal';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import { useSCVersioning } from 'data/queryHooks';
import DiscreteWizardTable from '../discreteWizard/DiscreteWizardTable';
import TabCellBoxContainer from 'components/ui-core/table/TabCellBoxContainer';
import { optionComparator } from './OptionHelper';
import Option from './Option';

export const LimitOptionsTable = ({ optionsData, setCreateVisible, setEditOptionValue, underlyingEntity, handleDelete, optionsType}) => {

  const [tableData, setTableData] = useState(optionsData);
  React.useEffect(() => {
    setTableData(optionsData)
	}, [optionsData]);

	const versioning_metadata = useSCVersioning();


const columns = React.useMemo(() => {
  const columns = [
    {
      Header: "Spacecraft Mode",
      accessor: "sc_mode",
    },
  ];

  if (optionsType === "NonDiscrete") {
    columns.push(
      {
        Header: "Red Low",
        accessor: "red_low",
      },
      {
        Header: "Yellow Low",
        accessor: "yellow_low",
      },
      {
        Header: "Red High",
        accessor: "red_high",
      },
      {
        Header: "Yellow High",
        accessor: "yellow_high",
      }
    );
    // Buttons
    columns.push({
      Header: "Action",
      accessor: (optionRow) => {
        return (
          <>
            <div className={style.tableCellContainer}>
              <TableEditButton
                editHandler={(event) => {
                  event.preventDefault();
                  setEditOptionValue(optionRow);
                }}
              />
              <TableDeleteButton
                deletionHandler={(event) => {
                  event.preventDefault();
                  handleDelete(optionRow);
                }}
              />
            </div>
          </>
        );
      },
      columnType: "buttons",
    });
  }

  if (optionsType === "Discrete") {
    columns.push({
      Header: "Discrete",
      accessor: (optionRow) => {
        return (
          <>
            <TabCellBoxContainer>
              {optionRow["discrete_values"] &&
                optionRow["discrete_values"]
                  .map((option) => (
                    <Option key={option.name} option={option} />
                  ))
                  .sort(optionComparator)}
            </TabCellBoxContainer>
          </>
        );
      },
      columnType: "buttons",
    });
    columns.push({
      Header: "Action",
      accessor: (optionRow) => {
        return (
          <>
            <div className={style.tableCellContainer}>
              <TableEditButton
                editHandler={(event) => {
                  event.preventDefault();
                  setEditOptionValue(optionRow);
                }}
              />
              <TableDeleteButton
                deletionHandler={(event) => {
                  event.preventDefault();
                  handleDelete(optionRow);
                }}
              />
            </div>
          </>
        );
      },
      columnType: "buttons",
    });
  }

  return columns;
}, [optionsType, setEditOptionValue, handleDelete]);

	
	return (
		<>	
			<ManagedMsdTable tableName="OptionsTable" columns={columns} data={tableData}>
				{optionsType=="NonDiscrete" && <CreateButton
					additionalStyles={{"marginBottom": "3px"}}
					width="9rem"
					title= {"Threshold"}
					onClick={(event) => {
						event.preventDefault()
						setCreateVisible(true);
					}}
				/>}
        	{optionsType=="Discrete" && <CreateButton
					additionalStyles={{"marginBottom": "3px"}}
					width="9rem"
					title= {"Enums"}
					onClick={(event) => {
						event.preventDefault()
						setCreateVisible(true);
					}}
				/>}			
			</ManagedMsdTable>
		</>
	);
};

export default LimitOptionsTable;
