import React from 'react';
import { useHistory } from 'react-router-dom';

import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'
import { getCommandPacketColumns } from 'components/cmd-packet/table-page/CommandPacketTableHelper';
import { PageState } from 'components/ui-core/page/usePageState';

export const CommandParamUsageTab = ({parentPackets}) => {

	const history = useHistory();
	const viewCommandPacketHandler = (cmdPacket) => {
		history.push(`/command-packet/${cmdPacket._id}`, new PageState("cmd_params_tab"));
	};
	
    const columns = React.useMemo(() => getCommandPacketColumns(viewCommandPacketHandler, "Packet Name"), []);

    
	return (
		<ManagedMsdTable tableName="CommandParamUsageTab" columns={columns} data={parentPackets} />
	);
};

export default CommandParamUsageTab;
