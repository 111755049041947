

class SubystemCspPortController {
    constructor(useFormObj, metadata) {
        this.useFormObj = useFormObj;
        this.metadata = metadata;
    }

    handleSubsystemChanged = (event) => {
        const {setValue} = this.useFormObj;
        
        const meta_sub = this.metadata.subsystems
            .find(sub => sub.subsystem === event.target.value)

        if (meta_sub) {
            setValue("csp_port", meta_sub.subsystem_port, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }
}

export default SubystemCspPortController;