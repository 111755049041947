
export class DragWithinTable {
    constructor(dropRequest, origTableData, setTableData, postDragDataPrepFn, makeDirty) {
        this.dropRequest = dropRequest;
        this.origTableData = origTableData;
        this.setTableData = setTableData;
        this.postDragDataPrepFn = postDragDataPrepFn
        this.makeDirty = makeDirty;
        
        this.nameAttr = dropRequest.nameAttr;

    }

    performDrag() {
        console.log("dragWithinTable", this.dropRequest);
        
        // Find the actual element that we should stack up all the selections behind 
        // (null for start of table)
        const pivotElement = this.#getPivotElementFromDrag(this.dropRequest, this.origTableData);
    
        this.reorderWithinTable(pivotElement) 
    }

    #getPivotElementFromDrag()  {
        const {mousePositionAtDropIndex, dropAtIndex, dragDetails: {selectedTableData}} = this.dropRequest;

        let pivotIndex = dropAtIndex;
        // Adjust the mouse being at the top of the index
        if (mousePositionAtDropIndex === "top-half") {
            pivotIndex--;
        }
    
        if (pivotIndex >= 0) {
            // Search for the first element above this that is NOT in the selection 
            // (that's about to move)
            for (let i = pivotIndex; i >= 0; i--) {
                if (!selectedTableData.find(row => this.origTableData[i][this.nameAttr] === row[this.nameAttr])) {
                    return this.origTableData[i];
                }
            }  
        } else {
            return null;
        }
    }    
    

    reorderWithinTable(pivotElement) {
        const {tableId, dragDetails: {selectedTableData}} = this.dropRequest;
        console.log("reorderWithinTable", tableId);

        // Remove all selected items from the source table
        const newTableData = this.origTableData
            .filter(tableRow => 
                !selectedTableData.find(row => row[this.nameAttr] === tableRow[this.nameAttr]));
        
        // Now find the new index of the pivot element
        let insertAtIndex = 0;
        if (pivotElement) {
            insertAtIndex = newTableData.findIndex(
                element => element[this.nameAttr] === pivotElement[this.nameAttr]) + 1;
        }
        
        this.addSelectionsAtIndex(newTableData, insertAtIndex);
    }

    addSelectionsAtIndex(newTableData, insertAtIndex) {
        const {dragDetails: {selectedTableData}} = this.dropRequest;

        newTableData.splice(insertAtIndex, 0, ...selectedTableData);
        this.postDragDataPrepFn(newTableData);

        if (this.makeDirty) {
            selectedTableData.forEach(row => row["dirty"] = true);
        }

        console.log("New Reordered Data", newTableData);

        // set the new data 
        this.setTableData(newTableData);        
    }

}

