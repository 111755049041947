import React from 'react';
import style from './SaveableTab.module.css';
import formStyle from 'components/ui-core/form/Form.module.css';

import MainButton from 'components/ui-core/buttons/MainButton/MainButton';
import EditModeTransition from 'model/EditModeTransition';
import { useASTData } from "context/ASTContext";
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'

export const SaveableTab = ({editModeActive, editModeTransitionHandler, 
        saveEnabled, createSubmitPromise, saveButtonTitle = "Save", ...props} ) => {
    
    const {setSubmitInProgress} = useASTData();
    
    const submitWithProgress = (event) => {
        console.log("setSubmitInProgress = true");
        setSubmitInProgress(true);
        const submitPromise = createSubmitPromise(event);
        submitPromise
            .then(() => {
                console.log("setSubmitInProgress = false");
                setSubmitInProgress(false);
                editModeTransitionHandler(false)
            })
            .catch(() => {
                setSubmitInProgress(false);
            })
    }
    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(submitWithProgress)

	return ( 
        <div className={style.saveableContainer}>
            <div className={style.saveableContent}>
                {/* Content */}
                {props.children}
            </div>

            {/* Buttons */}
            {editModeActive &&
                <div className={style.saveableButtonsContainer}>
                    <div className={formStyle.formTabButtonsContainer}>
                        <MainButton 
                            title="Cancel" 
                            onClick={() => editModeTransitionHandler(EditModeTransition.Cancel)} 
                        />
                        <MainButton 
                            title={saveButtonTitle} 
                            disabled= {!saveEnabled}
                            onClick={submitWithAppropriateWarnings}
                        />
                    </div>
                </div>
            }
        </div>  
	);
};

export default SaveableTab;
