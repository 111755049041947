// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Option_optionContainer__6EquA {
	display: inline-block;
}

.Option_option__rMKxD, 
.Option_severity__MVytF {
	padding: 2px 8px;
	border: 0.1rem solid var(--ast-primary-orange-1-color);
	border-radius: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/options/Option.module.css"],"names":[],"mappings":"AAEA;CACC,qBAAqB;AACtB;;AAEA;;CAEC,gBAAgB;CAChB,sDAAsD;CACtD,qBAAqB;AACtB","sourcesContent":["@import 'App.module.css';\r\n\r\n.optionContainer {\r\n\tdisplay: inline-block;\r\n}\r\n\r\n.option, \r\n.severity {\r\n\tpadding: 2px 8px;\r\n\tborder: 0.1rem solid var(--ast-primary-orange-1-color);\r\n\tborder-radius: 0.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionContainer": `Option_optionContainer__6EquA`,
	"option": `Option_option__rMKxD`,
	"severity": `Option_severity__MVytF`
};
export default ___CSS_LOADER_EXPORT___;
