import {FormRow, FormCheckmarkRow, Input, TextArea, Select, Checkbox, MultiSelect, ToggleSwitchWrapper, InputSimple} from 'components/ui-core/form/FormControls';
import SubystemCspPortController from 'components/ui-core/common/SubystemCspPortController';
import { getSortedSubsystemValueOptions } from 'components/ui-core/utils/MetadataHelper';
import { isInFswArr } from "components/tags/TagHelper";
import React, { useEffect, useState } from 'react';
import semver from 'semver';
import QuestionIcon from 'img/question-mark-button-svgrepo-com.svg';




export const CommandPacketFormContent = ({controlProps, metadata, useFormObj, isWizard, editModeActive}) => {
	const {watch, getValues, control, setValue} = useFormObj;

	// re-render when type change (conditional fields)
	watch("critical_command");
	const [showDestSemver, setShowDestSemver] = useState(false);
	const destNodeOptions = React.useMemo(() => metadata.nodes.map(nodeEntry => nodeEntry.node), []); 
	const portController = new SubystemCspPortController(useFormObj, metadata);

	const isCriticalCommand = () => getValues("critical_command");
	const isInFSW = () => isInFswArr(getValues("environment_tags"));
	const [semverEntries, setSemverEntries] = useState([]);
	const [warningStack , setWarningStack] = useState([]);
  
	const routeChange = () =>{ 
	  let path = `https://jubianchi.github.io/semver-check/#/`; 
	  window.open(path);
	}

	useEffect(() => {
		const destNodes = getValues("dest_nodes");
		const destSemver = getValues("dest_semver") || "";
		setShowDestSemver(Array.isArray(destNodes) && destNodes.length > 0);
		if (destNodes) {
			let semverLines = destSemver.split("\n");
			let refreshSemverLines = []
			destNodes.map(node => {
                const matchingSemverLine = semverLines.find(semverLine => semverLine.startsWith(node));
				if(matchingSemverLine){
					refreshSemverLines.push(matchingSemverLine);
				}
				else{
					refreshSemverLines.push(node + "*")
				}
                return node;
            });
			
			refreshSemverLines = refreshSemverLines.join("\n");
			semverLines = refreshSemverLines.split("\n");
			setValue("dest_semver", refreshSemverLines, { shouldValidate: true, shouldDirty: true });
			const warnings = [];
			const newSemverEntries = destNodes.map(node => {
                const matchingSemver = semverLines.find(semverLine => semverLine.startsWith(node))?.replace(node, '').trim();
				if (semver.validRange(matchingSemver) == null) {
					warnings.push(`Warning: ${node} - ${matchingSemver} is not a valid semver version.`);
				}
                return { node, semver: matchingSemver };
            });
			setWarningStack(warnings);
            setSemverEntries(newSemverEntries);
		}
    }, [watch("dest_nodes"), watch("dest_semver")]);
	
	const handleSemverChange = (node, newSemver) => {
        const updatedEntries = semverEntries.map(entry =>
            entry.node === node ? { ...entry, semver: newSemver } : entry
        );
        setSemverEntries(updatedEntries);
        const updatedSemverString = updatedEntries.map(entry => `${entry.node}${entry.semver}`).join("\n");
    	setValue("dest_semver", updatedSemverString, { shouldValidate: true, shouldDirty: true });
    };



	return (
		<>			
			<FormRow rowNum="1">
				<Input style={{width: "28rem"}}  title="Command Packet Name" attr="cmd_name" {...controlProps}/>

				<Select title="Subsystem" attr="subsystem_name" {...controlProps}
					valueOptions={getSortedSubsystemValueOptions(metadata)}
					includeBlank={isWizard} onChange={portController.handleSubsystemChanged}/>

				<Input title="CSP Port Number" attr="csp_port" {...controlProps} isRequired={isInFSW}/>
			</FormRow>

			<FormRow rowNum="2">
				<TextArea title="Description" attr="description" {...controlProps}/>
			</FormRow>

			<FormRow rowNum="3">
				<Input title="Command Code" attr="cmd_code" {...controlProps} isRequired={isInFSW}/>

				<MultiSelect title="Destination Nodes" attr="dest_nodes" {...controlProps}
					optionValues={destNodeOptions} control={control}/>
			</FormRow>
			
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			
				{showDestSemver && semverEntries.length > 0 && semverEntries.map((entry, index) => (
					<FormRow rowNum={index + 4} key={entry.node}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<label style={{ marginRight: '10px', backgroundColor: '#D3D3D3', padding: '4px 8px',  borderRadius: '8px' }}>
								{entry.node}
								<InputSimple style={{ marginLeft: '10px', width: '10rem' }} title={entry.node} value={entry.semver}
									onChange={(e) => handleSemverChange(entry.node, e.target.value)} readOnly={!editModeActive}/>
							</label>
						</div>
						
					</FormRow>
				))}
				{showDestSemver && (
				<div>
					<img src={QuestionIcon} alt="Semver Help" style={{ width: "1.5em", marginTop: "2px", marginLeft: "-7px" }} onClick={routeChange}/>
				</div>
				)}
	
			</div>
			{editModeActive && warningStack.length > 0 && (
				<div style={{ marginTop: '20px', color: 'red' }}>
					<h4>Warnings:</h4>
					<ul>
						{warningStack.map((warning, index) => (
							<li key={index}>{warning}</li>
						))}
					</ul>
				</div>
			)}

			<FormCheckmarkRow rowNum="4">
				{/* <Checkbox title="Critical Command" attr="critical_command" {...controlProps} 
					textLabels={{enabled: "Yes", disabled: "No"}}/> */}
				
				<ToggleSwitchWrapper title="Critical Command" attr="critical_command"
                        {...controlProps} isRequired={isCriticalCommand}/>

			</FormCheckmarkRow>
			{getValues("critical_command") &&
				<FormRow rowNum="3">
					<TextArea title="Critical Command Warning" attr="critical_command_warning" 
						{...controlProps} isRequired={isCriticalCommand}/>
				</FormRow>	
			}					
		</>
	);
}

export default CommandPacketFormContent;