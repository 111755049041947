// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SummaryBar_summaryContainer__ZUsJY {
	display: flex;
	flex-flow: row;
	justify-content: right;
}


.SummaryBar_summaryContainer__ZUsJY input {
	margin: 0.3rem 0 0.3rem 0;
}

.SummaryBar_summaryContainer__ZUsJY input {
	border-radius: 0.4rem;
	width: 3rem;
	height: 2rem;
	border: 0.063rem solid #c9c8c8;
	color: #656565;
	font-size: 0.875rem;
	padding-left: 0.5rem;
}


.SummaryBar_summaryContainer__ZUsJY label {
	margin: 0 1rem;
}

`, "",{"version":3,"sources":["webpack://./src/components/ui-core/common/SummaryBar.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,cAAc;CACd,sBAAsB;AACvB;;;AAGA;CACC,yBAAyB;AAC1B;;AAEA;CACC,qBAAqB;CACrB,WAAW;CACX,YAAY;CACZ,8BAA8B;CAC9B,cAAc;CACd,mBAAmB;CACnB,oBAAoB;AACrB;;;AAGA;CACC,cAAc;AACf","sourcesContent":["\r\n.summaryContainer {\r\n\tdisplay: flex;\r\n\tflex-flow: row;\r\n\tjustify-content: right;\r\n}\r\n\r\n\r\n.summaryContainer input {\r\n\tmargin: 0.3rem 0 0.3rem 0;\r\n}\r\n\r\n.summaryContainer input {\r\n\tborder-radius: 0.4rem;\r\n\twidth: 3rem;\r\n\theight: 2rem;\r\n\tborder: 0.063rem solid #c9c8c8;\r\n\tcolor: #656565;\r\n\tfont-size: 0.875rem;\r\n\tpadding-left: 0.5rem;\r\n}\r\n\r\n\r\n.summaryContainer label {\r\n\tmargin: 0 1rem;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryContainer": `SummaryBar_summaryContainer__ZUsJY`
};
export default ___CSS_LOADER_EXPORT___;
