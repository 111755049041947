import React from 'react';

import style from './TableFilters.module.css';
import FilterIcon from 'img/filter-icon.svg';

export const TableFilters = ({ useMsdTableObj: {headerGroups} }) => {

	return (
		<form className={style.filterContainer}>
			<div style={{ marginRight: '1rem'}}  >
				<img src={FilterIcon} className={style.filterImage} alt="FilterIcon" />
			</div>

			{
				headerGroups[0].headers.filter(header => header.Filter).map(header => (
					<div key={header.id}>
						<label>{header.Header}:</label>
						{ header.render('Filter') }
					</div>
				))
			}

		</form>
	);
};

export default TableFilters;
