
import {FormRow, Input, Select} from 'components/ui-core/form/FormControls';

export const BoardFormContent = ({controlProps, metadata}) => {
	const destNodeOptions = metadata.nodes.map(nodeEntry => nodeEntry.node); 
	return (
		<>
			<FormRow rowNum="1">
				<Select title="Name" attr="name" {...controlProps} valueOptions={destNodeOptions}/>
			</FormRow>
			<FormRow rowNum="2">
				<Input title="Version" attr="version" {...controlProps}/>
			</FormRow>
		</>

	);
};

export default BoardFormContent;
