import React from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getBoardSchema} from 'model/form-schema/ObjectSchemas'
import BoardFormContent from './BoardFormContent';

export const EditBoardModal = ({ setEditBoard, editBoard, metadata, userName, dataExtraction }) => {
	const formatted_semver_data = dataExtraction();
	const schema = getBoardSchema(formatted_semver_data, editBoard.name);
	const {useFormObj, controlProps} = useWizardForm(editBoard, schema, 'onChange');

	const createSubmitPromise = async (formData) => {
		
		const semver_data = JSON.parse(localStorage.getItem('board_filter_object'));
		const boardInClone = semver_data.find( ele => ele.board === editBoard.name);
		boardInClone.board = formData.name;
		boardInClone.semver = formData.version;
		localStorage.setItem("board_filter_object", JSON.stringify(semver_data));
		toast.success(`Board Version '${boardInClone.name}' updated`);

		return null;	
	};

	const closeWizard = (wizardVisible) => {
		if (!wizardVisible) {
			setEditBoard(null);
		}
	}

	return (
		<Wizard width='40rem'>
			<WizardForm title="Edit Board Version" 
				setWizardVisible={closeWizard} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
				submitButtonTitle="Edit"
			>
				<BoardFormContent controlProps={controlProps} metadata={metadata}/>
			</WizardForm>
		</Wizard>		
	);
};


export default EditBoardModal;
