import React, { useEffect, useState } from 'react';
import {FormRow, FormCheckmarkRow, Input, TextArea, Select, Checkbox, MultiSelect, QueryControlWrapper, InputSimple} from 'components/ui-core/form/FormControls';
import SubystemCspPortController from 'components/ui-core/common/SubystemCspPortController';
import { getSortedSubsystemValueOptions } from 'components/ui-core/utils/MetadataHelper';
import { isInFswArr } from "components/tags/TagHelper";
import semver from 'semver';

export const TelemetryPacketFormContent = ({controlProps, metadata, allCmdPacketsQuery, useFormObj, isWizard, editModeActive}) => {
	const {watch, getValues, control, setValue} = useFormObj;

	// re-render when response change (conditional fields)
	watch("response");
	const [showSrcSemver, setShowSrcSemver] = useState(false);
	const srcNodeOptions = React.useMemo(() => metadata.nodes.map(nodeEntry => nodeEntry.node), []); 
	const getResponseToOptions = React.useMemo(() => {
		return allCmdPacketsQuery.data.map(cmdPacket => cmdPacket.cmd_name);
	  }, [allCmdPacketsQuery.data]);
	const portController = new SubystemCspPortController(useFormObj, metadata);

	const isInFSW = () => isInFswArr(getValues("environment_tags"));
	const [semverEntries, setSemverEntries] = useState([]);
	const [warningStack , setWarningStack] = useState([]);
	useEffect(() => {
		const srcNodes = getValues("src_nodes");
		const srcSemver = getValues("src_semver") || "";
		setShowSrcSemver(Array.isArray(srcNodes) && srcNodes.length > 0);
		if (srcNodes) {
			let semverLines = srcSemver.split("\n");
			let refreshSemverLines = []
			srcNodes.map(node => {
                const matchingSemverLine = semverLines.find(semverLine => semverLine.startsWith(node));
				if(matchingSemverLine){
					refreshSemverLines.push(matchingSemverLine);
				}
				else{
					refreshSemverLines.push(node + "*")
				}
                return node;
            });
			
			refreshSemverLines = refreshSemverLines.join("\n");
			semverLines = refreshSemverLines.split("\n");
			setValue("src_semver", refreshSemverLines, { shouldValidate: true, shouldDirty: true });
			const warnings = [];
			const newSemverEntries = srcNodes.map(node => {
                const matchingSemver = semverLines.find(semverLine => semverLine.startsWith(node))?.replace(node, '').trim();
				if (semver.validRange(matchingSemver) == null) {
					warnings.push(`Warning: ${node} - ${matchingSemver} is not a valid semver version.`);
				}
                return { node, semver: matchingSemver };
            });
			setWarningStack(warnings);
            setSemverEntries(newSemverEntries);
		}
    }, [watch("src_nodes"), watch("src_semver")]);

	const handleSemverChange = (node, newSemver) => {
        const updatedEntries = semverEntries.map(entry =>
            entry.node === node ? { ...entry, semver: newSemver } : entry
        );
        setSemverEntries(updatedEntries);
        const updatedSemverString = updatedEntries.map(entry => `${entry.node}${entry.semver}`).join("\n");
    	setValue("src_semver", updatedSemverString, { shouldValidate: true, shouldDirty: true });
    };
	return (
		<>
			<FormRow rowNum="1">
				<Input style={{width: "28rem"}} title="Telemetry Packet Name" attr="tlm_packet_name" {...controlProps}/>

				<Select title="Subsystem" attr="subsystem_name" {...controlProps}
					valueOptions={getSortedSubsystemValueOptions(metadata)}
					includeBlank={isWizard} onChange={portController.handleSubsystemChanged}/>

				<Input title="CSP Port Number" attr="csp_port" {...controlProps} isRequired={isInFSW}/>
			</FormRow>

			<FormRow rowNum="2">
				<TextArea title="Description" attr="description" {...controlProps}/>
			</FormRow>

			<FormRow rowNum="3">
				<MultiSelect title="Source Nodes" attr="src_nodes" {...controlProps}
					optionValues={srcNodeOptions} control={control}/>
			</FormRow>
			{/* {showSrcSemver &&
				<FormRow rowNum="3">
					<TextArea title="Semver Versions" attr="src_semver" 
						{...controlProps}/>
				</FormRow>	
			} */}
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			{showSrcSemver && semverEntries.length > 0 && semverEntries.map((entry, index) => (
			<FormRow rowNum={index+4} key={entry.node}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
				<label style={{ marginRight: '10px', backgroundColor: '#D3D3D3', padding: '4px 8px', borderRadius: '8px' }}>
					{entry.node}
					<InputSimple style={{  marginLeft: '10px', width: '10rem' }} title={entry.node} value={entry.semver} 
					onChange={(e) => handleSemverChange(entry.node, e.target.value)}  readOnly={!editModeActive}/>
				</label>
				
					</div>
				</FormRow>
			))}
			</div>
			{editModeActive && warningStack.length > 0 && (
				<div style={{ marginTop: '20px', color: 'red' }}>
					<h4>Warnings:</h4>
					<ul>
						{warningStack.map((warning, index) => (
							<li key={index}>{warning}</li>
						))}
					</ul>
				</div>
			)}
			<FormRow rowNum="4">
				<Input title="Telemetry ID" attr="tlm_id" {...controlProps} isRequired={isInFSW}/>
				<Input title="File ID" attr="file_id" {...controlProps}/>
				<Input title="Update Frequency (Hz)" attr="update_frequency" {...controlProps}/>
			</FormRow>

			<FormCheckmarkRow rowNum="5">
				<Checkbox title="Response Packet" attr="response" {...controlProps}/>
				<Checkbox title="Non-Telemetry" attr="non_telem" {...controlProps}/>
				<Checkbox title="Allow Short" attr="allow_short" {...controlProps}/>
			</FormCheckmarkRow>

			{getValues("response") &&
				<FormRow style={{marginTop: "2rem"}} rowNum="6">
					<QueryControlWrapper
						title="Response To"
						attr="response_to_cmd_pkts"
						schema={controlProps.schema}
						queries={[allCmdPacketsQuery]}
						onLoad={() => (
							<MultiSelect minWidth={"30rem"}
								attr="response_to_cmd_pkts" {...controlProps} control={control} 
								optionValues={getResponseToOptions} />

						)}
					/>
				</FormRow>
			}
		</>
	);
};

export default TelemetryPacketFormContent;
