import React from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getSnapShotSchema} from 'model/form-schema/ObjectSchemas'
import {useUpdateVersionMetadata} from 'data/queryHooks';
import { SnapShotFormContent } from './SnapShotFormContent';
import { useBranchData } from './BranchContext';

export const EditSnapShotModal = ({ setEditSnapShot, editSnapShot, metadata , onEditSave, userName}) => {
    const currentSpacecraft = localStorage.getItem('spacecraft');
	let metadataClone = cloneDeep(metadata)
    metadataClone = metadataClone.find(ele => ele.spacecraft_name === currentSpacecraft);
	const schema = getSnapShotSchema();
	console.log(editSnapShot)
	const {useFormObj, controlProps} = useWizardForm(editSnapShot, schema, 'onChange');
	const {mutateAsync: updateVersionDb}  = useUpdateVersionMetadata();
	const {totalDbList , setTotalDbList} = useBranchData();

	const createSubmitPromise = async (formData) => {
		metadataClone.databases = totalDbList;
		const snapShotInClone = metadataClone.databases.find(row => row.version === editSnapShot.version);
		snapShotInClone.test_status = formData.test_status;
		snapShotInClone.comments = formData.comments;
        snapShotInClone.last_updated_by = userName;
        snapShotInClone.last_updated_date = new Date().toISOString();

		updateVersions(metadataClone);		
	};

	const closeWizard = (wizardVisible) => {
		if (!wizardVisible) {
			setEditSnapShot(null);
		}
	}

    const updateVersions = async (updatedVersion)  => {
		return await updateVersionDb(updatedVersion)
		.then(refreshedVersions => {
            onEditSave(updatedVersion.databases);
			setTotalDbList(updatedVersion.databases)
			toast.success(`'${editSnapShot.version}' updated`);
			return updatedVersion;
		})
		.catch(error => {
			toast.error("Versions update failed");
			return Promise.reject(error)
		});	
	}

	return (
		<Wizard width='40rem'>
			<WizardForm title="Edit SnapShot" 
				setWizardVisible={closeWizard} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
				submitButtonTitle="Edit"
			>
				<SnapShotFormContent controlProps={controlProps} />
			</WizardForm>
		</Wizard>		
	);
};


export default EditSnapShotModal;
