import EntityType, { PACKETS_ONLY } from "model/EntityType";

export const areEntityRequiredFieldsSet = (underlyingEntity, entityType) => {
  if (entityType === EntityType.CmdPacket) {
      return underlyingEntity["csp_port"] != null && underlyingEntity["cmd_code"] != null
  } else if (entityType === EntityType.TlmPacket) {
      return underlyingEntity["csp_port"] != null && underlyingEntity["tlm_id"] != null
  } 

  return true;
}


class ManagedTag {
    // Create new instances of the same class as static attributes
    static PENDING = new ManagedTag("PENDING", 1, [], true, [], [], [], "#FFFFBE") 
    static PAYLOAD = new ManagedTag("PAYLOAD", 6, PACKETS_ONLY, true, [], [], [], "#AAF9FF") 
    static DELETED = new ManagedTag("DELETED", 2, [], true, [], [], [], "#F8CECC")
    static FSW = new ManagedTag("FSW", 3, PACKETS_ONLY, true, [], ["STAGED_FOR_FSW_PROD"], [areEntityRequiredFieldsSet],  "#D5E8D4")
    static FSW_PROD = new ManagedTag("FSW_PROD", 4, [], false, [], [], [], "#BAC8D3")
    static STAGED_FOR_FSW_PROD = new ManagedTag("STAGED_FOR_FSW_PROD", 5, PACKETS_ONLY, true, ["FSW"], [], [], "#E1D5E7")
  
    constructor(name, workflowOrder, addableTo, canUserRemove, tagsReqToAdd, tagsInbitingRemove, entityCheckOnAddFns, color) {
      this.name = name;
      this.workflowOrder = workflowOrder;
      this.canUserAdd = addableTo.length > 0;
      this.addableTo = addableTo;
      this.canUserRemove = canUserRemove;
      this.tagsReqToAdd = tagsReqToAdd;
      this.tagsInbitingRemove = tagsInbitingRemove;
      this.entityCheckOnAddFns = entityCheckOnAddFns;
      this.color = color;
    }
}


const FSW_DEV_TAGS = [
  ManagedTag.PENDING, 
  ManagedTag.DELETED, 
  ManagedTag.FSW, 
  ManagedTag.STAGED_FOR_FSW_PROD,
  ManagedTag.FSW_PROD,
  ManagedTag.PAYLOAD
]

const FSW_PROD_TAGS = [
  ManagedTag.PENDING, 
  ManagedTag.FSW_PROD,
  ManagedTag.PAYLOAD

]

//const currentSpacecraft = localStorage.getItem('spacecraft');
const currentDatabase = localStorage.getItem('database');

const FSW_DEV_TAG_NAMES = FSW_DEV_TAGS.map(tag => tag.name)
const FSW_PROD_TAG_NAMES = FSW_PROD_TAGS.map(tag => tag.name)

export const getTagByName = (name) => {
  return getManagedTags().find(tag => tag.name === name);
}

//Allow Snapshot to have DEV tags visible to download cmd & tlm files
export const getManagedTags = () => {
  if (currentDatabase.includes('_DEV')) {
    return FSW_DEV_TAGS
  } else {
    return FSW_PROD_TAGS
  }
} 

//Allow Snapshot to have DEV tags visible 
export const getManagedTagNames = () => {
  if (currentDatabase.includes('_DEV')) {
    return FSW_DEV_TAG_NAMES
  } else {
    return FSW_PROD_TAG_NAMES
  }
} 

export const getUserAddableManagedTags = () => {
  return getManagedTags().filter(tag => tag.canUserAdd)
}

export const getUserRemovableManagedTags = () => {
  return getManagedTags().filter(tag => tag.canUserRemove)
}

export default ManagedTag;