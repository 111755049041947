import { getPendingBaseColumns } from 'components/tlm-packet/table-page/TelemetryPacketTableHelper'; 
import EntityType from 'model/EntityType';

import useReviewPacketsTable from 'components/review/useReviewPacketsTable';
import { getManagedTags } from 'model/ManagedTag';

export const ReviewTelemetryTab = ({pendingPackets, viewHandler, submitUpdateTagRequest}) => {

	const allManagedTags = getManagedTags();

	const tlmTableJsx = useReviewPacketsTable({
		tableName: "ReviewTelemetryTab",
		baseColumns: getPendingBaseColumns(), 
		packets: pendingPackets,  
		viewHandler: viewHandler,  
		entityType: EntityType.TlmPacket,  
		submitUpdateTagRequest,
		pageAllowedAdditions: allManagedTags,
		pageAllowedRemovals: allManagedTags
	});

	return tlmTableJsx;
};

export default ReviewTelemetryTab;