export const getUnitEntryOptions = (metadata) => {
    return metadata.units.map(unitsEntry => ({
        key: unitsEntry.units_short, 
        value: unitsEntry.units_long
    })).sort(); 
}

export const lookupLongUnitValue = (metadata, shortValue) => {
    if (!shortValue) {
        return null;
    }

    const unitEntry = metadata.units.find(unit => unit.units_short === shortValue);
    if (unitEntry) {
        return unitEntry.units_long;
    }
    return null;
}

export const getTlmPointApplicableTypes = () => {
    return ["float", "unsigned", "signed", "block", "string", "bitwise", "derived", "block_array", "var_block_array", "var_block"].sort();
}

export const getLimitsApplicableTypes = () => {
    return ["float", "derived"].sort();
}

export const getCmdParamApplicableTypes = () => {
    return ["float", "unsigned", "signed", "block", "string", "block_array"].sort();
}

export const doesTypeSupportTlmLimits = (type) => {
    return getLimitsApplicableTypes().includes(type);
}

export const getBlockArrayTypeOptions = () => {
    return ["float", "unsigned", "signed"].sort();
}

export const getDerivedTypeOptions = () => {
    return ["float", "unsigned", "signed", "block", "string", "block_array"].sort();
}

export const getAlgorithimTypeOptions = () => {
    return ["EVAL", "EXEC"].sort();
}

export const getSortedSubsystemValueOptions = (metadata) => {
    return metadata.subsystems.map(subsystem => subsystem.subsystem).sort();
}

export const getSnapShotTestStatusOptions = () => {
    return ["New","InProgress", "Successfull", "Failed"];
}
