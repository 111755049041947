import useAxios from "./useAxios";
import { toast } from 'react-toastify';

import { useASTData } from "context/ASTContext";
import { useHistory } from 'react-router-dom';



const baseURL = process.env.REACT_APP_API;




export const useDataService = () => {
    const api = useAxios(baseURL);
    const {setSubmitInProgress} = useASTData();

    const currentSpacecraft = localStorage.getItem('spacecraft');
    const currentDatabase = localStorage.getItem("database");

    const history = useHistory();






    const logApiError = (error) => {
        console.log('Error from MSD Backend API!')
        if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
           
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
    };

    const getErrorMessageFromDataDetails = (error) => {
        let details = error.response.data.detail;

        if (details) {
            console.log(details);

            if (details instanceof Array) {
                let message = "";
                details.forEach(element => {
                    console.log(`element: ${element}`)
                    if (element.loc && element.msg && element.loc instanceof Array) {
                        message += `'${element.loc[1]}' ${element.msg}. `
                    }
                });

                return message;
            } else if (typeof details === 'string') {
                return details;
            }
        }


        return null;
    }

    const notifyError = (error) => {
        let message;


        if (!error.response) {
            message = error.message ? error.message : "Network Error";
            history.push(`/login`)
        }
        else if (getErrorMessageFromDataDetails(error)) {
            message = getErrorMessageFromDataDetails(error)
            if (message.includes("Command Parameter")){
                history.push(`/command-parameters`);
            }
            else if(message.includes("Command Packet")){
                history.push(`/`)
            }
            else if(message.includes("Telemetry Packet")){
                history.push(`/telemetry-packets`)
            }
            else if (message.includes("Telemetry Point")){
                history.push(`/telemetry-points`)
            }
            // else if (message.includes("Not verified")){
            //     history.push(`/login`)
            // }
          
           
            

        } 
        else if (typeof error.response.data === 'string') { 
            message = error.response.data;
        } 
        else if (error.message) { 
            const detail = error.response.data?.detail;
            message = "Server error: " +  error.message +  (detail ? ". Detail: " + detail : "");
            
        } else if (error.response.status === 401) {
            message = error.message ? error.message : "Permission Denied";
        } 
        
        if (!message) { 
            message = "Unknown error from server";
        }
    
        toast.error(message, '', 8000);
    }
    
    
    // Augments the passed axios promise with error handling 
    const makeApiCall = (apiPromise) => {
        return apiPromise.catch((error) => {
            logApiError(error);
            notifyError(error);            
            return Promise.reject(error)
        });
    };

    //******************************* Command Packets ********************************* */
    const getCommandPackets = (skip, limit) => {
       return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/cmd_packets?skip=${skip}&limit=${limit}`)) 
    }

    const getCommandPacket = (id) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/cmd_packets/${id}`)) 
    }
 
    const createCommandPacket = (commandPacket) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/cmd_packets`, commandPacket));
    }

    const updateCommandPacket = (commandPacket) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/cmd_packets/${commandPacket._id}`, commandPacket));
    }

    const fetchCommandPacketBoardFilter = (boardFilter, filterToggle=true) => {
        const encodedFilterObject = encodeURIComponent(JSON.stringify(boardFilter));
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/cmd_packets_board_filtering?semver_list=${encodedFilterObject}&filter_flag=${filterToggle}`));
    }

    const logicallyDeleteCommandPacket = (packetId) => {
        return makeApiCall(api.delete(`/${currentSpacecraft}/${currentDatabase}/cmd_packets/logically_delete/${packetId}`));
    }

    //******************************* Command Params ********************************* */
    const getCommandParams = (skip, limit) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/cmd_param?skip=${skip}&limit=${limit}`)) 
    }

    const getCommandParam = (id) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/cmd_param/${id}`)) 
    }

    const createCommandParam = (commandParam) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/cmd_param`, commandParam));
    }

    const updateCommandParam = (commandParam) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/cmd_param/${commandParam._id}`, commandParam));
    }

    const logicallyDeleteCommandParam = (paramId) => {
        return makeApiCall(api.delete(`/${currentSpacecraft}/${currentDatabase}/cmd_param/logically_delete/${paramId}`));
    }

    //******************************* Telemetry Packets ********************************* */
    const getTelemetryPackets = (skip, limit) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_packets?skip=${skip}&limit=${limit}`)) 
    }

    const getTelemetryPacket = (id) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_packets/${id}`)) 
    }

    const createTelemetryPacket = (telemetryPacket) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/tlm_packets`, telemetryPacket))
    }

    const updateTelemetryPacket = (telemetryPacket) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/tlm_packets/${telemetryPacket._id}`, telemetryPacket));
    }

    const fetchTelemetryPacketBoardFilter = (boardFilter, filterToggle=true) => {
        const encodedFilterObject = encodeURIComponent(JSON.stringify(boardFilter));
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_packets_board_filtering?semver_list=${encodedFilterObject}&filter_flag=${filterToggle}`));
    }

    const logicallyDeleteTelemetryPacket = (packetId) => {
        return makeApiCall(api.delete(`/${currentSpacecraft}/${currentDatabase}/tlm_packets/logically_delete/${packetId}`));
    }

    //******************************* Telemetry Points ********************************* */
    const getTelemetryPoints = (skip, limit) => {
        //return makeApiCall(api.get(`/tlm_points?skip=${0}&limit=${limit}`)) 

        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_points?skip=${0}&limit=${limit}`)) 
        //spacecraft=BW3&version=FLIGHT_STAGING
    }

    const getTelemetryPointsOptimized = (skip, limit) => {
        //return makeApiCall(api.get(`/tlm_points?skip=${0}&limit=${limit}`)) 

        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_points-optimized?skip=${0}&limit=${limit}`)) 
        //spacecraft=BW3&version=FLIGHT_STAGING
    }

    const getTelemetryPoint = (id) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_points/${id}`)) 
    }

    const getTelemetryPointFromName = (pointName) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_points/name/${pointName}`)) 
    }

    const createTelemetryPoint = (telemetryPoint) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/tlm_points`, telemetryPoint))
    }

    const updateTelemetryPoint = (telemetryPoint) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/tlm_points/${telemetryPoint._id}`, telemetryPoint));
    }

    const logicallyDeleteTelemetryPoint = (pointId) => {
        return makeApiCall(api.delete(`/${currentSpacecraft}/${currentDatabase}/tlm_points/logically_delete/${pointId}`));
    }

    //******************************* Bitwise Points ********************************* */
    const getAllBitwise = (skip, limit) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_bitwise?skip=${skip}&limit=${limit}`)) 
    }

    const getBitwiseByTlmPointName = async (parentPointName) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_bitwise/parent-name/${parentPointName}`))
    }

    const updateTlmBitwise = (request) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/tlm_bitwise/parent-name/${request.tlmPointName}`, request));
    }

    //******************************* Versioning ********************************* */
    const getVersioningMetadata = () =>{
        return makeApiCall(api.get(`/version-metadata`));

    }

    const updateDevVersion = () => {
        return makeApiCall(api.put(`/add-dev-version?spacecraft=${currentSpacecraft}`));
    }

    const deleteDevVersion = async (snapshot) => {
        console.log(snapshot);
        return makeApiCall(api.delete(`del-snapshot-version?spacecraft=${currentSpacecraft}&snapshot=${snapshot}`));
    }

    const updateVersionMetadata = (database) => {
        console.log(database.spacecraft_name, database.databases);
        return makeApiCall(api.put(`/${database.spacecraft_name}/update-databases`, database.databases));
    }

    const compareMsdVersions = async (spacecraft_a, version_a, spacecraft_b, version_b) => {
        return downloadCompareResultFile(`compare-msd-versions/${spacecraft_a}/${version_a}/${spacecraft_b}/${version_b}` , `${spacecraft_a}_${version_a}_${spacecraft_b}_${version_b}.xlsx`);
    }

    //******************************* Alerts ************************************* */
    const getTlmPointsLite = () => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/tlm_points_lite`)) 
    }

    //******************************* Alerts ************************************* */

    const getAlerts = (skip, limit) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/limits?skip=${skip}&limit=${limit}`)) 
    }

    const getAlertById = (id) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/limits/${id}`)) 
    }

    const updateAlert = (limit) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/limits/${limit._id}`, limit));
    }

    const deleteAlertByName = (alertName) => {
        return makeApiCall(api.delete(`/${currentSpacecraft}/${currentDatabase}/limits/name/${alertName}`));
    }

    const createAlert = (limit) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/limits`, limit));
    }
    //******************************* Metadata ********************************* */
    const getMetadata = () => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/metadata`));
    }

    const updateMetadata = (metadata) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/metadata`, metadata));
    }

    //************************ Tags ********************************* */
    const getTags = () => {
        return makeApiCall(api.get(`/${currentSpacecraft}/${currentDatabase}/environment_tags`)) 
    }

    const createTag = (tag) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/environment_tags`, tag))
    }

    const updateTag = (tag) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/${currentDatabase}/environment_tags/${tag._id}`, tag));
    }

    const tagObjects = (tagRequest) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/environment_tags/tag_objects`, tagRequest));
    }
    
    //*************************   Push To Prod  **************************************/
    const getPushJobs = () => {
        return makeApiCall(api.get(`/${currentSpacecraft}/push_job`)) 
    }

    const getPushJob = (id) => {
        return makeApiCall(api.get(`/${currentSpacecraft}/push_job/${id}`)) 
    }

    const pushToProd = () => {
        return makeApiCall(api.post(`/${currentSpacecraft}/push_to_prod`));
    }

    const cancelPushJob = (id) => {
        return makeApiCall(api.put(`/${currentSpacecraft}/cancel_push/${id}`));
    }

    

    //*************************   Other ******************************************/
    const exportTagToS3 = (tagName, semverList ) => {
        return makeApiCall(api.post(`/${currentSpacecraft}/${currentDatabase}/update_s3/${tagName}`, semverList));
    }


    const downloadCosmosFile = (endpoint, baseFileName, tagName) => {
        setSubmitInProgress(true);

        return makeApiCall(
            api.get(endpoint, {
                responseType: 'blob'
            })
            .then (response => {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameStartIndex = contentDisposition.indexOf('filename="') + 'filename="'.length;
                if (!fileNameStartIndex) {
                    throw new Error("Filename not given in response")
                }

                const fileNameEndIndex = contentDisposition.indexOf('"', fileNameStartIndex + 1);
                const fileName = contentDisposition.substring(fileNameStartIndex, fileNameEndIndex)
                setSubmitInProgress(false);

                // We have the file data - use the browsers built-in functionality to convert
                // it to a file
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                toast.error(`Error downloading COSMOS ${baseFileName} for environment ${tagName}. Error: ${err.message}`);
                setSubmitInProgress(false);
            })
        );        
    }

    const downloadCompareResultFile = (endpoint, baseFileName) => {
        setSubmitInProgress(true);

        return makeApiCall(
            api.get(endpoint, {
                responseType: 'blob'
            })
            .then (response => {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameStartIndex = contentDisposition.indexOf('filename="') + 'filename="'.length;
                if (!fileNameStartIndex) {
                    throw new Error("Filename not given in response")
                }

                const fileNameEndIndex = contentDisposition.indexOf('"', fileNameStartIndex + 1);
                const fileName = contentDisposition.substring(fileNameStartIndex, fileNameEndIndex)
                setSubmitInProgress(false);

                // We have the file data - use the browsers built-in functionality to convert
                // it to a file
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                toast.error(`Error downloading Compare Result ${baseFileName}. Error: ${err.message}`);
                setSubmitInProgress(false);
            })
        );        
    }

    const downloadCosmosCmd = (tagName) => {
        return downloadCosmosFile(`/${currentSpacecraft}/${currentDatabase}/download_cmd_cosmos_file/${tagName}`, 'cmd.txt', tagName);
    }

    const downloadCosmosTlm = (tagName) => {
        return downloadCosmosFile(`/${currentSpacecraft}/${currentDatabase}/download_tlm_cosmos_file/${tagName}`, 'tlm.txt', tagName);
    }

    const downloadCosmosPocmos = (tagName) => {
        return downloadCosmosFile(`/${currentSpacecraft}/${currentDatabase}/download_pocmos_cmd_cosmos_file/${tagName}`, 'pocmos.txt', tagName);
    }

   
    const deleteObjectById = async (type, deleteDocumentId) => {
        return makeApiCall(api.delete(`/${currentSpacecraft}/${currentDatabase}/${type}/${deleteDocumentId}`));
    }    

    return { 
        //Metadata
            //GET:
                getMetadata,
            //PUT:
                updateMetadata,
        //Versioning
            //GET
            getVersioningMetadata,
            compareMsdVersions,
            //PUT
                updateDevVersion,
            //DEL
                deleteDevVersion,
            //PUT
            updateVersionMetadata,
        //Cmd-Packet:
            //GET:
                getCommandPackets,
                getCommandPacket,
            //POST:
                createCommandPacket,
                fetchCommandPacketBoardFilter,
            //PUT:
                logicallyDeleteCommandPacket,
                updateCommandPacket,

        //Cmd-Params:
            //GET:
                getCommandParams,
                getCommandParam,
            //POST:
                createCommandParam,
                fetchTelemetryPacketBoardFilter,
            //PUT:
                logicallyDeleteCommandParam,
                updateCommandParam,

        //Tlm-Packet:
            //GET:
                getTelemetryPackets,
                getTelemetryPacket,
            //POST:
                createTelemetryPacket,
            //PUT:
                logicallyDeleteTelemetryPacket,
                updateTelemetryPacket,

        //Tlm-Points:
            //GET:
                getTelemetryPoints,
                getTelemetryPointsOptimized,
                getTelemetryPoint,
                getTelemetryPointFromName,
            //POST:
                createTelemetryPoint,
            //PUT:
                logicallyDeleteTelemetryPoint,
                updateTelemetryPoint,

        //Bitwise:
            //GET:
                getAllBitwise,
                getBitwiseByTlmPointName,
            //PUT:
                updateTlmBitwise,

        //Env Tags:
            //GET:
                getTags,
            //POST:
                createTag,
            //PUT:
                updateTag,
                tagObjects,

        // Push to Proc
            //GET
                getPushJobs,
                getPushJob,
            //POST
                pushToProd,
            //PUT:
                cancelPushJob,
        // Alerts
            //GET
                getAlerts,
                getAlertById,
            //PUT
                updateAlert,
            //DEL
                deleteAlertByName,
            //POST
                createAlert,
        //Tlm Points Lite
            //GET
            getTlmPointsLite,
        //Other:
        deleteObjectById,
        exportTagToS3,
        downloadCosmosCmd,
        downloadCosmosTlm,
        downloadCosmosPocmos,
        makeApiCall        
    };
}


export default useDataService;

