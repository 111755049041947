
import { useQuery, useMutation, useQueryClient} from 'react-query';
import useDataService from 'data/useDataService';
import EntityType, { getEntityTypeByCode } from 'model/EntityType';

const MAX_RETRIEVED=100000;

// Command Packets
export const useCommandPackets = (queryOptions = {})  => {
    const {getCommandPackets} = useDataService();

    return useQuery(
        ["cmdPackets"], 
        () => getCommandPackets(0, MAX_RETRIEVED).then(res => res.data), 
        queryOptions
    );
}

export const useCommandPacket = (id)  => {
    const {getCommandPacket} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["cmdPackets", id], 
        () => getCommandPacket(id).then(res => res.data), {
            initialData: () => {
                return queryClient.getQueryData(["cmdPackets"])?.find(pkt => pkt._id === id);
            }
        }
    );
}

export const useCreateCommandPacket = () => {
    const {createCommandPacket} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (newPacket) => createCommandPacket(newPacket).then(res => res.data), {
            onSuccess: (newPacket) => {                
                queryClient.setQueryData(['cmdPackets', newPacket._id], newPacket);

                queryClient.setQueryData(['cmdPackets'], (oldPackets = []) => {
                    return [...oldPackets, newPacket];
                });
            },
            onError: (error) => {
                console.error("Failed to create command packet:", error);
            },

        });
}

export const useSaveCommandPacket = () => {
    const {updateCommandPacket} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (updatedPacket) => updateCommandPacket(updatedPacket).then(res => res.data), {
            onSuccess: (updatedPacket) => {
                updateQueriesForCmdPacketUpdate(updatedPacket, queryClient);
            }
        });
}


export const useGetCommandPacketBoardFilter = (boardFilter, filterFlag)  => {
    const {fetchCommandPacketBoardFilter} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["cmdPackets", boardFilter, filterFlag], 
        () => fetchCommandPacketBoardFilter(boardFilter, filterFlag).then(res => res.data), {
            initialData: () => {
                return queryClient.getQueryData(["cmdPackets"]);
            }
        }
    );
}

export const useLogicallyDeleteCommandPacket = () => {
    const {logicallyDeleteCommandPacket} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (packetId) => logicallyDeleteCommandPacket(packetId).then(res => res.data), {
            onSuccess: (updatedPacket) => {
                updateQueriesForCmdPacketUpdate(updatedPacket, queryClient);
            }
        });
}

const updateQueriesForCmdPacketUpdate = (updatedPacket, queryClient) => {
    queryClient.setQueryData(['cmdPackets', updatedPacket._id], updatedPacket);

    queryClient.setQueryData(['cmdPackets'], oldPackets => {
        return oldPackets?.map(packet => {
            if (packet._id === updatedPacket._id) {
                return updatedPacket;
            }
            return packet;
        });
    });
}

export const usePhysicallyDeleteCommandPacket = () => {
    const {deleteObjectById} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (packetId) => deleteObjectById("cmd_packets", packetId), {
            onSuccess: (response, packetId) => {                            
                queryClient.setQueryData(['cmdPackets'], oldPackets => {
                    return oldPackets.filter(packet => packet._id !== packetId);
                });
            }
        });
}


export const useCommandParams = ()  => {
    const {getCommandParams} = useDataService();

    return useQuery(
        ["cmdParams"], 
        () => getCommandParams(0, MAX_RETRIEVED).then(res => res.data)
    );
}

export const useCommandParam = (id)  => {
    const {getCommandParam} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["cmdParams", id], 
        () => getCommandParam(id).then(res => res.data), {
            initialData: () => {
                return queryClient.getQueryData(["cmdParams"])?.find(param => param._id === id);
            }
        }
    );    
}

export const useCreateCommandParam = () => {
    const {createCommandParam} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (newParam) => createCommandParam(newParam).then(res => res.data), {
            onSuccess: (newParam) => {                
                queryClient.setQueryData(['cmdParams', newParam._id], newParam);

                queryClient.setQueryData(['cmdParams'], oldParams => {
                    return [...oldParams, newParam];
                });
            }
        });
}

export const useSaveCommandParam = () => {
    const {updateCommandParam} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (paramToUpdate) => updateCommandParam(paramToUpdate).then(res => res.data), {
            onSuccess: (updatedParam) => {
                updateQueriesForCmdParamUpdate(updatedParam, queryClient);
            }
        });
}

export const useLogicallyDeleteCommandParam = () => {
    const {logicallyDeleteCommandParam} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (paramId) => logicallyDeleteCommandParam(paramId).then(res => res.data), {
            onSuccess: (updatedParam) => {
                updateQueriesForCmdParamUpdate(updatedParam, queryClient);
            }
        });
}

const updateQueriesForCmdParamUpdate = (updatedParam, queryClient) => {
    queryClient.setQueryData(['cmdParams', updatedParam._id], updatedParam);
    queryClient.refetchQueries(['cmdParams', updatedParam._id]);

    queryClient.setQueryData(['cmdParams'], oldParams => {
        return oldParams.map(param => {
            if (param._id === updatedParam._id) {
                return updatedParam;
            }
            return param;
        });
    });
}


export const usePhysicallyDeleteCommandParam = () => {
    const {deleteObjectById} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (paramId) => deleteObjectById("cmd_param", paramId), {
            onSuccess: (response, paramId) => {                                
                queryClient.setQueryData(['cmdParams'], oldParams => {
                    return oldParams.filter(param => param._id !== paramId);
                });
            }
        });
}

//Telemetry Packets
export const useTelemetryPackets = (queryOptions = {})  => {
    const {getTelemetryPackets} = useDataService();

    return useQuery(
        ["tlmPackets"], 
        () => getTelemetryPackets(0, MAX_RETRIEVED).then(res => res.data), 
        queryOptions
    );
}

export const useTelemetryPacket = (id)  => {
    const {getTelemetryPacket} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["tlmPackets", id], 
        () => getTelemetryPacket(id).then(res => res.data), {
            initialData: () => {
                return queryClient.getQueryData(["tlmPackets"])?.find(pkt => pkt._id === id);
            }
        }
    );
}

export const useCreateTelemetryPacket = () => {
    const {createTelemetryPacket} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (newPacket) => createTelemetryPacket(newPacket).then(res => res.data), {
            onSuccess: (newPacket) => {                
                queryClient.setQueryData(['tlmPackets', newPacket._id], newPacket);

                queryClient.setQueryData(['tlmPackets'], (oldPackets = []) => {
                    return [...oldPackets, newPacket];
                });
            },
            onError: (error) => {
                console.error("Failed to create telemetry packet:", error);
            },
        });
}

export const useSaveTelemetryPacket = () => {
    const {updateTelemetryPacket} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (updatedPacket) => updateTelemetryPacket(updatedPacket).then(res => res.data), {
            onSuccess: (updatedPacket) => {
                updateQueriesForTlmPacketUpdate(updatedPacket, queryClient);
            }
        });
}

export const useGetTelemetryPacketBoardFilter = (boardFilter, filterToggle)  => {
    const {fetchTelemetryPacketBoardFilter} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["tlmPackets", boardFilter, filterToggle], 
        () => fetchTelemetryPacketBoardFilter(boardFilter, filterToggle).then(res => res.data), {
            initialData: () => {
                return queryClient.getQueryData(["tlmPackets"]);
            }
        }
    );
}

export const useLogicallyDeleteTelemetryPacket = () => {
    const {logicallyDeleteTelemetryPacket} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (packetId) => logicallyDeleteTelemetryPacket(packetId).then(res => res.data), {
            onSuccess: (updatedPacket) => {
                updateQueriesForTlmPacketUpdate(updatedPacket, queryClient);
            }
        });
}

const updateQueriesForTlmPacketUpdate = (updatedPacket, queryClient) => {
    queryClient.setQueryData(['tlmPackets', updatedPacket._id], updatedPacket);

    queryClient.setQueryData(['tlmPackets'], oldPackets => {
        return oldPackets?.map(packet => {
            if (packet._id === updatedPacket._id) {
                return updatedPacket;
            }
            return packet;
        });
    });
}

export const usePhysicallyDeleteTelemetryPacket = () => {
    const {deleteObjectById} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (packetId) => deleteObjectById("tlm_packets", packetId), {
            onSuccess: (response, packetId) => {                            
                queryClient.setQueryData(['tlmPackets'], oldPackets => {
                    return oldPackets.filter(packet => packet._id !== packetId);
                });
            }
        });
}

// Telemetry Points
const repairTlmPoint = (tlmPointData) => {
    if (Array.isArray(tlmPointData)) {
        return tlmPointData.map(tlmPoint => repairTlmPoint(tlmPoint));
    }

    if (tlmPointData.type === "bitwise" && tlmPointData["bitwise_list"] == null) {
        tlmPointData["bitwise_list"] = [];
    } 

    return tlmPointData;
}

export const useTelemetryPoints = ()  => {
    const {getTelemetryPoints} = useDataService();

    //const [context, setContext] = useContext(SpaceCraftContext);
    //setContext("BW3")
    //const [context, setContext] = useContext(SpaceCraftContext);

    const  context="BW3"
    return useQuery(
        ["tlmPoints"], 
        () => getTelemetryPoints(0, MAX_RETRIEVED, context).then(res => repairTlmPoint(res.data))
    );
}

export const useTelemetryPointsOptimized = ()  => {
    const {getTelemetryPointsOptimized} = useDataService();

    return useQuery(
        ["tlmPoints"], 
        () => getTelemetryPointsOptimized(0, MAX_RETRIEVED).then(res => repairTlmPoint(res.data))
    );
}


export const useTelemetryPoint = (id)  => {
    const {getTelemetryPoint} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["tlmPoints", id], 
        () => getTelemetryPoint(id).then(res => repairTlmPoint(res.data)), {
            initialData: () => {
                return queryClient.getQueryData(["tlmPoints"])?.find(point => point._id === id);
            }
        }
    );    
}


export const useCreateTelemetryPoint = () => {
    const {createTelemetryPoint} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (newPoint) => createTelemetryPoint(newPoint).then(res => res.data), {
            onSuccess: (newPoint) => {                
                queryClient.setQueryData(['tlmPoints', newPoint._id], newPoint);

                queryClient.setQueryData(['tlmPoints'], oldPoints => {
                    return [...oldPoints, newPoint];
                });
            }
        });
}

const updateQueriesForTlmPointUpdate = (updatedTlmPoint, queryClient) => {

    queryClient.setQueryData(['tlmPoints', updatedTlmPoint._id], updatedTlmPoint);

    queryClient.setQueryData(['tlmPoints'], oldPoints => {
        return oldPoints?.map(point => {
            if (point._id === updatedTlmPoint._id) {
                return updatedTlmPoint;
            }
            return point;
        });
    });

    if (updatedTlmPoint.type === "bitwise") {
        queryClient.invalidateQueries(["allBitwise"]);
        queryClient.invalidateQueries(['bitwisePointsByTlmName', updatedTlmPoint.tlm_point_name]);
    }
}

export const useSaveTelemetryPoint = () => {
    const {updateTelemetryPoint} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (updatedPoint) => updateTelemetryPoint(updatedPoint).then(res => res.data), {
            onSuccess: (updatedTlmPoint) => {
                updateQueriesForTlmPointUpdate(updatedTlmPoint, queryClient);
            }
        });
}

export const useLogicallyDeleteTelemetryPoint = () => {
    const {logicallyDeleteTelemetryPoint} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (pointId) => logicallyDeleteTelemetryPoint(pointId).then(res => res.data), {
            onSuccess: (updatedTlmPoint) => {
                updateQueriesForTlmPointUpdate(updatedTlmPoint, queryClient);
            }
        });
}

export const usePhysicallyDeleteTelemetryPoint = () => {
    const {deleteObjectById} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (pointId) => deleteObjectById("tlm_points", pointId), {
            onSuccess: (response, pointId) => {                            
                queryClient.setQueryData(['tlmPoints'], oldPoints => {
                    return oldPoints.filter(point => point._id !== pointId);
                });
            }
        });
}

// Bitwise
export const useBitwise = ()  => {
    const {getAllBitwise} = useDataService();

    return useQuery(
        ["allBitwise"], 
        () => getAllBitwise(0, MAX_RETRIEVED).then(res => res.data)
    );
}


export const useTlmPointWithBitwise = (tlmPointId)  => {
    const {getTelemetryPoint, getBitwiseByTlmPointName} = useDataService();

    const pointByIdQuery = useQuery(
        ["tlmPoints", tlmPointId], 
        () => getTelemetryPoint(tlmPointId).then((res) => repairTlmPoint(res.data))
    );

    const tlmPointName = pointByIdQuery.data?.tlm_point_name;
    const type = pointByIdQuery.data?.type;

    const bitwiseForPointQuery = useQuery(
        ["bitwisePointsByTlmName", tlmPointName], 
        () => getBitwiseByTlmPointName(tlmPointName).then(res => res.data),
        {
            enabled: !!tlmPointName && type ==="bitwise"
        }
    );

    return [pointByIdQuery, bitwiseForPointQuery];
}

export const useSaveTlmBitwise = () => {
    const {updateTlmBitwise} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (request) => updateTlmBitwise(request).then(res => res.data), {
            onSuccess: ({tlm_point, bitwise_point_list}) => {
                updateQueriesForTlmPointUpdate(tlm_point, queryClient);

                queryClient.setQueryData(['bitwisePointsByTlmName', tlm_point.tlm_point_name], bitwise_point_list);
                queryClient.invalidateQueries(["allBitwise"]);

            }
        });
}


export const useTags = ()  => {
    const {getTags} = useDataService();

    return useQuery(
        ["tags"], 
        () => getTags().then(res => res.data)
    );
}


export const useCreateTag = () => {
    const {createTag} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (newTag) => createTag(newTag).then(res => res.data), {
            onSuccess: (newTag) => {                
                queryClient.setQueryData(['tags'], oldTags => {
                    return [...oldTags, newTag];
                });
            }
        });
}

export const useSaveTag = () => {
    const {updateTag} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (updatedTag) => updateTag(updatedTag).then(res => res.data), {
            onSuccess: (updatedTag) => {
                queryClient.setQueryData(['tags'], oldTags => {
                    const updatedTags = oldTags.map(tag => {
                        if (tag._id === updatedTag._id) {
                            return updatedTag;
                        }
                        return tag;
                    });

                    return updatedTags;
                });
            }
        });
}

export const useDeleteTag = () => {
    const {deleteObjectById} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (tagId) => deleteObjectById("environment_tags", tagId), {
            onSuccess: (response, tagId) => {                            
                queryClient.setQueryData(['tags'], oldTags => {
                    return oldTags.filter(tag => tag._id !== tagId);
                });
            }
        });
}

// const queryClient = useQueryClient();
export const useClearCache = () => {
    const queryClient = useQueryClient();
    queryClient.clear();
}

export const useTagObjects = () => {
    const {tagObjects} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (tagRequest) => tagObjects(tagRequest).then(res => res.data), {
            onSuccess: (response) => {
                response.update_tag_results.forEach((result) => {                
                    const entityType = getEntityTypeByCode(result.entity_type_code)
                    if (entityType === EntityType.CmdPacket) {
                        updateQueriesForCmdPacketUpdate(result.updated_entity, queryClient);
                    } else if (entityType === EntityType.CmdParam) {
                        updateQueriesForCmdParamUpdate(result.updated_entity, queryClient);                        
                    } else if (entityType === EntityType.TlmPacket) {
                        updateQueriesForTlmPacketUpdate(result.updated_entity, queryClient);                        
                    } else if (entityType === EntityType.TlmPoint) {
                        updateQueriesForTlmPointUpdate(result.updated_entity, queryClient);
                    }
                })
            }
        });
}


export const usePushToProd = () => {
    const {pushToProd} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        () => pushToProd().then(res => res.data), {
            onSuccess: (response) => {
                console.log(`Push response: ${response}`)
                // Pushing could cause, wide scale query invalidation through mass tag changes at all levels
                queryClient.invalidateQueries()
            }
        });
}


export const usePushJobs = ()  => {
    const {getPushJobs} = useDataService();

    return useQuery(
        ["pushJobs"], 
        () => getPushJobs().then(res => res.data), 
        {
            refetchInterval: 2000
        }
    );
}


export const usePushJob = (id)  => {
    const {getPushJob} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["pushJobs", id], 
        () => getPushJob(id).then(res => res.data), 
        {
            initialData: () => {
                return queryClient.getQueryData(["pushJobs"])?.find(job => job._id === id);
            },
            onSuccess: (latestPushJob) => {
                updateQueriesForPushJobUpdate(latestPushJob, queryClient);
            }
        }
    );    
}

export const useCancelPushJob = () => {
    const {cancelPushJob} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (id) => cancelPushJob(id).then(res => res.data), {
            onSuccess: (updatedPushJob) => {
                updateQueriesForPushJobUpdate(updatedPushJob, queryClient);
            }
        });
}


const updateQueriesForPushJobUpdate = (updatedJob, queryClient) => {
    queryClient.setQueryData(['pushJobs'], oldJob => {
        return [...oldJob, updatedJob];
    });
}


// Metadata
export const useMetadata = ()  => {
    const {getMetadata} = useDataService();

    return useQuery(
        ["metadata"], 
        () => getMetadata().then(res => res.data)
    );
}


export const useSaveMetadata = () => {
    const {updateMetadata} = useDataService();
    const queryClient = useQueryClient();

    return useMutation(
        (updatedMetadata) => updateMetadata(updatedMetadata).then((res) => res.data), {
            onSuccess: () => {
                queryClient.invalidateQueries(["metadata"]);
            }
        });
}

//versioning metadata


export const useSCVersioning = ()  => {
    const {getVersioningMetadata} = useDataService();

    return useQuery(
        ["version-metadata"], 
        () => getVersioningMetadata().then(res => res.data)
    );
}

export const useCreateDevVersion = () => {
    const {updateDevVersion} = useDataService();

    return useMutation(
        () => updateDevVersion().then(res => res.data), {
            onSuccess: (updatedDevVersion) => {
                return updatedDevVersion
            }
        });
}

export const useDeleteDevVersion = () => {
    const {deleteDevVersion} = useDataService();

    return useMutation(
        (snapshot) => deleteDevVersion(snapshot).then(res => res.data), {
            onSuccess: (deletedDevVersion) => {
                return deletedDevVersion
            }
        });
}

export const useUpdateVersionMetadata = () => {
    const {updateVersionMetadata} = useDataService();

    return useMutation(
        (database) => updateVersionMetadata(database).then(res => res.data), {
            onSuccess: (updatedDatabase) => {
               return updatedDatabase;
            }
        });
}

//Tlm Point Lite
export const useTlmPointsLite = ()  => {
    const {getTlmPointsLite} = useDataService();

    return useQuery(
        ["tlmPointLite"], 
        () => getTlmPointsLite().then(res => res.data)
    );
}

// Alerts MSD 

export const useAlerts = ()  => {
    const {getAlerts} = useDataService();

    return useQuery(
        ["alerts"], 
        () => getAlerts(0, 20000).then(res => res.data)
    );
}

export const useAlertById = (id)  => {
    const {getAlertById} = useDataService();
    const queryClient = useQueryClient();

    return useQuery(
        ["alertId", id], 
        () => getAlertById(id).then(res => res.data), {
            initialData: () => {
                return queryClient.getQueryData(["alertId"])?.find(param => param._id === id);
            }
        }
    );    
}

export const useSaveAlert = () => {
    const {updateAlert} = useDataService();

    return useMutation(
        (updatedAlert) => updateAlert(updatedAlert).then(res => res.data), {
            onSuccess: (updatedAlert) => {
                return updatedAlert;
            }
        });
}

export const useDeleteAlert = () => {
    const {deleteAlertByName} = useDataService();

    return useMutation(
        (alertName) => deleteAlertByName(alertName).then(res => res.data), {
            onSuccess: (updatedAlert) => {
                return updatedAlert;
            }
        });
}

export const useCreateAlert = () => {
    const {createAlert} = useDataService();

    return useMutation(
        (createdAlert) => createAlert(createdAlert).then(res => res.data), {
            onSuccess: (createdAlert) => {
                return createdAlert;
            }
        });
}