
import React from 'react';

import style from './EditorToolBar.module.css';

import ToolBarEditButton from 'components/ui-core/toolbar/buttons/ToolBarEditButton/ToolBarEditButton';
import ToolBarDeleteButton from 'components/ui-core/toolbar/buttons/ToolBarDeleteButton/ToolBarDeleteButton';
import ToolBarBuildModeButton from 'components/ui-core/toolbar/buttons/ToolBarBuildModeButton/ToolBarBuildModeButton';
import { useASTData } from 'context/ASTContext';
import EditModeTransition from 'model/EditModeTransition';
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'

import { doesEntityContainTag } from 'components/tags/TagHelper';
import ManagedTag from 'model/ManagedTag';
import ToolBarCopyButton from '../buttons/ToolBarCopyModeButton/ToolBarCopyButton';
import { useGetCommandPacketBoardFilter, useGetTelemetryPacketBoardFilter, useMetadata } from 'data/queryHooks';
import { useState } from 'react';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import CreateCommandPacketWizard from 'components/cmd-packet/table-page/CreateCommandPacketWizard';
import Modal from 'components/ui-core/modal/Modal/Modal';
import CreateTelemetryPacketWizard from 'components/tlm-packet/table-page/CreateTelemetryPacketWizard';

export const EditorActionButtons = ({ underlyingEntity, currentEditOption, selectedTabId, editModeActive, toolBarDef }) => {

	const { setConfirmationRequest, filterToggle } = useASTData();

	const [createVisible, setCreateVisible] = useState(false);
	const boardFilter= JSON.parse(localStorage.getItem('board_filter_object'));
	const allCmdPacketsQuery = useGetCommandPacketBoardFilter(boardFilter, filterToggle);
	const allTlmPacketsQuery = useGetTelemetryPacketBoardFilter(boardFilter, filterToggle);
	const metadataQuery = useMetadata();
	const isLogicallyDeleted = underlyingEntity
		? doesEntityContainTag(underlyingEntity, ManagedTag.DELETED.name)
		: false;
	const isStagedForProd = underlyingEntity
		? doesEntityContainTag(underlyingEntity, ManagedTag.STAGED_FOR_FSW_PROD.name)
		: false;

	const mutable = selectedTabId === "tags_tab" ? true : !isLogicallyDeleted && !isStagedForProd

	const buildModeButtonJsx = () => {
		const mutationsDef = toolBarDef.manipulationButtons?.mutationButtons;
		const disabled = editModeActive || !mutable;
		
		if (mutationsDef.includeEdit && currentEditOption === "buildMode") {
			return <ToolBarBuildModeButton disabled={disabled}
				onClick={() => {
					if (!disabled) {
						console.log("Build Mode pressed");
						mutationsDef.editModeTransitionHandler(EditModeTransition.LaunchEdit);
					}					
				}}/>;
		} else {
			return <></>
		}
	}

	const editButtonJsx = () => {
		const mutationsDef = toolBarDef.manipulationButtons?.mutationButtons;
		const disabled = editModeActive || !mutable;

		if (mutationsDef.includeEdit && currentEditOption === "formEditMode") {
			return <ToolBarEditButton disabled={disabled}
				onClick={() => {
					if (!disabled) {
						console.log("Edit pressed");
						mutationsDef.editModeTransitionHandler(EditModeTransition.LaunchEdit);
					}
				}}/>;
		} else {
			return <></>
		}
	}

	const launchDeletionConfirmRequest = () => {		
		const deleteDef = toolBarDef.manipulationButtons?.deleteButton;
		const deleteRequest = deleteDef.createDeleteRequest();
		setConfirmationRequest(deleteRequest);
	}
	const {submitWithAppropriateWarnings} = useSubmitWithWarnings(launchDeletionConfirmRequest)

	const deleteButtonJsx = () => {
		const deleteDef = toolBarDef.manipulationButtons?.deleteButton;
		const disabled = editModeActive || isLogicallyDeleted || isStagedForProd;

		if (deleteDef) {
			return <ToolBarDeleteButton disabled={disabled}
				onClick={() => {
					if (!disabled) {
						console.log("Delete pressed");
						submitWithAppropriateWarnings();
					}
				}}
			/>;
		} else {
			return <></>
		}
	}

	const copyButttonCmdJsx = () => {
		const copyDef = toolBarDef.manipulationButtons?.copyButton;
		const disabled = editModeActive || !mutable;

		if (copyDef) {
			return <> {createVisible &&
				<LoadingWrapper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
					queries={[allCmdPacketsQuery, allTlmPacketsQuery, metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateCommandPacketWizard
								setCreateVisible={setCreateVisible}
								allCmdPackets={allCmdPacketsQuery.data}
								metadata={metadataQuery.data[0]}
								copyPacket={underlyingEntity}
							/>
						</Modal>
					)}
				/>
			}
				<ToolBarCopyButton disabled={disabled}
					onClick={() => {
						if (!disabled) {
							setCreateVisible(true);
						}
					}} />
			</>
		}
		else {
			return <></>
		}
	}
	const copyButttonTlmJsx = () => {
		const copyDef = toolBarDef.manipulationButtons?.copyButton;
		const disabled = editModeActive || !mutable;

		if (copyDef) {
			return <> {createVisible &&
				<LoadingWrapper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
					queries={[allCmdPacketsQuery, allTlmPacketsQuery, metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateTelemetryPacketWizard
								setCreateVisible={setCreateVisible}
								allTlmPackets={allTlmPacketsQuery.data}
								allCmdPacketsQuery={allCmdPacketsQuery}
								metadata={metadataQuery.data[0]}
								copyPacket={underlyingEntity}
							/>
						</Modal>
					)}
				/>
			}
				<ToolBarCopyButton disabled={disabled}
					onClick={() => {
						if (!disabled) {
							setCreateVisible(true);
						}
					}} />
			</>
		}
		else {
			return <></>
		}
	}

	return (
		<div className={style.buttonContainer}>
			{buildModeButtonJsx()}
			{underlyingEntity.cmd_name && copyButttonCmdJsx()}
			{!underlyingEntity.cmd_name && copyButttonTlmJsx()}
			{editButtonJsx()}
			{deleteButtonJsx()}
		</div>
	);
}


export default EditorActionButtons;
