// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) {background: rgba(225, 221, 221, 0.5)}
tbody tr:nth-child(even) {background: rgba(255, 255, 255, 1)}


table {
	width: 100%;
	border-bottom: 0.15rem solid #707070;
}


thead {
	background: var(--ast-dark-color);
	color: var(--ast-light-color);
	font-weight: 500;
	font-size: 0.875rem;
	text-align: left;
}

thead th {
	padding: 1rem 1rem 1rem 0.5rem;
	border-right: 0.15rem solid #707070;
	border-top: 0.15rem solid #707070;
}

table,
td {
	border-right: 0.15rem solid #707070;
	border-collapse: collapse;
	padding: 0.5rem;
	text-align: left;
}

table tr td {
	text-align: left;
}

td:nth-child(1),
th:nth-child(1) {  
	border-left: 0.15rem solid #707070;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/Table/Table.module.css"],"names":[],"mappings":"AAAA,yBAAyB,oCAAoC;AAC7D,0BAA0B,kCAAkC;;;AAG5D;CACC,WAAW;CACX,oCAAoC;AACrC;;;AAGA;CACC,iCAAiC;CACjC,6BAA6B;CAC7B,gBAAgB;CAChB,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,8BAA8B;CAC9B,mCAAmC;CACnC,iCAAiC;AAClC;;AAEA;;CAEC,mCAAmC;CACnC,yBAAyB;CACzB,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;;CAEC,kCAAkC;AACnC","sourcesContent":["tbody tr:nth-child(odd) {background: rgba(225, 221, 221, 0.5)}\r\ntbody tr:nth-child(even) {background: rgba(255, 255, 255, 1)}\r\n\r\n\r\ntable {\r\n\twidth: 100%;\r\n\tborder-bottom: 0.15rem solid #707070;\r\n}\r\n\r\n\r\nthead {\r\n\tbackground: var(--ast-dark-color);\r\n\tcolor: var(--ast-light-color);\r\n\tfont-weight: 500;\r\n\tfont-size: 0.875rem;\r\n\ttext-align: left;\r\n}\r\n\r\nthead th {\r\n\tpadding: 1rem 1rem 1rem 0.5rem;\r\n\tborder-right: 0.15rem solid #707070;\r\n\tborder-top: 0.15rem solid #707070;\r\n}\r\n\r\ntable,\r\ntd {\r\n\tborder-right: 0.15rem solid #707070;\r\n\tborder-collapse: collapse;\r\n\tpadding: 0.5rem;\r\n\ttext-align: left;\r\n}\r\n\r\ntable tr td {\r\n\ttext-align: left;\r\n}\r\n\r\ntd:nth-child(1),\r\nth:nth-child(1) {  \r\n\tborder-left: 0.15rem solid #707070;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
