
import { doesEntityContainTag } from 'components/tags/TagHelper';
import ManagedTag from 'model/ManagedTag';

const headerSize = 4;

export const getPacketSummaryItems = (assignedChildren, childTypeName) => {
    const nonDeletedChildren = assignedChildren
        .filter(row => !doesEntityContainTag(row, ManagedTag.DELETED.name))
        .filter(row => row["type"] !== "derived");

    const childByteCount =  nonDeletedChildren.reduce(
        (accumulator, childRow) => accumulator + childRow.size_bytes,
        0
    );

    const totalPacketBytes = childByteCount + headerSize;

    return [
        {
            title: `Total ${childTypeName} Bytes`,
            value: childByteCount
        }, 
        {
            title: "Total Packet Bytes",
            value: totalPacketBytes
        }
    ]
}