import React from 'react';
import TableScrollbar from 'react-table-scrollbar';

import TablePaginator from '../TablePaginator/TablePaginator';
import './Table.module.css'
import Row from './Row'
import DndRow from './DndRow'
import EmptyTableDndRowPlaceholder from './EmptyTableDndRowPlaceholder'
import { usePageState } from 'components/ui-core/page/usePageState';

export const Table = ({ useMsdTableObj, getCustomRowProps = () => {}, tableErrors, ...props }) => {

	const {tableName, getTableProps, getTableBodyProps, headerGroups, 
			prepareRow, page, rows, includedHooks, selectedFlatRows, idAttrName} = useMsdTableObj;

	const rowsToRender = includedHooks.pagination ? page : rows;

	// Ensure the selection is set on the page state (only update/re-renders if actually changed)
	const { setSeletedRowIds } = usePageState();
	if (useMsdTableObj.includedHooks.selection) {
		const ids = selectedFlatRows.map(row => row.original[idAttrName]);
		setSeletedRowIds(tableName, ids)
	}
	
	return (
		<>
			<TableScrollbar>
				<table {...props} {...getTableProps()} >
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(
											column.getSortByToggleProps ? column.getSortByToggleProps() : [])}>
										{column.render('Header')}
										<span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()} >
						{rowsToRender.map((row, index) => {
							prepareRow(row);
							return (
								includedHooks.dnd
									? <DndRow key={index} row={row} index={index} 
										getCustomRowProps={getCustomRowProps} useMsdTableObj={useMsdTableObj}/>
									: <Row key={index} row={row} index={index} getCustomRowProps={getCustomRowProps}/>
							);
						})}

						{rowsToRender.length === 0 && includedHooks.dnd
							? <EmptyTableDndRowPlaceholder 
								getCustomRowProps={getCustomRowProps} useMsdTableObj={useMsdTableObj}/>
							: <></>
						}
					</tbody>
				</table>
				
				{tableErrors && tableErrors()}

				{includedHooks.pagination &&
					<TablePaginator useMsdTableObj={useMsdTableObj}/>
				}
			</TableScrollbar>
		</>
	);
};

export default Table;
