import React from 'react';
import style from './MsdMainPage.module.css'

import { ReactQueryDevtools } from 'react-query/devtools';

import SubmittablePage from 'components/ui-core/page/SubmittablePage';
import TintedPage from 'components/ui-core/page/TintedPage';

import SideBar from '../SideBar/SideBar';
import Header from '../Header/Header';
import ConfirmationModal from 'components/ui-core/modal/ConfirmationModal/ConfirmationModal';


export const MsdMainPage = (props) => {

    const msdDev = process.env.REACT_APP_MSD_DEV;

    return (
        <TintedPage>
            <SubmittablePage>
                <div id="MsdMainPageRoot" className={style.pageRootContainer}>
                    <SideBar/>
                    <main id="MainPage" className={style.mainSection}>
                        <div className={style.mainHeader}>
                            <Header title={'Command Packets'} />
                        </div>
                        <div id="mainBody" className={style.mainBody}>
                            {props.children}
                        </div>

                        {msdDev==="true" && <ReactQueryDevtools initialIsOpen />}
                    </main>
                    <ConfirmationModal />
                </div>
            </SubmittablePage>
        </TintedPage>
    );
}

export default MsdMainPage;
